import React, { ReactNode } from 'react'
import { CloseOutlined, Save } from '@mui/icons-material'
import { Drawer, Typography, IconButton, Stack, Box, Grid, TextField, Button, Divider, Chip } from '@mui/material'
import { HttpError, useParsed } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { FieldValues } from 'react-hook-form'
import { Edit } from '@refinedev/mui'

export const EditMayorAndLeader: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  reset,
  modal: { visible, close },
  saveButtonProps
}) => {
  const { id } = useParsed()
  const handleSubmitEdit = (values: FieldValues) => {
    onFinish({
      ...values,
      id
    })
    reset()
    close()
  }

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 1000 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Editar Prefeito/Secretário</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            component="div"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitEdit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('mayor_name', {
                      required: 'Nome do Prefeito é obrigatório!',
                      maxLength: {
                        value: 250,
                        message: 'Nome do Prefeito deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.mayor_name}
                    helperText={errors.mayor_name?.message as ReactNode}
                    label="Nome do Prefeito"
                    name="mayor_name"
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('mayor_cpf', {
                      required: 'CPF do Prefeito é obrigatório!',
                      maxLength: {
                        value: 250,
                        message: 'CPF do Prefeito deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.mayor_cpf}
                    helperText={errors.mayor_cpf?.message as ReactNode}
                    label="CPF do Prefeito"
                    name="mayor_cpf"
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('mayor_mail')}
                    error={!!errors?.mayor_mail}
                    helperText={errors.mayor_mail?.message as ReactNode}
                    label="E-mail do Prefeito"
                    name="mayor_mail"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('mayor_phone')}
                    error={!!errors?.mayor_phone}
                    helperText={errors.mayor_phone?.message as ReactNode}
                    label="Telefon Fixo do Prefeito"
                    name="mayor_phone"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('mayor_cellphone')}
                    error={!!errors?.mayor_cellphone}
                    helperText={errors.mayor_cellphone?.message as ReactNode}
                    label="Celular do Prefeito"
                    name="mayor_cellphone"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box paddingTop={4} paddingBottom={4} position="relative">
                    <Chip
                      label="Prefeito"
                      style={{
                        position: 'absolute',
                        top: '10%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                      }}
                    />
                    <Divider style={{ borderColor: 'white', borderWidth: '2px' }} />
                    <Chip
                      label="Secretário"
                      style={{
                        position: 'absolute',
                        top: '90%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('leader_name', {
                      required: 'Nome do Secretário é obrigatório!',
                      maxLength: {
                        value: 250,
                        message: 'Nome do Secretário deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.leader_name}
                    helperText={errors.leader_name?.message as ReactNode}
                    label="Nome do Secretário"
                    name="leader_name"
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('leader_cpf', {
                      required: 'CPF do Secretário é obrigatório!',
                      maxLength: {
                        value: 250,
                        message: 'CPF do Secretário deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.leader_cpf}
                    helperText={errors.leader_cpf?.message as ReactNode}
                    label="CPF do Secretário"
                    name="leader_cpf"
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('leader_mail')}
                    error={!!errors?.leader_mail}
                    helperText={errors.leader_mail?.message as ReactNode}
                    label="E-mail do Secretário"
                    name="leader_mail"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('leader_phone')}
                    error={!!errors?.leader_phone}
                    helperText={errors.leader_phone?.message as ReactNode}
                    label="Telefon Fixo do Secretário"
                    name="leader_phone"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('leader_cellphone')}
                    error={!!errors?.leader_cellphone}
                    helperText={errors.leader_cellphone?.message as ReactNode}
                    label="Celular do Secretário"
                    name="leader_cellphone"
                    margin="normal"
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
              </Grid>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    <Save />
                  }>Salvar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Edit>
    </Drawer>
  )
}
