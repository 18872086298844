import React, { useState, ReactNode, useEffect, useMemo } from 'react'
import { EnumCampaigns, EnumCustomerStatus, SendingEmail } from 'enums'
import { ISimec, ICustomerFilterVariables, ICampaign, IMesoregion, IMicroregion, IState, IMail } from 'interfaces'
import Editor from '@monaco-editor/react'
import states from 'data/states.json'
import regions from 'data/regions.json'
import { Add, Clear, Info, Send } from '@mui/icons-material'
import { v4 as uuid } from 'uuid'
import validator from 'validator'
import { axiosInstance } from 'config/axios'
import { LoadingButton } from '@mui/lab'
import { Stack, Box, Grid, TextField, Autocomplete, Typography, Button, Card, CardHeader, CardContent, Paper, Fab, FormControl, FormLabel, RadioGroup, FormControlLabel, Alert, AlertTitle, Stepper, Step, StepButton, Radio, Tooltip, useTheme, Chip } from '@mui/material'
import { GridColumns, DataGrid } from '@mui/x-data-grid'
import { useCreate, useTranslate, HttpError, CrudFilters, BaseRecord, getDefaultFilter } from '@refinedev/core'
import { useDataGrid, DeleteButton, SaveButton, Create } from '@refinedev/mui'
import { useForm, useStepsForm } from '@refinedev/react-hook-form'
import { FieldValues, Controller } from 'react-hook-form'
import parse from 'html-react-parser'
import { FileInput } from 'components/file-input'

export function CampaignCreate () {
  const requiredTextToDisableFilters = 'Enviar para todos'
  const [type, setType] = useState<string | null>()

  const [fieldToDisableFilters, setFieldToDisableFilters] = useState<string>('')
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false)
  const [subject, setSubject] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [html, setHtml] = useState<string>('')
  const [urlTest, setUrlTest] = useState<string>('')
  const [loadingMailTest, setLoadingMailTest] = useState<boolean>(false)
  const [radio, setRadio] = useState<string>('')
  const [state, setState] = useState<IState | null>()
  const [mail, setMail] = useState<any>()
  const [mails, setMails] = useState<any[]>([])
  const [date, setDate] = useState<string | null>()
  const [time, setTime] = useState<string | null>()
  const [mesoregions, setMesoregions] = useState<IMesoregion[]>()
  const [microregions, setMicroregions] = useState<IMicroregion[]>()
  const { mutate: mutateCreate } = useCreate<any>()
  const t = useTranslate()
  const { palette } = useTheme()
  const [files, setFiles] = useState<any[]>([])

  const handleFileChange = async (selectedFiles: FileList) => {
    const uploadedFiles: any[] = []
    for (const file of Array.from(selectedFiles)) {
      const formData = new FormData()
      formData.append('upload', file)
      const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post<{ url: string, key: string }>(
        'file/upload',
        formData,
        {
          withCredentials: false,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      uploadedFiles.push({
        file_name: file.name,
        file_url: data.url,
        mimetype: file.type,
        key: data.key,
        size: file.size,
        last_modified_date: new Date(file.lastModified)
      })
    }
    setFiles([...files, ...uploadedFiles])
  }

  const handleDeleteFile = async (fileToDelete: any) => {
    const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    try {
      await axiosInstance.delete('file/remove', {
        data: { key: fileToDelete.key },
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
    } catch (error) {
      console.error('Error deleting file:', error)
    }
    setFiles(files.filter((file) => file !== fileToDelete))
  }

  const stepTitles = [
    t('campaigns.steps.descriptions'),
    t(type === 'EMAIL' ? 'campaigns.steps.content.email' : 'campaigns.steps.content.whatsapp'),
    t('campaigns.steps.recipients'),
    t('campaigns.steps.timer')
  ]

  const { dataGridProps, search: searchFilter, filters } = useDataGrid<
  ISimec,
  HttpError,
  ICustomerFilterVariables
  >({
    resource: 'simecCustomers',
    pagination: {
      pageSize: 25,
      current: 0
    },
    syncWithLocation: false,
    metaData: {
      variables: {
        operatorOrm: {
          value: 'or',
          name: 'operatorOrm',
          required: false,
          type: 'String'
        }
      },
      fields: [
        'total',
        {
          nodes: [
            'id',
            'code',
            'cnpj',
            'name',
            'mail',
            'county_name',
            'state_abbreviation',
            'mayor_mail',
            'mayor_cellphone',
            'leader_mail',
            'leader_cellphone',
            'status'
          ]
        }
      ]
    },
    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, region_name: regionName, mesoregion_name: mesoregionName, microregion_name: microregionName, state_name: stateName } = params

      filters.push({
        field: 'status',
        operator: 'in',
        value: status?.toString()
      }, {
        field: 'region_name',
        operator: 'in',
        value: regionName?.toString()
      }, {
        field: 'state_name',
        operator: 'in',
        value: stateName?.toString()
      }, {
        field: 'mesoregion_name',
        operator: 'in',
        value: mesoregionName && mesoregionName.length > 0 ? mesoregionName?.toString() : undefined
      }, {
        field: 'microregion_name',
        operator: 'in',
        value: microregionName && microregionName.length > 0 ? microregionName?.toString() : undefined
      })

      return filters
    }
  })

  const { handleSubmit: handleSubmitFilter, control: controlFilter } = useForm<
  BaseRecord,
  HttpError,
  ICustomerFilterVariables
  >({
    defaultValues: {
      status: getDefaultFilter('status', filters, 'in'),
      region_name: getDefaultFilter('region_name', filters, 'in'),
      state_name: getDefaultFilter('state_name', filters, 'in'),
      mesoregion_name: getDefaultFilter('mesoregion_name', filters, 'in'),
      microregion_name: getDefaultFilter('microregion_name', filters, 'in')
    }
  })

  if (fieldToDisableFilters === requiredTextToDisableFilters) {
    while (filters.length > 0) {
      filters.pop()
    }
  }

  const {
    refineCore: { onFinish, formLoading },
    control,
    register,
    handleSubmit,
    formState: { errors },
    steps: { currentStep, gotoStep }
  } = useStepsForm<ICampaign, HttpError>({
    stepsProps: {
      isBackValidate: false
    },
    warnWhenUnsavedChanges: true
  })

  const handleSubmitCreate = async (values: FieldValues) => {
    const attachments = (files ?? []).map((file: any) => ({
      href: file.file_url,
      filename: file.file_name
    }))

    let htmlBody = ''
    if (html) {
      const formData = new FormData()
      formData.append('html', new Blob([html], { type: 'text/plain' }))

      const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post('/file/html', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })
      htmlBody = data.url
    }

    const { sending_email: sendingEmail, type_campaigns: typeCampaigns, date: oldDate, time: oldTime, ...rest } = values

    await onFinish({
      ...rest,
      datetime: radio === '2' ? new Date(`${date} ${time}`) : new Date(),
      type_campaigns: Object.values(EnumCampaigns).indexOf(typeCampaigns),
      html_body: htmlBody,
      text_body: text.toString() ?? '',
      task_type: radio,
      user_status: filters.find((f: any) => f.field === 'status')?.value ?? null,
      region_name: filters.find((f: any) => f.field === 'region_name')?.value ?? null,
      state_name: filters.find((f: any) => f.field === 'state_name')?.value ?? null,
      mesoregion_name: filters.find((f: any) => f.field === 'mesoregion_name')?.value ?? null,
      microregion_name: filters.find((f: any) => f.field === 'microregion_name')?.value ?? null,
      mails: mails.map(m => m.to),
      attachments: attachments ?? [],
      sending_email: type !== 'EMAIL' ? null : Object.values(SendingEmail).indexOf(sendingEmail)
    })
  }
  const resetHtml = () => {
    setHtml('')
  }

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setDisableNextButton(false)
        break
      case 1:
        (text.length === 0 && html.length === 0) ? setDisableNextButton(true) : setDisableNextButton(false)
        break
      case 2:
        filters.length === 0 ? setDisableNextButton(true) : setDisableNextButton(false)
        if (fieldToDisableFilters === requiredTextToDisableFilters) {
          setDisableNextButton(false)
          handleSubmitFilter(searchFilter)
        }
        break
      case 3:
        date && time ? setDisableNextButton(false) : setDisableNextButton(true)
        break
    }
  }, [currentStep, html, text, filters, fieldToDisableFilters, date, time])

  useEffect(() => {
    const fetchData = async () => {
      const dataMeso = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state?.id}/mesorregioes`)
      const jsonMeso = await dataMeso.json()
      setMesoregions(jsonMeso)

      const dataMicro = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state?.id}/microrregioes`)
      const jsonMicro = await dataMicro.json()
      setMicroregions(jsonMicro)
    }
    fetchData()
      .catch(console.error)
  }, [state])

  async function testMail () {
    setLoadingMailTest(true)
    const attachments = (files ?? []).map((file: any) => ({
      href: file.file_url,
      filename: file.file_name
    }))

    let htmlBody = ''
    if (html) {
      const formData = new FormData()
      formData.append('html', new Blob([html], { type: 'text/plain' }))

      const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post('/file/html', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })
      htmlBody = data.url
    }

    mutateCreate({
      resource: 'testMail',
      invalidates: ['all'],
      values: {
        html: htmlBody,
        text,
        subject,
        to: 'teste@braconsultoria.com',
        attachments
      },
      metaData: {
        fields: ['url']
      },
      successNotification: {
        type: 'success',
        message: 'Link de e-mail teste foi gerado!',
        undoableTimeout: 3000
      }
    }, {
      async onSuccess ({ data }) {
        setUrlTest(data.url)
        setLoadingMailTest(false)
      }
    })
  }

  function addToList () {
    if (type === 'EMAIL') {
      if (!validator.isEmail(mail)) return null
      if (mails && mails?.length > 0) {
        setMails([...mails, { id: `${uuid()}${mail}`, to: mail }])
      } else setMails([{ id: `${uuid()}${mail}`, to: mail }])
      setMail('')
    } else {
      if (!validator.isMobilePhone(mail, 'pt-BR')) return null
      if (mails && mails?.length > 0) {
        setMails([...mails, { id: `${uuid()}${mail}`, to: mail }])
      } else setMails([{ id: `${uuid()}${mail}`, to: mail }])
      setMail('')
    }
  }

  function removeMailToList (id: any, mails: any[]) {
    setMails(mails.filter((mail) => mail.id !== id))
  }

  const columns = useMemo<GridColumns<ISimec>>(
    () => [
      {
        field: 'county_name',
        headerName: 'Prefeitura',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'state_abbreviation',
        headerName: 'Estado',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'mail',
        headerName: 'E-Mail Prefeitura',
        flex: 1,
        minWidth: 100
      }
    ],
    []
  )

  const columnsWS = useMemo<GridColumns<ISimec>>(
    () => [
      {
        field: 'county_name',
        headerName: 'Prefeitura',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'state_abbreviation',
        headerName: 'Estado',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'mayor_cellphone',
        headerName: 'Prefeito Num',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'leader_cellphone',
        headerName: 'Secretário Num',
        flex: 1,
        minWidth: 100
      }
    ],
    []
  )

  const columnsMail = useMemo<GridColumns<IMail>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <DeleteButton
                hideText
                recordItemId={row.id}
                onClick={() => removeMailToList(row.id, (mails ?? []))}
              />
            </Stack>
          )
        }
      },
      {
        field: 'to',
        headerName: 'Lista de E-Mails',
        flex: 1,
        minWidth: 100,
        renderCell: ({ row }) => {
          return row.to
        }
      }
    ],
    []
  )

  const columnsWhatsApp = useMemo<GridColumns<IMail>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <DeleteButton
                hideText
                recordItemId={row.id}
                onClick={() => removeMailToList(row.id, (mails ?? []))}
              />
            </Stack>
          )
        }
      },
      {
        field: 'to',
        headerName: 'Lista de WhatsApps',
        flex: 1,
        minWidth: 100,
        renderCell: ({ row }) => {
          return row.to
        }
      }
    ],
    []
  )

  const infoContent = type === 'EMAIL' ? 'Nem todos os clientes de email suportam o formato HTML ou podem bloquear automaticamente o conteúdo HTML por motivos de segurança. Se o destinatário não puder ver o conteúdo do HTML, eles ainda serão capazes de ler o texto simples, garantindo que a mensagem chegue a eles.' : 'Texto que será enviado aos contatos'
  const infoAttachment = type === 'EMAIL' ? 'Os anexos serão anexados ao email' : 'Os anexos serão enviados junto a mensagem'

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column' }}
            autoComplete="off"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  {...register('title', { required: 'Título é obrigatório!' })}
                  error={!!errors?.title}
                  helperText={errors.title?.message as ReactNode ?? 'Escreva o título do E-mail, será o título enviado para todos os destinatários caso o tipo de campanha seja EMAIL.'}
                  label="Título"
                  name="title"
                  margin="normal"
                  onChange={(event) => setSubject(event.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  control={control}
                  name="type_campaigns"
                  rules={{ required: 'Tipo é obrigatório!' }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={Object.values(EnumCampaigns).filter(value => typeof value === 'string')}
                      onChange={(_, value) => {
                        setType(value)
                        field.onChange(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo"
                          margin="normal"
                          variant="outlined"
                          error={!!errors?.type_campaigns}
                          helperText={errors.type_campaigns?.message as ReactNode ?? 'Selecione o tipo da campanha, será o meio de envio utilizado.'}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  {...register('description')}
                  error={!!errors?.description}
                  helperText={errors.description?.message as ReactNode ?? 'Opcional, descrição sobre a campanha.'}
                  label="Descrição"
                  name="description"
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
            {
              type === 'EMAIL' && (
                <Grid item xs={12} md={6} lg={4}>
                  <Controller
                    control={control}
                    name="sending_email"
                    rules={{
                      required: {
                        value: type === 'EMAIL',
                        message: 'Email de envio é obrigatório!'
                      }
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={Object.values(SendingEmail).filter(value => typeof value === 'string')}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Emails habilitados para envio"
                            margin="normal"
                            variant="outlined"
                            error={!!errors?.sending_email}
                            helperText={errors.sending_email?.message as ReactNode ?? 'Selecione qual e-mail será utilizado para envio da campanha.'}
                            required={type === 'EMAIL'}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )
            }
          </Box>
        )
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant="h5">Texto</Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <Tooltip title={(<>
                    <Typography variant="caption" display="block" gutterBottom>{infoContent}</Typography>
                  </>)}>
                    <Info />
                  </Tooltip>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                value={text}
                onChange={(event) => setText(event.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography variant="h5">Anexos</Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <Tooltip title={(<>
                    <Typography variant="caption" display="block" gutterBottom>{infoAttachment}</Typography>
                  </>)}>
                    <Info />
                  </Tooltip>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <FileInput onFileChange={handleFileChange} />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                  paddingTop: 2
                }}
              >
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.key}
                    onDelete={() => handleDeleteFile(file)}
                    deleteIcon={<Clear />}
                  />
                ))}
              </Box>
            </Grid>
            {
              type === 'EMAIL' && (
                <>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Typography variant="h5">Html</Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        <Tooltip title={(<>
                          <Typography variant="caption" display="block" gutterBottom>{'Ao digitar o código HTML, o visualizador HTML exibirá o resultado em tempo real. Isso permite que o usuário veja imediatamente como o código afeta a aparência da página, facilitando a correção de possíveis erros ou ajustes necessários'}</Typography>
                        </>)}>
                          <Info />
                        </Tooltip>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                      <Button
                        variant="contained"
                        color="warning"
                        type="button"
                        onClick={() => resetHtml()}
                      >Limpar Editor HTML</Button>
                      <LoadingButton
                        endIcon={<Send />}
                        loading={loadingMailTest}
                        loadingPosition="end"
                        variant="contained"
                        color="info"
                        type="button"
                        onClick={testMail}
                      >
                        Gerar E-mail teste
                      </LoadingButton>
                      <Button
                        variant="contained"
                        color="success"
                        type="button"
                        disabled={loadingMailTest || !urlTest}
                        target='_blank'
                        href={urlTest}
                      >Acessar E-mail teste</Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                      <Editor
                        height="40vh"
                        theme={palette.mode === 'dark' ? 'vs-dark' : 'vs-light'}
                        defaultLanguage="html"
                        defaultValue={html}
                        onChange={(value) => setHtml(value ?? '')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                      <CardHeader title={'Visualizador do E-mail'} subheader="Em tempo-real!" />
                      { parse(html) }
                    </Card>
                  </Grid>
                </>
              )
            }
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <CardHeader title={'Filtrar Prefeituras'} subheader="Selecione os filtros para envio da campanha." />
                <CardContent sx={{ pt: 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="status"
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disabled={fieldToDisableFilters === requiredTextToDisableFilters}
                            disablePortal
                            {...field}
                            options={Object.values(EnumCustomerStatus) || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value?.map((p) => p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option === value
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.status.label'
                                )}
                                placeholder={t(
                                  'customer.filter.status.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="region_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disabled={fieldToDisableFilters === requiredTextToDisableFilters}
                            multiple
                            options={regions}
                            onChange={(_, value) => {
                              field.onChange(
                                value?.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.region.label'
                                )}
                                placeholder={t(
                                  'customer.filter.region.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                disabled={fieldToDisableFilters === requiredTextToDisableFilters}
                                helperText={'Filtre as prefeituras por região.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="state_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disabled={fieldToDisableFilters === requiredTextToDisableFilters}
                            groupBy={(option) => option?.regiao.nome || ''}
                            multiple
                            options={states}
                            onChange={(_, value) => {
                              if (value.length === 1) {
                                setState(value[0])
                              } else setState(null)
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.state.label'
                                )}
                                placeholder={t(
                                  'customer.filter.state.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre as prefeituras por estado, caso haja filtro de região o sistema irá considerar OU a região OU o estado selecionado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="mesoregion_name"
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disabled={!state || fieldToDisableFilters === requiredTextToDisableFilters}
                            {...field}
                            options={mesoregions || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.mesoregion.label'
                                )}
                                placeholder={t(
                                  'customer.filter.mesoregion.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                disabled={fieldToDisableFilters === requiredTextToDisableFilters}
                                helperText={'Filtre o estado por mesoregião, válido quando selecionado apenas um estado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="microregion_name"
                        render={({ field }) => (
                          <Autocomplete
                            groupBy={(option) => option?.mesorregiao.nome || ''}
                            multiple
                            disabled={!state || fieldToDisableFilters === requiredTextToDisableFilters}
                            {...field}
                            options={microregions || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.microregion.label'
                                )}
                                placeholder={t(
                                  'customer.filter.microregion.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre o estado por microregião, válido quando selecionado apenas um estado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <TextField
                        label="Enviar para todos"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={fieldToDisableFilters}
                        onChange={(event) => setFieldToDisableFilters(event.target.value)}
                        helperText={<>
                        Para enviar a campanha para todas as prefeituras do Brasil digite <strong>Enviar para todos</strong> no campo acima.</>}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="right">
                        <Button type="button" variant="contained" onClick={handleSubmitFilter(searchFilter)}>
                          {t('customer.filter.submit')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper>
                <Grid container padding={2}>
                  <Grid item xs={12}>
                    <Stack direction="column" justifyContent="left">
                      <Typography variant='h5'>Prefeituras</Typography>
                      <Typography variant='h5' color={'green'}>{filters.length === 0 ? fieldToDisableFilters === requiredTextToDisableFilters ? 'Todas as' : 0 : dataGridProps.rowCount} prefeituras receberão a campanha.</Typography>
                      <Typography variant='subtitle1'>Tabela abaixo representa a relação de prefeituras que receberão a campanha de acordo com os filtros acima.</Typography>
                    </Stack>
                    <DataGrid
                      {...dataGridProps}
                      rows={filters.length === 0 ? fieldToDisableFilters === requiredTextToDisableFilters ? dataGridProps.rows : [] : dataGridProps.rows}
                      columns={type === 'EMAIL' ? columns : columnsWS}
                      filterModel={undefined}
                      autoHeight
                      pagination
                      paginationMode='server'
                      density='compact'
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper>
                <Grid container padding={2}>
                  <Grid item xs={12}>
                    <Stack direction="column" justifyContent="left">
                      <Typography variant='h5'>{type === 'EMAIL' ? 'E-mails' : 'WhatsApps'}</Typography>
                      <Typography variant='subtitle1'>(Opcional) Adicione {type === 'EMAIL' ? 'e-mails' : 'whatsapps'} manualmente para receberam a campanha.</Typography>
                    </Stack>
                    <Grid spacing={2}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={10}>
                        <TextField
                          label={`Digite o ${type === 'EMAIL' ? 'E-mail' : 'WhatsApp com DDD'}`}
                          name="email"
                          margin="normal"
                          placeholder={type === 'EMAIL' ? 'Exemplo: xxxxx@xxxxxx.com' : 'Exemplo: 61999999999'}
                          color="success"
                          type="email"
                          fullWidth
                          onChange={(event) => setMail(event.target.value)}
                          value={mail}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Fab onClick={() => addToList()} size='medium' color="success" aria-label="search">
                            <Add />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                    <DataGrid
                      rows={mails ?? []}
                      columns={type === 'EMAIL' ? columnsMail : columnsWhatsApp}
                      filterModel={undefined}
                      autoHeight
                      pagination
                      paginationMode='server'
                      density='compact'
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Stack
                sx={{ pt: 4 }}
                direction="column"
                spacing={2}
                justifyContent="center"
              >
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Selecione a programação de envio *</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radio}
                    onChange={(event, value) => setRadio(value)}
                  >
                    <FormControlLabel value="1" control={<Radio required />} label="Envio pendente" />
                    <FormControlLabel value="2" control={<Radio />} label="Programar Envio" />
                  </RadioGroup>
                </FormControl>
                {
                  radio === '1'
                    ? <Alert severity="info">
                      <AlertTitle>Informação</AlertTitle>
                        Ao Selecionar <strong>Envio pendente</strong> a campanha permanecerá com o status <strong>PENDENTE</strong> até algum usuário acessar a campanha e clicar em <strong>Enviar Campanha</strong>.
                    </Alert>
                    : <Alert severity="warning">
                      <AlertTitle>Atenção</AlertTitle>
                        A campanha será enviada para <strong>{filters.length === 0 ? fieldToDisableFilters === requiredTextToDisableFilters ? 'Todas as' : 0 : dataGridProps.rowCount} prefeituras</strong> na data e hora informada!
                    </Alert>
                }
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                {...register('date', {
                  required: (radio === '2'),
                  validate: (value) => {
                    const d = new Date(value)
                    const date = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
                    const now = new Date()
                    date.setHours(12, 0, 0, 0)
                    now.setHours(12, 0, 0, 0)
                    return date.getTime() < now.getTime() ? 'A data de envio da campanha não pode ser inferior à data atual!' : true
                  },
                  valueAsDate: true
                })}
                margin="normal"
                fullWidth
                type="date"
                placeholder='Selecione uma Data'
                disabled={ radio !== '2' }
                required={ radio !== '2' }
                error={!!errors?.date}
                helperText={errors.date?.message as ReactNode ?? 'Data que será enviada a campanha aos destinatários, opção disponível apenas se for selecionada a opção "Programar Envio".'}
                onChange={(event) => setDate(event.target.value)}
              />
              <TextField
                {...register('time', { required: (radio === '2') })}
                margin="normal"
                fullWidth
                type="time"
                placeholder='Selecione uma Hora'
                disabled={ radio !== '2' }
                required={ radio !== '2' }
                error={!!errors?.time}
                helperText={errors.time?.message as ReactNode ?? 'Hora que será enviada a campanha aos destinatários, opção disponível apenas se for selecionada a opção "Programar Envio".'}
                onChange={(event) => setTime(event.target.value)}
              />
            </Grid>
          </Grid>
        )
    }
  }

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Campanha</Typography>
      }}
      headerButtons={
        <>
          {currentStep > 0 && (
            <Button
              variant='contained'
              color='info'
              onClick={() => {
                gotoStep(currentStep - 1)
              }}
            >
              {t('buttons.previousStep')}
            </Button>
          )}
          {currentStep < stepTitles.length - 1 && (
            <Button disabled={disableNextButton} variant='contained' onClick={() => gotoStep(currentStep + 1)}>
              {t('buttons.nextStep')}
            </Button>
          )}
          {currentStep === stepTitles.length - 1 && (
            <SaveButton
              onClick={handleSubmit(handleSubmitCreate)}
            />
          )}
        </>
      }
      footerButtons={<></>}
      isLoading={formLoading}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stepper nonLinear activeStep={currentStep}>
          {stepTitles.map((label, index) => (
            <Step
              key={label}
              sx={{
                '& .MuiStepLabel-label': {
                  fontSize: '18px',
                  lineHeight: '32px'
                }
              }}
            >
              <StepButton disabled={disableNextButton} onClick={() => gotoStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <br />
        {renderFormByStep(currentStep)}
      </Box>
    </Create>
  )
}
