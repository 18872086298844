import React, { useMemo, useState, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useGetIdentity, useParsed } from '@refinedev/core'
import { Autocomplete, Button, Grid, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import { Close, Save } from '@mui/icons-material'
import { DateField, DeleteButton, useAutocomplete, useDataGrid } from '@refinedev/mui'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { IUser, IWallet } from 'interfaces'
import { Controller, FieldValues } from 'react-hook-form'
import { useForm } from '@refinedev/react-hook-form'

export const WalletShow = ({ open, handleClose }) => {
  const { data: user } = useGetIdentity<{
    id: string;
    name: string;
  }>()
  const { id } = useParsed()
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)

  const {
    refineCore: { onFinish },
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    refineCoreProps: {
      resource: 'customersWallet',
      redirect: false,
      invalidates: ['all']
    }
  })

  const { autocompleteProps } = useAutocomplete<IUser>({
    resource: 'users',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'name',
            'email',
            'profile',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    },
    pagination: {
      current: 1,
      pageSize: 25
    },
    filters: [{
      field: 'profile',
      operator: 'eq',
      value: '2'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '3'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '6'
    }]
  })

  const columns = useMemo<GridColumns<IWallet>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <DeleteButton
                hideText
                recordItemId={row.id}
                disabled={row.user.id !== user?.id}
                resource="customersWallet"
                invalidates={['all']}
                accessControl={{ enabled: true }}
              />
            </Stack>
          )
        }
      },
      {
        field: 'name',
        headerName: 'Usuario',
        flex: 1,
        minWidth: 250,
        renderCell: function render ({ row }) {
          return row.user.name
        }
      },
      {
        field: 'createdAt',
        headerName: 'Adicionado a carteira em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Adicionado a carteira por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      }
    ],
    []
  )

  const { dataGridProps } = useDataGrid({
    resource: 'findUsersWithCustomer',
    pagination: {
      mode: 'off'
    },
    meta: {
      operation: 'findUsersWithCustomer',
      variables: {
        customerId: {
          type: 'String',
          required: true,
          value: id
        }
      },
      fields: [
        'id',
        'createdAt',
        'updatedAt',
        {
          createdBy: ['id', 'name']
        },
        {
          user: [
            'id',
            'name',
            'createdAt',
            'updatedAt'
          ]
        }
      ]
    }
  })

  const handleSubmitCreate = (values: FieldValues) => {
    reset()
    setSelectedUser(null)
    onFinish({
      ...values,
      customerId: id
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90%',
          overflow: 'auto'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            paddingBottom: '50px'
          }}
        >
          <Typography paddingLeft={4} paddingTop={2} variant="h5">Carteira</Typography>
          <Close
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'red',
              cursor: 'pointer'
            }}
            onClick={handleClose}
          />
        </Box>
        <Grid container spacing={2} padding={4}>
          {dataGridProps.loading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0
              }}
            />
          )}
          <Grid item xs={12}>
            <DataGrid
              {...dataGridProps}
              columns={columns}
              density='compact'
              autoHeight
              pagination
              paginationMode='server'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography paddingTop={2} variant="h5">Para adicionar o cliente para um novo usuario use o campo abaixo:</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(handleSubmitCreate)}>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="userId"
                    render={({ field }) => (
                      <Autocomplete
                        {...autocompleteProps}
                        {...field}
                        onChange={(_, value: IUser | null) => {
                          field.onChange(value?.id)
                          setSelectedUser(value)
                        }}
                        getOptionLabel={(item: IUser) => {
                          return item.name
                            ? item.name
                            : autocompleteProps?.options?.find(
                              (p) =>
                                p.id.toString() ===
                                item.toString()
                            )?.name ?? ''
                        }}
                        value={selectedUser}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return value === undefined || option.id === value.id
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Usuarios"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.userId}
                            helperText={errors.userId?.message as ReactNode}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack paddingTop={2} justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!selectedUser}
                      startIcon={
                        <Save />
                      }>Adicionar</Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
