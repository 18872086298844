import React, { ReactNode, useEffect, useState } from 'react'
import { IDistrict, IState, IUser } from 'interfaces'
import { Save } from '@mui/icons-material'
import { EnumKindOfPerson, TypeCustomer } from 'enums'
import { Typography, Box, Grid, TextField, Autocomplete, Stack, Button, Divider } from '@mui/material'
import { FieldValues, Controller } from 'react-hook-form'
import { useForm } from '@refinedev/react-hook-form'
import { Create, useAutocomplete } from '@refinedev/mui'
import { useGo } from '@refinedev/core'

export function CustomerCreate () {
  const go = useGo()
  const [zipCode] = useState<string | null>(null)
  const [street, setStreet] = useState<string | null>(null)
  const [complement, setComplement] = useState<string | null>(null)
  const [city, setCity] = useState<string | null>(null)
  const [state, setState] = useState<string | null>(null)
  const [states, setStates] = useState<IState[]>()
  const [districts, setDistricts] = useState<IDistrict[]>()
  const [stateName, setStateName] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      const json = await data.json()
      setStates(json)
    }
    fetchData()
      .catch(console.error)
  }, [])

  useEffect(() => {
    const stateSelected = states?.find(state => state.nome === stateName)
    const fetchData = async () => {
      const data = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateSelected?.id}/distritos`)
      const json = await data.json()
      setDistricts(json)
    }
    fetchData()
      .catch(console.error)
  }, [stateName])

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://viacep.com.br/ws/${zipCode}/json`)
      const json = await data.json()
      if (json.logradouro) {
        setStreet(json?.logradouro)
        setCity(json?.localidade)
        setComplement(json?.complemento)
        setState(json?.uf)
      }
    }
    fetchData()
      .catch(console.error)
  }, [zipCode])

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      resource: 'simecCustomers',
      redirect: false,
      onMutationSuccess: () => {
        go({ to: '/clientes' })
      }
    }
  })

  const { autocompleteProps } = useAutocomplete<IUser>({
    resource: 'users',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'name',
            'email',
            'profile',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    },
    pagination: {
      current: 1,
      pageSize: 25
    },
    filters: [{
      field: 'profile',
      operator: 'eq',
      value: '2'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '3'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '6'
    }]
  })

  const handleSubmitCreate = (values: FieldValues) => {
    const { type_customer: typeCustomer } = values
    onFinish({
      ...values,
      type_customer: Object.values(TypeCustomer).indexOf(typeCustomer)
    })
  }

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Cliente</Typography>
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column' }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('name', { required: true })}
                error={!!errors?.name}
                helperText={errors.name?.message as ReactNode}
                label="Razão Social/Nome Completo"
                name="name"
                margin='normal'
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('cnpj', {
                  required: 'CPF/CNPJ é obrigatório!',
                  minLength: {
                    value: 3,
                    message: 'CPF/CNPJ deve conter no mínimo 3 letras'
                  },
                  maxLength: {
                    value: 150,
                    message: 'CPF/CNPJ deve conter no máximo 150 letras'
                  },
                  validate: (value) => {
                    const cpfCnpj = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/
                    return !cpfCnpj.test(value) ? 'Informe um CPF ou CNPJ válido!' : true
                  }
                })}
                error={!!errors?.cnpj}
                helperText={errors.cnpj?.message as ReactNode}
                label="CPF/CNPJ"
                name="cnpj"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('inscricao_estadual')}
                error={!!errors?.inscricao_estadual}
                helperText={errors.inscricao_estadual?.message as ReactNode}
                label="Inscrição Estadual"
                name="inscricao_estadual"
                margin='normal'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="type_person"
                rules={{ required: 'Tipo de Pessoa é obrigatório!' }}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    {...field}
                    options={Object.values(EnumKindOfPerson).filter(value => typeof value === 'string')}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Pessoa"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.type_person}
                        helperText={errors.type_person?.message as ReactNode}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="type_customer"
                rules={{ required: 'Tipo de Cliente é obrigatório!' }}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    {...field}
                    options={Object.values(TypeCustomer).filter(value => typeof value === 'string')}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Cliente"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.type_customer}
                        helperText={errors.type_customer?.message as ReactNode}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('cep', {
                  required: 'CEP é obrigatório!',
                  validate: (value: string) => {
                    const isValidBRZip = /^[0-9]{8}$/
                    if (!isValidBRZip.test(value)) return 'Informe um CEP válido!'
                    return true
                  }
                })}
                // onChange={(event) => {
                //   const isValidBRZip = /^[0-9]{8}$/
                //   if (!isValidBRZip.test(event.target.value)) return 'Informe um CEP válido!'
                //   setZipCode(event.target.value)
                // }}
                error={!!errors?.cep}
                helperText={errors.cep?.message as ReactNode}
                placeholder='XXXXXXXX'
                label="CEP"
                name="cep"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="state_abbreviation"
                rules={{ required: 'Estado é obrigatório!' }}
                render={({ field }) => (
                  <Autocomplete
                    groupBy={(option) => {
                      const match = states?.find(state => state.nome === option)
                      return match?.regiao.nome || ''
                    }}
                    {...field}
                    disablePortal
                    options={states?.map(state => state) || []}
                    value={state || field.value}
                    onChange={(_, value) => {
                      setStateName(value.nome)
                      field.onChange(value.sigla)
                    }}
                    getOptionLabel={(item) => {
                      return item?.nome
                        ? item.nome
                        : item
                    }}
                    isOptionEqualToValue={(
                      option,
                      value
                    ) => {
                      return (
                        option.nome === value ||
                          option.nome === value.nome
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        variant="outlined"
                        margin='normal'
                        required
                        error={!!errors.state_abbreviation}
                        helperText={errors.state_abbreviation?.message as ReactNode}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="county_name"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={districts?.map(district => district?.nome) || []}
                    onChange={(_, value: string) => {
                      field.onChange(value)
                    }}
                    value={city || field.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        variant="outlined"
                        margin='normal'
                        required
                        error={!!errors.county_name}
                        helperText={errors.county_name?.message as ReactNode}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('address', {
                  required: 'Endereço é obrigatório!',
                  minLength: {
                    value: 1,
                    message: 'Endereço deve conter no mínimo 1 letra'
                  },
                  maxLength: {
                    value: 250,
                    message: 'Endereço deve conter no máximo 250 letras'
                  }
                })}
                error={!!errors?.address}
                helperText={errors.address?.message as ReactNode}
                label="Endereço"
                name="address"
                margin="normal"
                required
                fullWidth
                onChange={(e) => setStreet(e.target.value)}
                value={street}
                InputLabelProps={{ shrink: !!street }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('number')}
                error={!!errors?.number}
                helperText={errors.number?.message as ReactNode}
                label="Número"
                name="number"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                {...register('complement')}
                error={!!errors?.complement}
                helperText={errors.complement?.message as ReactNode}
                label="Complemento"
                name="complement"
                margin="normal"
                value={complement || ''}
                onChange={(e) => setComplement(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>(Opcional) Adicione o cliente a carteira de algum usuario</Divider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="userId"
                render={({ field }) => (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value: IUser | null) => {
                      field.onChange(value?.id)
                    }}
                    getOptionLabel={(item: IUser) => {
                      return item.name
                        ? item.name
                        : autocompleteProps?.options?.find(
                          (p) =>
                            p.id.toString() ===
                                item.toString()
                        )?.name ?? ''
                    }}
                    isOptionEqualToValue={(option: any, value: any) => {
                      return value === undefined || option.id === value.id
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Usuarios"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.userId}
                        helperText={errors.userId?.message as ReactNode}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
          <Button type="submit" variant="contained"><Save/> Adicionar</Button>
        </Stack>
      </form>
    </Create>
  )
}
