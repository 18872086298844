import React, { useState } from 'react'
import { Box, Theme, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'

const Dropzone = styled(Box)(({ theme, isDragActive }: { theme: Theme; isDragActive: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderWidth: 2,
  borderRadius: 2,
  borderColor: theme.palette.grey[500],
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.grey[500],
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
  },
  ...(isDragActive && {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  })
}))

export const FileInput = ({ onFileChange }) => {
  const [isDragActive, setIsDragActive] = useState<boolean>(false)
  const theme = useTheme()

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragActive(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setIsDragActive(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    handleFiles(files)
    setIsDragActive(false)
  }

  const handleChange = (e) => {
    const files = e.target.files
    handleFiles(files)
  }

  const handleFiles = (files) => {
    if (files.length > 0) {
      onFileChange(files)
    }
  }

  const handleClick = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.multiple = true
    fileInput.addEventListener('change', handleChange)
    fileInput.click()
  }

  return (
    <Dropzone
      theme={theme}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      isDragActive={isDragActive}
    >
      <Typography variant="subtitle1">
        {isDragActive ? 'Solte os arquivos' : 'Clique ou arraste os arquivos aqui'}
      </Typography>
    </Dropzone>
  )
}
