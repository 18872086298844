import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { TOKEN_KEY } from 'config/client'

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: TOKEN_KEY ? `Bearer ${TOKEN_KEY}` : ''
    }
  }
})

const httpLink = authLink.concat(new HttpLink({
  uri: process.env.NODE_ENV !== 'development' ? '/graphql' : 'http://localhost:3000/graphql'
}))

// const wsLink = authLink.concat(new WebSocketLink({
//   uri: process.env.NODE_ENV !== 'development' ? 'wss://sistema.braconsultoria.com/graphql' : 'ws://localhost:3000/graphql',
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: TOKEN_KEY
//     }
//   }
// }))

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    )
  },
  // wsLink,
  httpLink
)

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default apolloClient
