import React from 'react'
import { CanAccess, useGetIdentity, useShow } from '@refinedev/core'
import { DateField, RefreshButton, Show } from '@refinedev/mui'
import { Button, Grid, Typography } from '@mui/material'
import { IUser } from 'interfaces'
import { EnumUserProfile } from 'enums'
import { useModalForm } from '@refinedev/react-hook-form'
import { Key } from '@mui/icons-material'
import { ChangePassword } from 'components/passwords'

export function UserShow () {
  const metaData = {
    fields: [
      'id',
      'name',
      'email',
      'access',
      'cpf',
      'profile',
      'createdAt',
      'updatedAt',
      'deletedAt'
    ]
  }
  const { queryResult, showId } = useShow<IUser>({
    meta: metaData
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const changePasswordModal = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'password',
      metaData: {
        operation: 'user',
        fields: ['id']
      }
    }
  })

  const {
    modal: { show: showChangePasswordModal }
  } = changePasswordModal

  return (
    <>
      <ChangePassword {...changePasswordModal} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Show
            isLoading={isLoading}
            headerProps={{
              title: <Typography variant="h5">Usuario: {record?.name}</Typography>,
              action: (
                <>
                  <RefreshButton meta={metaData} recordItemId={showId} />
                  {
                    record?.id && user?.id && (
                      <CanAccess resource="passwords" action="update" params={{ userId: record?.id, myId: user?.id }}>
                        <Button
                          color='warning'
                          startIcon={<Key />}
                          onClick={() => showChangePasswordModal(record?.id)}
                        >Alterar Senha</Button>
                      </CanAccess>
                    )
                  }
                </>
              )
            }}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Login
                </Typography>
                <Typography variant="body2">{record?.access}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    E-mail - BRA
                </Typography>
                <Typography variant="body2">{record?.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Perfil
                </Typography>
                <Typography variant="body2">{EnumUserProfile[record?.profile || '']}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    CPF
                </Typography>
                <Typography variant="body2">{record?.cpf}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Criado em
                </Typography>
                <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.createdAt} /></Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Atualizado em
                </Typography>
                <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.updatedAt} /></Typography>
              </Grid>
              {record?.deletedAt
                ? <Grid item xs={12} md={6} lg={3}>
                  <Typography variant="body1" fontWeight="bold">
                    Deletado em
                  </Typography>
                  <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.deletedAt} /></Typography>
                </Grid>
                : null }
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Status
                </Typography>
                <Typography variant="body2">{record?.deletedAt ? 'Inativo' : 'Ativo'}</Typography>
              </Grid>
            </Grid>
          </Show>
        </Grid>
      </Grid>
    </>
  )
}
