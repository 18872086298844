import { useTheme } from '@mui/material/styles'
import { Chip, ChipProps } from '@mui/material'
import { EnumStatus } from 'enums'

type StatusCampaignProps = {
  status: EnumStatus;
};

export const CampaignStatus: React.FC<StatusCampaignProps> = ({ status }) => {
  const { palette } = useTheme()

  let color: ChipProps['color']
  let label = ''

  switch (status) {
    case 0:
      color = 'info'
      label = 'Pendente'
      break
    case 1:
      color = 'success'
      label = 'Em Andamento'
      break
    case 2:
      color = 'error'
      label = 'Cancelado'
      break
    case 3:
      color = palette.mode === 'dark' ? 'default' : 'secondary'
      label = 'Finalizado'
      break
    default:
      color = palette.mode === 'dark' ? 'default' : 'secondary'
      break
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      color={color}
      label={label}
    />
  )
}
