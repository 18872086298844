import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useCreate, useShow } from '@refinedev/core'
import { Card, Button, Chip, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import { Clear, Close, DocumentScanner, Download, Save } from '@mui/icons-material'
import { ModelContract, TypeContract } from 'enums'
import { DateField, DeleteButton, ShowButton } from '@refinedev/mui'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { IContract, IContractFiles } from 'interfaces'
import { Document, Page } from 'react-pdf'

import './style.css'
import { FileInput } from 'components/file-input'
import { axiosInstance } from 'config/axios'
import { LoadingButton } from '@mui/lab'

export default function ShowContract ({ contractId, open, handleClose }) {
  if (!contractId) return null

  const [openFileModal, setOpenFileModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<IContractFiles | null>(null)
  const [files, setFiles] = useState<any[]>([])
  const [isLoadingDoc, setIsLoadingDoc] = useState(false)

  const { mutate: mutateCreate } = useCreate<any[]>()

  const handleFileChange = async (selectedFiles: FileList) => {
    const uploadedFiles: any[] = []
    for (const file of Array.from(selectedFiles)) {
      const formData = new FormData()
      formData.append('upload', file)
      const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post<{ url: string, key: string }>(
        'file/upload',
        formData,
        {
          withCredentials: false,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      uploadedFiles.push({
        file_name: file.name,
        file_url: data.url,
        mimetype: file.type,
        key: data.key,
        size: file.size,
        last_modified_date: new Date(file.lastModified)
      })
    }
    setFiles([...files, ...uploadedFiles])
  }

  const handleDeleteFile = async (fileToDelete: IContractFiles) => {
    const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    try {
      await axiosInstance.delete('file/remove', {
        data: { key: fileToDelete.key },
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
    } catch (error) {
      console.error('Error deleting file:', error)
    }
    setFiles(files.filter((file) => file !== fileToDelete))
  }

  const handleAddFile = async () => {
    try {
      mutateCreate({
        resource: 'manyContractFile',
        invalidates: ['all'],
        values: {
          files,
          contractId
        },
        metaData: {
          fields: []
        },
        successNotification: {
          type: 'success',
          message: 'Arquivo(s) adicionado(s) com sucesso!',
          undoableTimeout: 3000
        }
      })
    } catch (error) {
      console.error('Error deleting file:', error)
    }
    setFiles([])
  }

  const handleModalOpen = (file: IContractFiles) => {
    setSelectedFile(file)
    setOpenFileModal(true)
  }

  const handleFileModalClose = () => {
    setOpenFileModal(false)
  }

  async function generateDocx (contractId: string, customerName: string) {
    setIsLoadingDoc(true)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    const { data } = await axiosInstance.post('docs/contract', {
      contractId
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    })

    if (data) {
      const blob = new Blob([data], { type: 'application/msword' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${customerName}-${new Date().toLocaleDateString()}.doc`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      setIsLoadingDoc(false)
    }
  }

  const fileColumns = useMemo<GridColumns<IContractFiles>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} type='button' onClick={() => handleModalOpen(row)} />
              <DeleteButton
                hideText
                recordItemId={row.id}
                resource="contractFile"
                invalidates={['all']}
              />
            </Stack>
          )
        }
      },
      {
        field: 'file_name',
        headerName: 'Título',
        flex: 1,
        minWidth: 250
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Criado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      }
    ],
    []
  )

  const { queryResult } = useShow<IContract>({
    id: contractId,
    resource: 'contract',
    meta: {
      operation: 'contract',
      fields: [
        'id',
        'title',
        'start_date',
        'end_date',
        'start_hour',
        'end_hour',
        'type',
        'model',
        'qty',
        'value_unit',
        'value_total',
        'value_in_writing',
        'speaker_name',
        'speaker_cpf',
        'createdAt',
        'updatedAt',
        'deletedAt',
        {
          files: [
            'id',
            'key',
            'file_name',
            'file_url',
            'mimetype',
            'last_modified_date',
            'size',
            'createdAt',
            {
              createdBy: ['id', 'name']
            }
          ]
        },
        {
          createdBy: ['id', 'name']
        },
        {
          updatedBy: ['id', 'name']
        }
      ]
    }
  })
  const { data, isLoading } = queryResult
  const contract = data?.data

  const handleDownload = async (fileUrl: string | undefined) => {
    if (!fileUrl) return
    try {
      const response = await fetch(fileUrl)
      const blob = await response.blob()
      const fileName = fileUrl.split('/').pop() || 'downloaded_file'

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = fileName

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error)
    }
  }

  function renderSwitch () {
    switch (true) {
      case ['image', 'png', 'jpg', 'jpeg'].includes(selectedFile?.mimetype ?? ''):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <img
                  src={selectedFile?.file_url}
                  srcSet={selectedFile?.file_url}
                  alt={selectedFile?.file_name}
                  loading="lazy"
                  style={{
                    maxHeight: '900px',
                    maxWidth: '100%'
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        )
      case selectedFile?.mimetype?.includes('pdf'):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <Document
                  file={selectedFile?.file_url}
                  loading={'Carregando PDF, por favor aguarde um momento...'}
                >
                  <Page
                    pageNumber={1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="pdf-page"
                  />
                </Document>
              </Card>
            </Grid>
          </Grid>
        )
      case selectedFile?.mimetype?.includes('officedocument'):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <iframe
                  src={`https://docs.google.com/gview?url=${selectedFile?.file_url}&embedded=true`}
                  loading="lazy"
                  style={{
                    maxWidth: '700px',
                    maxHeight: '900px',
                    minHeight: '600px',
                    minWidth: '500px'
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        )
      default:
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <iframe
                  src={`https://docs.google.com/gview?url=${selectedFile?.file_url}&embedded=true`}
                  loading="lazy"
                  style={{
                    maxWidth: '700px',
                    maxHeight: '900px',
                    minHeight: '600px',
                    minWidth: '500px'
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        )
    }
  }

  const Modalfile = () => (
    <Modal open={openFileModal} onClose={handleFileModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90%',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            paddingBottom: '25px'
          }}
        >
          <Close
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'red',
              cursor: 'pointer'
            }}
            onClick={handleFileModalClose}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {renderSwitch()}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: 2
              }}
            >
              <Typography variant="h5">{selectedFile?.file_name}</Typography>
              <Typography variant="body2">
              Última modificação:{' '}
                {selectedFile?.last_modified_date
                  ? (
                    <DateField format="D/M/YY" value={selectedFile?.last_modified_date} />
                  )
                  : null}
              </Typography>
              <Typography variant="body2">
              Criado em: <DateField format="D/M/YY" value={selectedFile?.createdAt} />
              </Typography>
              <Box marginTop={2}>
                <Typography variant="h6">Ações:</Typography>
                <Stack direction="row" spacing={1}>
                  <DeleteButton
                    hideText
                    recordItemId={selectedFile?.id}
                    resource="contractFile"
                    invalidates={['all']}
                  />
                  <IconButton
                    color="primary"
                    onClick={() => handleDownload(selectedFile?.file_url)}
                  >
                    <Download />
                  </IconButton>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '90%',
            overflow: 'auto'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: 0,
              overflow: 'hidden',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              paddingBottom: '50px'
            }}
          >
            <Typography paddingLeft={4} paddingTop={2} variant="h5">Título: {contract?.title}</Typography>
            <Close
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'red',
                cursor: 'pointer'
              }}
              onClick={handleClose}
            />
          </Box>
          <Grid container spacing={2} padding={4}>
            {isLoading && (
              <LinearProgress
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0
                }}
              />
            )}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Data de início:
              </Typography>
              <Typography variant="body2">{<DateField format='D/M/YY' value={contract?.start_date} />}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Data de término:
              </Typography>
              <Typography variant="body2">{contract?.end_date ? <DateField format='D/M/YY' value={contract?.end_date} /> : 'Não foi informada'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Tipo:
              </Typography>
              <Typography variant="body2">{contract?.type ? TypeContract[contract?.type] : null}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Modelo:
              </Typography>
              <Typography variant="body2">{contract?.model ? ModelContract[contract?.model] : null}</Typography>
            </Grid>
            {
              contract?.speaker_name && (
                <Grid item xs={12} md={6} lg={3}>
                  <Typography variant="body1" fontWeight="bold">
                    Palestrante
                  </Typography>
                  <Typography variant="body2">{contract?.speaker_name} - {contract?.speaker_cpf}</Typography>
                </Grid>
              )
            }
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Valor Unit:
              </Typography>
              <Typography variant="body2">{(contract?.value_unit ?? 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Quantidade:
              </Typography>
              <Typography variant="body2">{contract?.qty}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Valor Total:
              </Typography>
              <Typography variant="body2">{(contract?.value_total ?? 0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Valor Total por extenso:
              </Typography>
              <Typography variant="body2">{contract?.value_in_writing}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Criado em:
              </Typography>
              <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={contract?.createdAt} /></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Criado por:
              </Typography>
              <Typography variant="body2">{contract?.createdBy?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Atualizado em:
              </Typography>
              <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={contract?.updatedAt} /></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
              Atualizado por:
              </Typography>
              <Typography variant="body2">{contract?.updatedBy?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <LoadingButton
                  loading={isLoadingDoc}
                  onClick={() => generateDocx(contract?.id ?? '', contract?.title ?? '')}
                  loadingPosition="end"
                  color='info'
                  variant='contained'
                >Gerar Contrato</LoadingButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography paddingTop={2} variant="h5">Arquivos:</Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={contract?.files ?? []}
                columns={fileColumns}
                density='compact'
                autoHeight
                pagination
                paginationMode='server'
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paddingTop={2} variant="h5">Para adicionar novos arquivos use o campo abaixo:</Typography>
            </Grid>
            <Grid item xs={12}>
              <FileInput onFileChange={handleFileChange} />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                  paddingTop: 2
                }}
              >
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.key}
                    onDelete={() => handleDeleteFile(file)}
                    deleteIcon={<Clear />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack paddingTop={2} justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleAddFile()}
                  startIcon={
                    <Save />
                  }>Salvar</Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modalfile />
    </>
  )
}
