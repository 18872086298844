import React, { useEffect, useMemo, useState } from 'react'
import { ICustomerFilterVariables, ISimec, IWallet } from 'interfaces'
import { getBackgroundColor, getHoverBackgroundColor } from 'contexts'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { Stack, Typography, Grid, Box, Theme, Card, CardHeader, Button, Autocomplete, TextField, CardContent, useTheme, Divider, Chip, Collapse } from '@mui/material'
import { GridRowId, GridColumns, DataGrid, GridLinkOperator, GridToolbar, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { HttpError, CrudFilters, useNavigation, useList, useInvalidate, useCreateMany, useGetIdentity, BaseRecord, getDefaultFilter, useTranslate } from '@refinedev/core'
import { ShowButton, DateField, useDataGrid, List, useAutocomplete } from '@refinedev/mui'
import { DailyAttendances, NewCustomers, WidgetStatusAttendances } from 'components/widgets'
import { Add, Close, FiberManualRecord, FileDownload, Save, Wallet } from '@mui/icons-material'
import { useForm } from '@refinedev/react-hook-form'
import { Controller } from 'react-hook-form'
import states from 'data/states.json'
import { AttendanceStatus } from 'components/attendance'
import AttendanceCalendar from 'components/widgets/attendance-calendar'
import { axiosInstance } from 'config/axios'
import { LoadingButton } from '@mui/lab'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function CustomerList () {
  const tableWalletId = 'commercialWallet'
  const tableAllId = 'commercialAll'

  const t = useTranslate()
  const theme = useTheme()
  const invalidate = useInvalidate()
  const { create } = useNavigation()
  const { data: user }: any = useGetIdentity()
  const { mutate: createMany } = useCreateMany<number[]>()
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState(null)
  const [selectedAllAttendanceStatus, setSelectedAllAttendanceStatus] = useState(null)
  const [isCalendarCollapsed, setIsCalendarCollapsed] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)

  const { dataGridProps, search: searchWallet, filters: filtersWallet } = useDataGrid<IWallet,
  HttpError,
  ICustomerFilterVariables
  >({
    resource: 'myWallet',
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    metaData: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'createdAt',
            {
              createdBy: ['id', 'name']
            },
            'updatedAt',
            {
              updatedBy: ['id', 'name']
            },
            {
              customer: [
                'id',
                'cnpj',
                'inscricao_estadual',
                'name',
                'cep',
                'number',
                'address',
                'county_name',
                'state_abbreviation',
                'attendance_status',
                'status',
                'mail',
                'createdAt',
                {
                  createdBy: ['id', 'name']
                },
                'updatedAt',
                {
                  updatedBy: ['id', 'name']
                },
                'deletedAt',
                {
                  contracts: ['id', 'start_date', 'end_date']
                }
              ]
            }
          ]
        }
      ],
      operation: 'myWallet'
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    onSearch: (params) => {
      const filtersWallet: CrudFilters = []
      const { name, cnpj, state_abbreviation: stateName, county_name: city, attendance_status: attendanceStatus } = params

      filtersWallet.push({
        field: 'name',
        operator: 'in',
        value: name && name.length > 0 ? name?.toString() : undefined
      })

      filtersWallet.push({
        field: 'cnpj',
        operator: 'contains',
        value: `%${cnpj}%`
      })

      filtersWallet.push({
        field: 'state_abbreviation',
        operator: 'in',
        value: stateName && stateName.length > 0 ? stateName?.toString() : undefined
      })

      filtersWallet.push({
        field: 'county_name',
        operator: 'contains',
        value: `%${city}%`
      })

      filtersWallet.push({
        field: 'attendance_status',
        operator: 'eq',
        value: attendanceStatus
      })

      return filtersWallet
    }
  })

  const { handleSubmit: handleSubmitWallet, control: controlWallet, register: registerWallet, setValue: setValueWallet } = useForm<
  BaseRecord,
  HttpError,
  ICustomerFilterVariables
  >({
    defaultValues: {
      name: getDefaultFilter('name', filtersWallet, 'in'),
      cnpj: getDefaultFilter('cnpj', filtersWallet, 'contains'),
      state_abbreviation: getDefaultFilter('state_abbreviation', filtersWallet, 'in'),
      county_name: getDefaultFilter('county_name', filtersWallet, 'contains'),
      attendance_status: getDefaultFilter('attendance_status', filtersWallet, 'eq')
    }
  })

  const handleFilterWalletAttendance = (attendanceStatus, searchFn) => {
    if (selectedAttendanceStatus === attendanceStatus) {
      setSelectedAttendanceStatus(null)
      setValueWallet('attendance_status', undefined)
    } else {
      setSelectedAttendanceStatus(attendanceStatus)
      setValueWallet('attendance_status', attendanceStatus)
    }
    searchFn()
  }

  const { autocompleteProps: customerProps } = useAutocomplete<ISimec>({
    resource: 'simecCustomers',
    metaData: {
      fields: [
        {
          nodes: ['id', 'name']
        }
      ]
    },
    pagination: {
      current: 1,
      pageSize: 25
    },
    sort: [
      {
        field: 'name',
        order: 'asc'
      }
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'contains',
        value: `%${value}%`
      }
    ]
  })

  const {
    data
  } = useList<any, HttpError>({
    resource: 'getUserWalletInfo',
    pagination: {
      mode: 'off'
    },
    meta: {
      operation: 'getUserWalletInfo',
      fields: [
        {
          customers: [
            'total',
            'addedLastWeek',
            {
              dailyReport: [
                'day',
                'count'
              ]
            }
          ]
        },
        {
          attendances: [
            'total',
            {
              dailyReport: [
                'day',
                'count'
              ]
            }
          ]
        }
      ]
    }
  })

  const info: any = data?.data

  const {
    data: dataAttendance
  } = useList<any, HttpError>({
    resource: 'getAttendanceStatusCounts',
    pagination: {
      mode: 'off'
    },
    meta: {
      operation: 'getAttendanceStatusCounts',
      fields: [
        {
          wallet: [
            'in_progress',
            'urgent',
            'finished',
            'pending'
          ]
        },
        {
          all: [
            'in_progress',
            'urgent',
            'finished',
            'pending'
          ]
        }
      ]
    }
  })

  const attendanceStatus: any = dataAttendance?.data

  const { dataGridProps: allCustomers, search, filters } = useDataGrid<ISimec,
  HttpError,
  ICustomerFilterVariables
  >({
    resource: 'simecCustomers',
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    metaData: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'code',
            'cnpj',
            'inscricao_estadual',
            'name',
            'number',
            'mail',
            'county_name',
            'state_abbreviation',
            'attendance_status',
            'cep',
            'address',
            'status',
            'isInWallet',
            'createdAt',
            {
              createdBy: ['id', 'name']
            },
            'updatedAt',
            {
              updatedBy: ['id', 'name']
            },
            {
              contracts: ['id', 'start_date', 'end_date']
            },
            'deletedAt'
          ]
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { name, cnpj, state_abbreviation: stateName, county_name: city, attendance_status: attendanceStatus } = params

      filters.push({
        field: 'name',
        operator: 'in',
        value: name && name.length > 0 ? name?.toString() : undefined
      })

      filters.push({
        field: 'cnpj',
        operator: 'contains',
        value: `%${cnpj}%`
      })

      filters.push({
        field: 'state_abbreviation',
        operator: 'in',
        value: stateName && stateName.length > 0 ? stateName?.toString() : undefined
      })

      filters.push({
        field: 'county_name',
        operator: 'contains',
        value: `%${city}%`
      })

      filters.push({
        field: 'attendance_status',
        operator: 'eq',
        value: attendanceStatus
      })

      return filters
    }
  })

  const { handleSubmit: handleSubmitAllCustomers, control: controlAllCustomers, register: registerAllCustomers, setValue: setValueAll } = useForm<
  BaseRecord,
  HttpError,
  ICustomerFilterVariables
  >({
    defaultValues: {
      name: getDefaultFilter('name', filters, 'in'),
      cnpj: getDefaultFilter('cnpj', filters, 'contains'),
      state_abbreviation: getDefaultFilter('state_abbreviation', filters, 'in'),
      county_name: getDefaultFilter('county_name', filters, 'contains'),
      attendance_status: getDefaultFilter('attendance_status', filters, 'eq')
    }
  })

  const handleFilterAllAttendance = (attendanceStatus, searchFn) => {
    if (selectedAllAttendanceStatus === attendanceStatus) {
      setSelectedAllAttendanceStatus(null)
      setValueAll('attendance_status', undefined)
    } else {
      setSelectedAllAttendanceStatus(attendanceStatus)
      setValueAll('attendance_status', attendanceStatus)
    }
    searchFn()
  }

  const handleInvalidate = () => {
    invalidate({
      resource: 'simecCustomers',
      invalidates: ['all']
    })
  }

  async function generateXlsx () {
    setLoading(true)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    const { data } = await axiosInstance.post('simec/xlsx', {
      filters
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    })
    if (data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'prefeituras.xlsx')
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      setLoading(false)
    }
  }

  const handleCustomersToMyWallet = () => {
    createMany({
      resource: 'customersWallet',
      values: selectionModel.map((customerId) => ({ customerId, userId: user?.id })),
      successNotification: {
        type: 'success',
        message: 'Clientes adicionados a sua carteira com sucesso!',
        undoableTimeout: 3000
      }
    }, {
      onSuccess: () => {
        setSelectionModel([])
        setIsClicked(false)
        handleInvalidate()
      }
    })
  }

  const [columnVisibilityModelWallet, setColumnVisibilityModelWallet] = useState<GridColumnVisibilityModel>({
    actions: !isClicked,
    'customer.name': true,
    cnpj: true,
    'customer.contracts': true,
    attendance_status: true,
    address: true,
    county_name: true,
    state_abbreviation: true,
    createdAt: true,
    'createdBy.name': true,
    'customer.createdBy.name': true,
    'customer.updatedAt': true,
    'customer.updatedBy.name': true
  })

  const columnsWallet = useMemo<GridColumns<IWallet>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hide: isClicked,
        hideable: isClicked,
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.customer?.id} />
            </Stack>
          )
        }
      },
      {
        field: 'customer.name',
        headerName: 'Razão Social',
        flex: 2,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer?.name
        }
      },
      {
        field: 'cnpj',
        headerName: 'CNPJ',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer?.cnpj
        }
      },
      {
        field: 'customer.contracts',
        headerName: 'Contrato',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: function render ({ row }) {
          if (row.customer?.contracts?.length > 0) {
            const recentContract = row.customer?.contracts?.reduce((recent, contract) => {
              return new Date(recent.start_date) > new Date(contract.start_date) ? recent : contract
            })

            const endDate = new Date(recentContract.end_date)
            const currentDate = new Date()
            const timeDiff = Math.abs(endDate.getTime() - currentDate.getTime())
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

            let color
            let status
            if (endDate < new Date()) {
              color = 'error'
              status = 'Vencido'
            } else if (diffDays <= 30) {
              color = 'error'
              status = `Vence em ${diffDays} dias`
            } else if (diffDays <= 60) {
              color = 'warning'
              status = `Vence em ${diffDays} dias`
            } else if (diffDays <= 90) {
              color = 'info'
              status = `Vence em ${diffDays} dias`
            } else {
              color = 'success'
              status = 'Em dia'
            }

            return <Chip icon={<FiberManualRecord />} label={status} color={color} size='small' />
          }

          return null
        }
      },
      {
        field: 'attendance_status',
        headerName: 'Status de Atendimento',
        flex: 1,
        minWidth: 250,
        renderCell: function render ({ row }) {
          return row.customer?.attendance_status ? <AttendanceStatus status={row.customer?.attendance_status} /> : null
        }
      },
      {
        field: 'address',
        headerName: 'Endereço',
        flex: 2,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer?.address
        }
      },
      {
        field: 'county_name',
        headerName: 'Cidade',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer?.county_name
        }
      },
      {
        field: 'state_abbreviation',
        headerName: 'Estado',
        flex: 1,
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.customer?.state_abbreviation
        }
      },
      {
        field: 'createdAt',
        headerName: 'Adicionado a sua carteira em',
        flex: 3,
        minWidth: 250,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Adicionado a sua carteira por',
        flex: 3,
        minWidth: 250,
        type: 'date',
        renderCell: function render ({ row }) {
          return row?.createdBy?.name
        }
      },
      {
        field: 'customer.createdBy.name',
        headerName: 'Cliente criado por',
        flex: 3,
        minWidth: 250,
        renderCell: function render ({ row }) {
          return row.customer?.createdBy?.name
        }
      },
      {
        field: 'customer.updatedAt',
        headerName: 'Cliente atualizado em',
        flex: 3,
        minWidth: 250,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'customer.updatedBy.name',
        headerName: 'Cliente atualizado por',
        flex: 3,
        minWidth: 250,
        renderCell: function render ({ row }) {
          return row.updatedBy?.name
        }
      }
    ],
    []
  )

  const [columnVisibilityModelAll, setColumnVisibilityModelAll] = useState<GridColumnVisibilityModel>({
    actions: !isClicked,
    name: true,
    cnpj: true,
    'customer.contracts': true,
    isInWallet: true,
    attendance_status: true,
    county_name: true,
    state_abbreviation: true,
    cep: true,
    address: true,
    createdAt: true,
    'createdBy.name': true,
    updatedAt: true,
    deletedAt: true
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const walletTableData = parsedData.find(item => item.tableId === tableWalletId)
      if (walletTableData) {
        setColumnVisibilityModelWallet(walletTableData.column_visibility)
      }

      const allTableData = parsedData.find(item => item.tableId === tableAllId)
      if (allTableData) {
        setColumnVisibilityModelAll(allTableData.column_visibility)
      }
    }
  }, [])

  const columnsAll: any = [
    {
      headerName: 'Ações',
      field: 'actions',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      hide: isClicked,
      hideable: isClicked,
      type: 'actions',
      renderCell: function render ({ row }) {
        return (
          <Stack direction="row" spacing={1}>
            <ShowButton hideText recordItemId={row.id} />
          </Stack>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Razão Social',
      flex: 2,
      minWidth: 200
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      flex: 1,
      minWidth: 200
    },
    {
      field: 'customer.contracts',
      headerName: 'Contrato',
      flex: 3,
      minWidth: 200,
      sorteable: false,
      renderCell: function render ({ row }) {
        if (row.contracts?.length > 0) {
          const recentContract = row.contracts.reduce((recent, contract) => {
            return new Date(recent.start_date) > new Date(contract.start_date) ? recent : contract
          })

          const endDate = new Date(recentContract.end_date)
          const currentDate = new Date()
          const timeDiff = Math.abs(endDate.getTime() - currentDate.getTime())
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

          let color
          let status
          if (endDate < new Date()) {
            color = 'error'
            status = 'Vencido'
          } else if (diffDays <= 30) {
            color = 'error'
            status = `Vence em ${diffDays} dias`
          } else if (diffDays <= 60) {
            color = 'warning'
            status = `Vence em ${diffDays} dias`
          } else if (diffDays <= 90) {
            color = 'info'
            status = `Vence em ${diffDays} dias`
          } else {
            color = 'success'
            status = 'Em dia'
          }

          return <Chip icon={<FiberManualRecord />} label={status} color={color} size='small' />
        }

        return null
      }
    },
    {
      field: 'isInWallet',
      headerName: 'Carteira',
      flex: 3,
      minWidth: 200,
      sorteable: false,
      renderCell: function render ({ row }) {
        return row.isInWallet ? 'Está na sua carteira' : ''
      }
    },
    {
      field: 'attendance_status',
      headerName: 'Status de Atendimento',
      flex: 1,
      minWidth: 250,
      renderCell: function render ({ row }) {
        return row.attendance_status ? <AttendanceStatus status={row.attendance_status} /> : null
      }
    },
    {
      field: 'county_name',
      headerName: 'Cidade',
      flex: 1,
      minWidth: 200
    },
    {
      field: 'state_abbreviation',
      headerName: 'Estado',
      flex: 1,
      minWidth: 100
    },
    {
      field: 'cep',
      headerName: 'CEP',
      flex: 1,
      minWidth: 150,
      hide: false,
      getApplyQuickFilterFn: undefined
    },
    {
      field: 'address',
      headerName: 'Endereço',
      flex: 2,
      minWidth: 300,
      hide: false,
      getApplyQuickFilterFn: undefined,
      renderCell: function render ({ row }) {
        return `${row.address} ${row.number}`
      }
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      flex: 1,
      minWidth: 150,
      type: 'date',
      renderCell: function render ({ row }) {
        return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
      }
    },
    {
      field: 'createdBy.name',
      headerName: 'Criado por',
      flex: 3,
      minWidth: 250,
      renderCell: function render ({ row }) {
        return row.createdBy?.name
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Atualizado em',
      flex: 1,
      minWidth: 150,
      type: 'date',
      renderCell: function render ({ row }) {
        return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
      }
    },
    {
      field: 'deletedAt',
      headerName: 'Deletado em',
      flex: 1,
      minWidth: 150,
      type: 'date',
      renderCell: function render ({ row }) {
        return row.deletedAt ? <DateField format='HH:mm D/M/YY' value={row.deletedAt} /> : ''
      }
    }
  ]

  const handleCollapseCalendar = () => {
    setIsCalendarCollapsed(!isCalendarCollapsed)
  }

  return (
    <Grid container columns={24} spacing={2}>
      <Grid item xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <DailyAttendances data={info?.attendances?.dailyReport} />
        </Card>
      </Grid>
      <Grid item xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <NewCustomers total={info?.customers?.total} trend={info?.customers?.addedLastWeek} data={info?.customers?.dailyReport} />
        </Card>
      </Grid>
      <Grid item xs={24}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <Button color='info' onClick={handleCollapseCalendar} variant='contained' >{isCalendarCollapsed ? 'Abrir ' : 'Fechar '}Calendário de Atendimentos</Button>
        </Box>
        <Collapse in={!isCalendarCollapsed}>
          <AttendanceCalendar />
        </Collapse>
      </Grid>
      <Grid item xs={24} md={16} xl={18}>
        <List headerProps={{
          title: <Typography variant="h5">Minha Carteira de Clientes</Typography>,
          action: null
        }}
        >
          <Box
            sx={{
              width: '100%',
              '& .super-app-theme--ACTIVED': {
                bgcolor: (theme) =>
                  getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                '&:hover': {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.success.main,
                      theme.palette.mode
                    )
                }
              },
              '& .super-app-theme--DISABLED': {
                bgcolor: (theme) =>
                  getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                '&:hover': {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.error.main, theme.palette.mode
                    )
                }
              }
            }}
          >
            <Card>
              <CardHeader title={'Filtrar'} />
              <CardContent sx={{ pt: 0 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                      control={controlWallet}
                      name="state_abbreviation"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          groupBy={(option) => option?.regiao.nome || ''}
                          multiple
                          options={states}
                          onChange={(_, value) => {
                            field.onChange(
                              value.map((p) => p.sigla ?? p)
                            )
                          }}
                          getOptionLabel={(item) => {
                            return item?.nome
                              ? item.nome
                              : item
                          }}
                          isOptionEqualToValue={(
                            option,
                            value
                          ) => {
                            return (
                              option.nome === value ||
                                  option.nome === value.nome
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Estado'}
                              placeholder={'Filtre por estado'}
                              margin="normal"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      {...registerWallet('county_name')}
                      label={'Cidade'}
                      placeholder={'Filtre por cidade'}
                      margin="normal"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                      control={controlWallet}
                      name="name"
                      render={({ field }) => (
                        <Autocomplete
                          {...customerProps}
                          {...field}
                          multiple
                          onChange={(_, value: any) => {
                            field.onChange(
                              value.map((p) => p.name ?? p)
                            )
                          }}
                          getOptionLabel={(item: any) => {
                            return item.name
                              ? item.name
                              : customerProps?.options?.find(
                                (p) =>
                                  p.id.toString() ===
                              item.toString()
                              )?.name ?? ''
                          }}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return value === undefined || option.id === value.id
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Razão Social'}
                              placeholder={'Filtre por Razão Social'}
                              margin="normal"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      {...registerWallet('cnpj')}
                      label={'CNPJ'}
                      placeholder={'Filtre por CNPJ'}
                      margin="normal"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="right">
                      <Button type="button" variant="contained" onClick={handleSubmitWallet(searchWallet)}>
                        {t('customer.filter.submit')}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <DataGrid
              keepNonExistentRowsSelected
              disableColumnFilter
              {...dataGridProps}
              columns={columnsWallet}
              filterModel={undefined}
              density='compact'
              autoHeight
              pagination
              paginationMode='server'
              getRowClassName={({ row }) => `super-app-theme--${row.status?.toString()}`}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or
                  }
                }
              }}
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                filterPanel: {
                  linkOperators: [GridLinkOperator.And],
                  columnsSort: 'asc',
                  filterFormProps: {
                    linkOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small'
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' }
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' }
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small'
                      }
                    },
                    deleteIconProps: {
                      sx: {
                        '& .MuiSvgIcon-root': { color: '#d32f2f' }
                      }
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-filterForm': { p: 2 },
                    '& .MuiDataGrid-filterForm:nth-child(even)': {
                      backgroundColor: (theme: Theme) =>
                        theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                    },
                    '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                  }
                }
              }}
              columnVisibilityModel={columnVisibilityModelWallet}
              onColumnVisibilityModelChange={(newModel) => {
                setColumnVisibilityModelWallet(newModel)
                handleSaveColumnVisibility({
                  tableId: tableWalletId,
                  userId: user?.id ?? '',
                  column_visibility: newModel
                })
              }}
              localeText={DatagridLanguage()}
            />
          </Box>
        </List>
      </Grid>
      <Grid item xs={24} md={8} xl={6}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.info.main}
              qty={attendanceStatus?.wallet?.pending}
              status={'Não Atendeu'}
              text={'Tratativa não iniciada.'}
              handleClick={() => handleFilterWalletAttendance('3', handleSubmitWallet(searchWallet))}
              isSelected={selectedAttendanceStatus === '3'}
              disabled={(attendanceStatus?.wallet?.pending ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.warning.main}
              qty={attendanceStatus?.wallet?.in_progress}
              status={'Em Progresso'}
              text={'Tratativa em andamento.'}
              handleClick={() => handleFilterWalletAttendance('0', handleSubmitWallet(searchWallet))}
              isSelected={selectedAttendanceStatus === '0'}
              disabled={(attendanceStatus?.wallet?.in_progress ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.error.main}
              qty={attendanceStatus?.wallet?.urgent}
              status={'Urgente'}
              text={'Tratativa de ter prioridade para a finalização.'}
              handleClick={() => handleFilterWalletAttendance('1', handleSubmitWallet(searchWallet))}
              isSelected={selectedAttendanceStatus === '1'}
              disabled={(attendanceStatus?.wallet?.urgent ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.success.main}
              qty={attendanceStatus?.wallet?.finished}
              status={'Finalizado'}
              text={'Tratativa foi concluída.'}
              handleClick={() => handleFilterWalletAttendance('2', handleSubmitWallet(searchWallet))}
              isSelected={selectedAttendanceStatus === '2'}
              disabled={(attendanceStatus?.wallet?.finished ?? 0) === 0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={24}>
        <Box paddingTop={4} paddingBottom={4} position="relative">
          <Chip
            label="Carteira"
            style={{
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
          <Divider style={{ borderColor: 'white', borderWidth: '2px' }} />
          <Chip
            label="Todos"
            style={{
              position: 'absolute',
              top: '90%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={24} md={16} xl={18}>
        <List headerProps={{
          title: <Typography variant="h5">Todos os Clientes</Typography>,
          action: (
            <Stack spacing={2} direction={'row'}>
              {
                !isClicked
                  ? (
                    <>
                      <LoadingButton
                        variant='contained'
                        color='info'
                        onClick={generateXlsx}
                        loading={loading}
                        startIcon={<FileDownload />}
                      >Gerar Excel</LoadingButton>
                      <Button
                        onClick={() => setIsClicked(true)}
                        variant='contained'
                        color='info'
                        startIcon={<Wallet />}
                      >Adicionar a minha carteira</Button>
                      <Button
                        onClick={() => {
                          create('clientes')
                        }}
                        variant='contained'
                        startIcon={<Add />}
                      >Criar novo cliente</Button>
                    </>
                  )
                  : (
                    <>
                      <Button
                        onClick={() => setIsClicked(false)}
                        variant='contained'
                        color='error'
                        startIcon={<Close />}
                      >Cancelar</Button>
                      <Button
                        onClick={() => handleCustomersToMyWallet()}
                        variant='contained'
                        color='success'
                        startIcon={<Save />}
                      >Salvar</Button>
                    </>
                  )
              }
            </Stack>
          )
        }}
        breadcrumb={null}
        >
          <Box
            sx={{
              width: '100%',
              '& .super-app-theme--ACTIVED': {
                bgcolor: (theme) =>
                  getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                '&:hover': {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.success.main,
                      theme.palette.mode
                    )
                }
              },
              '& .super-app-theme--DISABLED': {
                bgcolor: (theme) =>
                  getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                '&:hover': {
                  bgcolor: (theme) =>
                    getHoverBackgroundColor(
                      theme.palette.error.main, theme.palette.mode
                    )
                }
              }
            }}
          >
            <Card>
              <CardHeader title={'Filtrar'} />
              <CardContent sx={{ pt: 0 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                      control={controlAllCustomers}
                      name="state_abbreviation"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          groupBy={(option) => option?.regiao.nome || ''}
                          multiple
                          options={states}
                          onChange={(_, value) => {
                            field.onChange(
                              value.map((p) => p.sigla ?? p)
                            )
                          }}
                          getOptionLabel={(item) => {
                            return item?.nome
                              ? item.nome
                              : item
                          }}
                          isOptionEqualToValue={(
                            option,
                            value
                          ) => {
                            return (
                              option.nome === value ||
                                  option.nome === value.nome
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Estado'}
                              placeholder={'Filtre por estado'}
                              margin="normal"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      {...registerAllCustomers('county_name')}
                      label={'Cidade'}
                      placeholder={'Filtre por cidade'}
                      margin="normal"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                      control={controlAllCustomers}
                      name="name"
                      render={({ field }) => (
                        <Autocomplete
                          {...customerProps}
                          {...field}
                          multiple
                          onChange={(_, value: any) => {
                            field.onChange(
                              value.map((p) => p.name ?? p)
                            )
                          }}
                          getOptionLabel={(item: any) => {
                            return item.name
                              ? item.name
                              : customerProps?.options?.find(
                                (p) =>
                                  p.id.toString() ===
                              item.toString()
                              )?.name ?? ''
                          }}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return value === undefined || option.id === value.id
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Razão Social'}
                              placeholder={'Filtre por Razão Social'}
                              margin="normal"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      {...registerAllCustomers('cnpj')}
                      label={'CNPJ'}
                      placeholder={'Filtre por CNPJ'}
                      margin="normal"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="right">
                      <Button type="button" variant="contained" onClick={handleSubmitAllCustomers(search)}>
                        {t('customer.filter.submit')}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <DataGrid
              {...allCustomers}
              keepNonExistentRowsSelected
              disableColumnFilter
              columns={columnsAll}
              filterModel={undefined}
              density='compact'
              autoHeight
              pagination
              paginationMode='server'
              checkboxSelection={isClicked}
              disableSelectionOnClick={isClicked}
              getRowClassName={({ row }) => `super-app-theme--${row.status?.toString()}`}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or
                  }
                }
              }}
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                filterPanel: {
                  linkOperators: [GridLinkOperator.And],
                  columnsSort: 'asc',
                  filterFormProps: {
                    linkOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small'
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' }
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' }
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small'
                      }
                    },
                    deleteIconProps: {
                      sx: {
                        '& .MuiSvgIcon-root': { color: '#d32f2f' }
                      }
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-filterForm': { p: 2 },
                    '& .MuiDataGrid-filterForm:nth-child(even)': {
                      backgroundColor: (theme: Theme) =>
                        theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                    },
                    '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                  }
                },
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true }
                }
              }}
              selectionModel={selectionModel}
              isRowSelectable={(params) => !params.row.isInWallet}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids)
              }}
              columnVisibilityModel={columnVisibilityModelAll}
              onColumnVisibilityModelChange={(newModel) => {
                setColumnVisibilityModelAll(newModel)
                handleSaveColumnVisibility({
                  tableId: tableAllId,
                  userId: user?.id ?? '',
                  column_visibility: newModel
                })
              }}
              localeText={DatagridLanguage()}
            />
          </Box>
        </List>
      </Grid>
      <Grid item xs={24} md={8} xl={6}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.info.main}
              qty={attendanceStatus?.wallet?.pending}
              status={'Não Atendeu'}
              text={'Tratativa não iniciada.'}
              handleClick={() => handleFilterAllAttendance('3', handleSubmitAllCustomers(search))}
              isSelected={selectedAllAttendanceStatus === '3'}
              disabled={(attendanceStatus?.wallet?.pending ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.warning.main}
              qty={attendanceStatus?.all?.in_progress}
              status={'Em Progresso'}
              text={'Tratativa em andamento.'}
              handleClick={() => handleFilterAllAttendance('0', handleSubmitAllCustomers(search))}
              isSelected={selectedAllAttendanceStatus === '0'}
              disabled={(attendanceStatus?.all?.in_progress ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.error.main}
              qty={attendanceStatus?.all?.urgent}
              status={'Urgente'}
              text={'Tratativa de ter prioridade para a finalização.'}
              handleClick={() => handleFilterAllAttendance('1', handleSubmitAllCustomers(search))}
              isSelected={selectedAllAttendanceStatus === '1'}
              disabled={(attendanceStatus?.all?.urgent ?? 0) === 0}
            />
          </Grid>
          <Grid item>
            <WidgetStatusAttendances
              color={theme.palette.success.main}
              qty={attendanceStatus?.all?.finished}
              status={'Finalizado'}
              text={'Tratativa foi concluída.'}
              handleClick={() => handleFilterAllAttendance('2', handleSubmitAllCustomers(search))}
              isSelected={selectedAllAttendanceStatus === '2'}
              disabled={(attendanceStatus?.all?.finished ?? 0) === 0}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
