import { Box } from '@mui/material'
import React from 'react'

export const CertificateBody = ({ student, text, course, programs, backgroundImage, qrcode }) => {
  if (backgroundImage === 1) {
    return (
      <>
        <head>
          <style>
            {`@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
          
          @page {
            size: landscape;
            margin: 0;
          }
          
          * {
            margin: 0;
            padding: 0;
          }`}
          </style>
        </head>
        <div
          id="page-one"
          style={{
            width: '29.7cm',
            height: '21cm',
            position: 'relative',
            background: 'url(/fundo_cert2-front.jpg) no-repeat center center fixed',
            backgroundSize: '29.7cm 21cm'
          }}
        >
          <img
            src="/logo.png"
            alt="Logo"
            style={{
              position: 'absolute',
              top: '2cm',
              left: '1cm',
              width: '4.32cm',
              height: '4.11cm'
            }}
          />
          <h1
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              fontSize: '50px',
              textAlign: 'center',
              position: 'absolute',
              top: '35%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              width: '19.152cm',
              height: '2.533cm',
              color: '#000000'
            }}
          >
          CERTIFICADO
          </h1>
          <h2
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 500,
              fontSize: '22px',
              textAlign: 'center',
              position: 'absolute',
              top: '38%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F'
            }}
          >
            OUTORGADO A
          </h2>
          <h3
            style={{
              fontFamily: 'Edwardian Script ITC, cursive',
              color: '#7F7F7F',
              fontWeight: 700,
              fontSize: '30pt',
              textAlign: 'center',
              position: 'absolute',
              top: '56%',
              left: '36%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {student}
          </h3>
          <p
            style={{
              fontFamily: 'Montserrat Medium, sans-serif',
              fontWeight: 600,
              fontSize: '14pt',
              textAlign: 'center',
              position: 'absolute',
              top: '64%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F'
            }}
          >
            {course}
          </p>
          <p
            style={{
              fontFamily: 'Montserrat Medium, sans-serif',
              fontWeight: 600,
              fontSize: '14pt',
              textAlign: 'center',
              position: 'absolute',
              top: '68%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F',
              paddingTop: '60px'
            }}
          >
            Brasília, {new Date().toLocaleDateString('pt-BR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </p>
          <img
            src="/assinatura_isabel.png"
            alt="Assinatura Isabel"
            style={{
              position: 'absolute',
              bottom: '15%',
              right: '16%',
              width: '3.49cm',
              height: '2.42cm'
            }}
          />
          <p
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 500,
              fontSize: '14px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '15.2%',
              right: '15%',
              color: '#7F7F7F'
            }}
          >
        Isabel Cristina Pereira Dantas De Almeida
          </p>
          <p
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              fontSize: '15px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '13%',
              right: '15%',
              color: '#7F7F7F'
            }}
          >
        Bra Consultoria
          </p>
        </div>
        <div
          id="page-two"
          style={{
            width: '29.7cm',
            height: '21cm',
            position: 'relative',
            background: 'url(/fundo_cert2-back.jpg) no-repeat center center fixed',
            backgroundSize: '29.7cm 21cm',
            padding: '1.5cm 1cm'
          }}
        >
          <h2
            style={{
              fontFamily: 'Questrial, sans-serif',
              fontSize: '20px',
              position: 'absolute',
              top: '60px',
              left: '100px',
              color: '#000000'
            }}
          >
            PROGRAMAÇÃO
          </h2>
          <p
            style={{
              fontFamily: 'Questrial, sans-serif',
              fontSize: '15px',
              position: 'absolute',
              top: 'calc(30px + 16.5px + 45px)',
              left: '100px',
              width: '12.331cm',
              textAlign: 'justify',
              textJustify: 'inter-word',
              color: '#000000'
            }}
          >
            {text}
          </p>
          <img
            src={qrcode}
            alt="QRCode Validador"
            style={{
              position: 'absolute',
              top: '7%',
              right: '6%',
              width: '3cm',
              height: '3cm'
            }}
          />
          <p
            style={{
              fontFamily: 'Calibri, sans-serif',
              fontSize: '12px',
              position: 'absolute',
              top: '20%',
              right: '4.5%',
              width: '4cm',
              textAlign: 'center',
              textJustify: 'inter-word',
              color: '#7F7F7F'
            }}
          >
          Valide a autenticidade do certificado escaneando o QRCode
          </p>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              height: '70%', // ajuste conforme necessário
              position: 'absolute',
              top: '27%', // Ajuste conforme necessário
              left: '90px',
              width: '100%',
              padding: '0 2%',
              boxSizing: 'border-box'
            }}
          >
            {programs.map((program, index) => {
              const top = `${Math.floor(index / 2) * 28}%`
              const left = index % 2 === 0 ? '0' : '50%'

              return (
                <div
                  id={`program${index}`}
                  key={index}
                  style={{
                    position: 'absolute',
                    top,
                    left,
                    width: '37%', // ajuste conforme necessário
                    height: '35%', // ajuste conforme necessário
                    boxSizing: 'border-box',
                    padding: '1%',
                    overflow: 'auto'
                  }}
                >
                  <h3
                    style={{
                      fontFamily: 'Questrial, sans-serif',
                      fontSize: '16.5px',
                      textAlign: 'left',
                      color: '#7F7F7F'
                    }}
                  >
                    {`Módulo ${index + 1} - ${program.name}`}
                  </h3>
                  <p
                    style={{
                      fontFamily: 'Calibri, sans-serif',
                      fontSize: '12px',
                      color: '#7F7F7F',
                      wordWrap: 'break-word',
                      overflow: 'hidden'
                    }}
                  >
                    {program.description}
                  </p>
                  {[program.line1, program.line2, program.line3, program.line4, program.line5, program.line6]
                    .filter(line => line)
                    .map((line, lineIndex) => (
                      <p
                        key={lineIndex}
                        style={{
                          fontFamily: 'Questrial, sans-serif',
                          fontSize: '11px',
                          color: '#7F7F7F',
                          wordWrap: 'break-word',
                          overflow: 'visible'
                        }}
                      >
                        {line}
                      </p>
                    ))}
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <head>
          <style>
            {`@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
          
          @page {
            size: landscape;
            margin: 0;
          }
          
          * {
            margin: 0;
            padding: 0;
          }`}
          </style>
        </head>
        <div
          id="page-one"
          style={{
            width: '29.7cm',
            height: '21cm',
            position: 'relative',
            background: 'url(/fundo_cert2-front.jpg) no-repeat center center fixed',
            backgroundSize: '29.7cm 21cm'
          }}
        >
          <img
            src="/logo.png"
            alt="Logo"
            style={{
              position: 'absolute',
              top: '2cm',
              left: '1cm',
              width: '4.32cm',
              height: '4.11cm'
            }}
          />
          <h1
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              fontSize: '50px',
              textAlign: 'center',
              position: 'absolute',
              top: '35%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              width: '19.152cm',
              height: '2.533cm',
              color: '#000000'
            }}
          >
          CERTIFICADO
          </h1>
          <h2
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 500,
              fontSize: '22px',
              textAlign: 'center',
              position: 'absolute',
              top: '38%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F'
            }}
          >
            OUTORGADO A
          </h2>
          <h3
            style={{
              fontFamily: 'Edwardian Script ITC, cursive',
              color: '#7F7F7F',
              fontWeight: 700,
              fontSize: '30pt',
              textAlign: 'center',
              position: 'absolute',
              top: '56%',
              left: '36%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {student}
          </h3>
          <p
            style={{
              fontFamily: 'Montserrat Medium, sans-serif',
              fontWeight: 600,
              fontSize: '14pt',
              textAlign: 'center',
              position: 'absolute',
              top: '64%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F'
            }}
          >
            {course}
          </p>
          <p
            style={{
              fontFamily: 'Montserrat Medium, sans-serif',
              fontWeight: 600,
              fontSize: '14pt',
              textAlign: 'center',
              position: 'absolute',
              top: '72%',
              left: '36%',
              transform: 'translate(-50%, -50%)',
              color: '#7F7F7F'
            }}
          >
            Brasília, {new Date().toLocaleDateString('pt-BR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </p>
          <img
            src="/assinatura_isabel.png"
            alt="Assinatura Isabel"
            style={{
              position: 'absolute',
              bottom: '15%',
              right: '16%',
              width: '3.49cm',
              height: '2.42cm'
            }}
          />
          <p
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 500,
              fontSize: '14px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '15.2%',
              right: '15%',
              color: '#7F7F7F'
            }}
          >
        Isabel Cristina Pereira Dantas De Almeida
          </p>
          <p
            style={{
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'bold',
              fontSize: '15px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '13%',
              right: '15%',
              color: '#7F7F7F'
            }}
          >
        Bra Consultoria
          </p>
        </div>
        <div
          id="page-two"
          style={{
            width: '29.7cm',
            height: '21cm',
            position: 'relative',
            background: 'url(/fundo_cert2-back.jpg) no-repeat center center fixed',
            backgroundSize: '29.7cm 21cm'
          }}
        >
          <h2
            style={{
              fontFamily: 'Questrial, sans-serif',
              fontSize: '20px',
              position: 'absolute',
              top: '60px',
              left: '220px',
              color: '#000000'
            }}
          >
            PROGRAMAÇÃO
          </h2>
          <p
            style={{
              fontFamily: 'Questrial, sans-serif',
              fontSize: '15px',
              position: 'absolute',
              top: 'calc(30px + 16.5px + 45px)',
              left: '100px',
              width: '12.331cm',
              textAlign: 'justify',
              textJustify: 'inter-word',
              color: '#000000'
            }}
          >
            {text}
          </p>
          <img
            src={qrcode}
            alt="QRCode Validador"
            style={{
              position: 'absolute',
              top: '7%',
              right: '6%',
              width: '3cm',
              height: '3cm'
            }}
          />
          <p
            style={{
              fontFamily: 'Calibri, sans-serif',
              fontSize: '12px',
              position: 'absolute',
              top: '20%',
              right: '4.5%',
              width: '4cm',
              textAlign: 'center',
              textJustify: 'inter-word',
              color: '#7F7F7F'
            }}
          >
            Valide a autenticidade do certificado escaneando o QRCode
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))',
                gap: '1rem',
                paddingLeft: '100px',
                paddingRight: '100px',
                width: '100%'
              }}
            >
              {programs.map((program, index) => (
                <Box id={`program${index}`} key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3
                    style={{
                      fontFamily: 'Questrial, sans-serif',
                      fontSize: '1rem',
                      textAlign: 'left',
                      color: '#000000'
                    }}
                  >
                    {`Módulo ${index + 1} - ${program.name}`}
                  </h3>
                  <p
                    style={{
                      fontFamily: 'Calibri, sans-serif',
                      fontSize: '1rem',
                      marginBottom: '2rem',
                      width: '100%',
                      color: '#7F7F7F'
                    }}
                  >
                    {program.description}
                  </p>
                  <div style={{ flex: 1 }}>
                    {[program.line1, program.line2, program.line3, program.line4, program.line5, program.line6]
                      .filter((line) => line)
                      .map((line, lineIndex) => (
                        <p
                          key={lineIndex}
                          style={{
                            fontFamily: 'Questrial, sans-serif',
                            fontSize: '1.13rem',
                            width: '100%',
                            color: '#7F7F7F'
                          }}
                        >
                          {line}
                        </p>
                      ))}
                  </div>
                </Box>
              ))}
            </div>
          </div>
        </div>
      </>

    )
  }
}

export default CertificateBody
