import { Authenticated } from '@refinedev/core'
import { ErrorComponent } from '@refinedev/mui'
import { CatchAllNavigate, NavigateToResource } from '@refinedev/react-router-v6'
import { Header, Layout, OffLayoutArea, Title } from 'components/layout'
import { LoginPage } from 'components/login'
import { CommercialActivitiesList } from 'pages/activities/list'
import { BankSlipCreate, BankSlipShow, BankSlipsList } from 'pages/bank-slips'
import { CampaignCreate, CampaignEdit, CampaignList, CampaignShow } from 'pages/campaigns'
import { CustomerCreate, CustomerList, CustomerShow } from 'pages/customers-commercial'
import { SimecCreate, SimecList, SimecShow } from 'pages/customers-simec'
import { DashboardPage } from 'pages/dashboard'
import { TrainingList, TrainingCreate, TrainingShow, TrainingEdit } from 'pages/training'
import { UserCreate, UserEdit, UserList, UserShow } from 'pages/users'
import { Outlet, Route, Routes } from 'react-router-dom'

const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <Authenticated fallback={<Outlet />}>
            <NavigateToResource resource="dashboard" />
          </Authenticated>
        }
      >
        <Route
          path="/login"
          element={
            <LoginPage />
          }
        />
      </Route>

      <Route
        element={
          <Authenticated
            fallback={
              <CatchAllNavigate to="/login" />
            }
          >
            <Layout
              Header={Header}
              Title={Title}
              OffLayoutArea={OffLayoutArea}
            >
              <Outlet />
            </Layout>
          </Authenticated>
        }
      >
        <Route index element={<DashboardPage />} />

        <Route path="/boletos">
          <Route
            index
            element={<BankSlipsList />}
          />
          <Route
            path="criar"
            element={<BankSlipCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<BankSlipShow />}
          />
        </Route>

        <Route path="/usuarios">
          <Route
            index
            element={<UserList />}
          />
          <Route
            path="criar"
            element={<UserCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<UserShow />}
          />
          <Route
            path="editar/:id"
            element={<UserEdit />}
          />
        </Route>

        <Route path="/prefeituras">
          <Route
            index
            element={<SimecList />}
          />
          <Route
            path="criar"
            element={<SimecCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<SimecShow />}
          />
        </Route>

        <Route path="/clientes">
          <Route
            index
            element={<CustomerList />}
          />
          <Route
            path="criar"
            element={<CustomerCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<CustomerShow />}
          />
          <Route
            path="resumo-de-atividades"
            element={<CommercialActivitiesList />}
          />
        </Route>

        <Route path="/campanhas">
          <Route
            index
            element={<CampaignList />}
          />
          <Route
            path="criar"
            element={<CampaignCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<CampaignShow />}
          />
          <Route
            path="editar/:id"
            element={<CampaignEdit />}
          />
        </Route>

        <Route path="/formacoes">
          <Route
            index
            element={<TrainingList />}
          />
          <Route
            path="criar"
            element={<TrainingCreate />}
          />
          <Route
            path="visualizar/:id"
            element={<TrainingShow />}
          />
          <Route
            path="editar/:id"
            element={<TrainingEdit />}
          />
        </Route>
      </Route>

      <Route
        element={
          <Authenticated>
            <Layout
              Header={Header}
              Title={Title}
              OffLayoutArea={OffLayoutArea}
            >
              <Outlet />
            </Layout>
          </Authenticated>
        }
      >
        <Route
          path="*"
          element={<ErrorComponent />}
        />
      </Route>
    </Routes>
  )
}
export default Router
