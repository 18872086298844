import React, { ReactNode } from 'react'
import { useForm } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import {
  Edit,
  RefreshButton
} from '@refinedev/mui'
import {
  Box,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  InputAdornment,
  Stack,
  Button
} from '@mui/material'
import { EnumTypeTraining } from 'enums'
import { Save } from '@mui/icons-material'
import { useParsed } from '@refinedev/core'

export function TrainingEdit () {
  const { id: showId } = useParsed()
  const meta = {
    fields: [
      'id',
      'name',
      'description',
      'training_init',
      'training_end',
      'type'
    ]
  }

  const {
    refineCore: { formLoading },
    saveButtonProps,
    refineCore: { onFinish },
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      invalidates: ['all'],
      meta
    }
  })

  const handleSubmitEdit = (values: FieldValues) => {
    const { type, ...rest } = values
    onFinish({
      ...rest,
      type: typeof type === 'string' ? Object.keys(EnumTypeTraining).indexOf(type as unknown as EnumTypeTraining) : type
    })
  }

  return (
    <Edit
      footerButtons={<></>}
      headerProps={{
        title: <Typography variant="h5">Editar Formação</Typography>,
        action: <RefreshButton meta={meta} recordItemId={showId} />
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('name', { required: 'O Nome é obrigatório!', minLength: 5, maxLength: 50 })}
                defaultValue={' '}
                error={!!errors?.name}
                helperText={errors.name?.message as ReactNode}
                label="Nome"
                name="name"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                control={control}
                name="type"
                rules={{ required: 'Tipo é obrigatório!' }}
                defaultValue={null as any}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    {...field}
                    options={Object.values(EnumTypeTraining)}
                    onChange={(_, value) => {
                      field.onChange(Object.values(EnumTypeTraining).indexOf(value as unknown as EnumTypeTraining))
                    }}
                    getOptionLabel={(value) => {
                      let option = ''
                      option = (typeof value === 'number')
                        ? Object.values(EnumTypeTraining)[value]
                        : EnumTypeTraining[value]

                      if (!option) {
                        const indexOfS = Object.values(EnumTypeTraining).indexOf(value as unknown as EnumTypeTraining)
                        const key = Object.keys(EnumTypeTraining)[indexOfS]
                        option = EnumTypeTraining[key]
                      }
                      return option
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (typeof value === 'number') return option.toString() === Object.values(EnumTypeTraining)[value]

                      return value === undefined ||
                    option.toString() === EnumTypeTraining[value]
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.type}
                        helperText={errors.type?.message as ReactNode}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('training_init', {
                  valueAsDate: true
                })}
                defaultValue={' '}
                error={!!errors?.training_init}
                helperText={errors.training_init?.message as ReactNode}
                label="Data de Inicío"
                name="training_init"
                margin="normal"
                type="date"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('training_end', {
                  valueAsDate: true
                })}
                defaultValue={' '}
                error={!!errors?.training_end}
                helperText={errors.training_end?.message as ReactNode}
                label="Data de Término"
                name="training_end"
                margin="normal"
                type="date"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...register('text', { required: 'Texto da primeira página é obrigatório!', minLength: 5, maxLength: 500 })}
                error={!!errors?.text}
                helperText={errors.text?.message as ReactNode}
                label="Texto da primeira página"
                name="text"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...register('description', { maxLength: 500 })}
                defaultValue={' '}
                error={!!errors?.description}
                helperText={errors.description?.message as ReactNode}
                label="Texto da segunda página"
                name="description"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
            <Button
              type="submit"
              variant="contained"
              startIcon={
                <Save />
              }>
                  Editar
            </Button>
          </Stack>
        </form>
      </Box>
    </Edit>
  )
}
