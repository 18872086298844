import React from 'react'
import { Refine } from '@refinedev/core'
import { notificationProvider, RefineSnackbarProvider } from '@refinedev/mui'
import { CssBaseline, GlobalStyles } from '@mui/material'
import routerProvider, { UnsavedChangesNotifier } from '@refinedev/react-router-v6'
import { useTranslation } from 'react-i18next'
import { ColorModeContextProvider } from 'contexts'
import { authProvider } from './services/auth-provider'
import { People, Domain, Campaign, AccountBalanceOutlined, Description, Book, CardTravel, ManageAccounts, Dashboard, Assignment } from '@mui/icons-material'
import { dataProvider } from 'services/data-provider'
import { client } from 'config/client'
import { Can } from 'security/can'
import { EnumUserProfile } from 'enums'
import { KBarProvider } from '@refinedev/kbar'
import { BrowserRouter } from 'react-router-dom'
import ModalImage from 'components/modal'
import { ApolloProvider } from '@apollo/client'
import apolloClient from 'services/apollo-client'
import Router from 'routes'

function App () {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  }

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <KBarProvider>
          <ColorModeContextProvider>
            <ModalImage />
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
            <RefineSnackbarProvider>
              <Refine
                authProvider={authProvider}
                routerProvider={routerProvider}
                dataProvider={dataProvider(client)}
                notificationProvider={notificationProvider}
                i18nProvider={i18nProvider}
                options={{
                  mutationMode: 'undoable',
                  undoableTimeout: 3000,
                  syncWithLocation: true
                }}
                accessControlProvider={{
                  can: async ({ action, resource, params }) => {
                    const profile = localStorage.getItem('profile')
                    const can = Can(action, EnumUserProfile[profile ?? 'USER'], resource, params)
                    return Promise.resolve({ can })
                  }
                }}
                resources={[
                  {
                    name: 'dashboard',
                    list: '/',
                    meta: {
                      label: 'Dashboard',
                      icon: <Dashboard />
                    }
                  },
                  {
                    name: 'finance',
                    meta: {
                      label: 'Financeiro',
                      icon: <AccountBalanceOutlined />
                    }
                  },
                  {
                    name: 'commercial',
                    meta: {
                      label: 'Comercial',
                      icon: <CardTravel />
                    }
                  },
                  {
                    name: 'bankSlip',
                    list: '/boletos',
                    create: '/boletos/criar',
                    show: '/boletos/visualizar/:id',
                    meta: {
                      label: 'Boletos',
                      parent: 'finance',
                      icon: <Description />
                    }
                  },
                  {
                    name: 'user',
                    list: '/usuarios',
                    create: '/usuarios/criar',
                    show: '/usuarios/visualizar/:id',
                    edit: '/usuarios/editar/:id',
                    meta: {
                      label: 'Usuarios',
                      icon: <People />
                    }
                  },
                  {
                    name: 'simecCustomers',
                    list: '/prefeituras',
                    create: '/prefeituras/criar',
                    show: '/prefeituras/visualizar/:id',
                    meta: {
                      label: 'Prefeituras',
                      icon: <Domain />
                    }
                  },
                  {
                    name: 'clientes',
                    list: '/clientes',
                    create: '/clientes/criar',
                    show: '/clientes/visualizar/:id',
                    meta: {
                      label: 'Clientes',
                      parent: 'commercial',
                      icon: <ManageAccounts />
                    }
                  },
                  {
                    name: 'activities',
                    list: '/clientes/resumo-de-atividades',
                    meta: {
                      label: 'Resumo de Atividades',
                      parent: 'commercial',
                      icon: <Assignment />
                    }
                  },
                  {
                    name: 'campaign',
                    list: '/campanhas',
                    create: '/campanhas/criar',
                    show: '/campanhas/visualizar/:id',
                    edit: '/campanhas/editar/:id',
                    meta: {
                      label: 'Campanhas',
                      icon: <Campaign />
                    }
                  },
                  {
                    name: 'training',
                    list: '/formacoes',
                    create: '/formacoes/criar',
                    show: '/formacoes/visualizar/:id',
                    edit: '/formacoes/editar/:id',
                    meta: {
                      label: 'Formações',
                      icon: <Book />
                    }
                  }
                ]}
              >
                <Router />
                <UnsavedChangesNotifier />
              </Refine>
            </RefineSnackbarProvider>
          </ColorModeContextProvider>
        </KBarProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
