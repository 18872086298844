import { axiosInstance } from 'config/axios'

interface UserTableSettings<T> {
  userId: string;
  tableId: string;
  column_visibility: {
    [P in keyof T]?: boolean;
  };
}

export async function handleSaveColumnVisibility (values: UserTableSettings<any>) {
  const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
  const response = await axiosInstance.post<{ url: string, key: string }>(
    'datagrid/column-visibility',
    values,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

  const columnData = localStorage.getItem('columns')
  const parsedData = columnData ? JSON.parse(columnData) : []
  const existingTableIndex = parsedData.findIndex(item => item.tableId === values.tableId)

  if (existingTableIndex !== -1) {
    parsedData[existingTableIndex].column_visibility = values.column_visibility
  } else {
    parsedData.push({
      tableId: values.tableId,
      column_visibility: values.column_visibility
    })
  }

  localStorage.setItem('columns', JSON.stringify(parsedData))

  return response
}

export async function getColumnVisibility (token: string) {
  const { data } = await axiosInstance.get('datagrid/column-visibility', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}
