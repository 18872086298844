import React, { useMemo } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { HttpError, useInvalidate } from '@refinedev/core'
import { useDataGrid, List, ShowButton, DateField, RefreshButton } from '@refinedev/mui'
import { ICustomersUpdateHistory } from 'interfaces'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { Circle } from '@mui/icons-material'
import AttendanceCalendar from 'components/widgets/attendance-calendar'
import CaptchaBalance from 'components/reports/balance'

export function DashboardPage () {
  const invalidate = useInvalidate()
  const { dataGridProps } = useDataGrid<ICustomersUpdateHistory,
  HttpError
  >({
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    resource: 'customersUpdateHistory',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'sigpc_programs_date',
            'sigpc_date',
            'pnae_date',
            'sigef_date',
            'cauc_date',
            'pdde_date',
            'simec_date',
            'cae_date',
            'createdAt',
            {
              createdBy: ['id, name']
            },
            {
              customer: [
                'id',
                'name'
              ]
            }
          ]
        }
      ]
    }
  })

  const columns = useMemo<GridColumns<ICustomersUpdateHistory>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton resource='simecCustomers' hideText recordItemId={row.customer.id} />
            </Stack>
          )
        }
      },
      {
        field: 'customer.name',
        headerName: 'Prefeitura',
        flex: 2,
        minWidth: 300,
        renderCell: function render ({ row }) {
          return row.customer.name
        }
      },
      {
        field: 'sigpc_date',
        headerName: 'SIGPC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigpc_date ? <DateField format='HH:mm D/M/YY' value={row.sigpc_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'pnae_date',
        headerName: 'PNAE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.pnae_date ? <DateField format='HH:mm D/M/YY' value={row.pnae_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'sigef_date',
        headerName: 'SIGEF',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigef_date ? <DateField format='HH:mm D/M/YY' value={row.sigef_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'cauc_date',
        headerName: 'CAUC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.cauc_date ? <DateField format='HH:mm D/M/YY' value={row.cauc_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'simec_date',
        headerName: 'SIMEC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.simec_date ? <DateField format='HH:mm D/M/YY' value={row.simec_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'sigpc_programs_date',
        headerName: 'SIGPC PROG',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigpc_programs_date ? <DateField format='HH:mm D/M/YY' value={row.sigpc_programs_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'cae_date',
        headerName: 'CAE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.cae_date ? <DateField format='HH:mm D/M/YY' value={row.cae_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'pdde_date',
        headerName: 'PDDE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.pdde_date ? <DateField format='HH:mm D/M/YY' value={row.pdde_date} /> : <Circle color='error' />
        }
      }
    ],
    []
  )

  const handleInvalidate = () => {
    invalidate({
      resource: 'customersUpdateHistory',
      invalidates: ['all']
    })
  }

  return (
    <Grid container columns={24} spacing={2}>
      <CaptchaBalance />
      <Grid item xs={24}>
        <List
          breadcrumb={null}
          headerProps={{
            title: (<>
              <Typography variant="h5">Histórico de Atualização</Typography>
              <Typography variant="subtitle1">Visão Geral das últimas atualizações.</Typography>
            </>),
            action: <RefreshButton onClick={handleInvalidate} />
          }}>
          <DataGrid
            {...dataGridProps}
            columns={columns}
            disableColumnFilter
            autoHeight
            pagination
            paginationMode='server'
            density='compact'
            localeText={DatagridLanguage()}
          />
        </List>
      </Grid>
      <Grid item xs={24}>
        <AttendanceCalendar />
      </Grid>
    </Grid>

  )
}
