import React, { useContext } from 'react'
import { useGetIdentity, useGetLocale, useGo, useSetLocale } from '@refinedev/core'
import {
  AppBar,
  IconButton,
  Avatar,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  Grid,
  Button
} from '@mui/material'
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material'

import { ColorModeContext } from 'contexts'
import i18n from 'i18n'

export const Header: React.FC = () => {
  const { mode, setMode } = useContext(ColorModeContext)

  const changeLanguage = useSetLocale()
  const locale = useGetLocale()
  const currentLocale = locale()
  const go = useGo()

  const { data: user } = useGetIdentity<{
    name: string;
    id: string;
  }>()
  const showUserInfo = user && (user?.name)

  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <Grid container>
          <Grid item xs={6}>
            <Stack
              direction="row"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
            >
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                onClick={() => {
                  setMode()
                }}
              >
                {mode === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
              </IconButton>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  disableUnderline
                  defaultValue={currentLocale}
                  inputProps={{ 'aria-label': 'Without label' }}
                  variant="standard"
                >
                  {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                    <MenuItem
                      selected={currentLocale === lang}
                      key={lang}
                      defaultValue={lang}
                      onClick={() => {
                        changeLanguage(lang)
                      }}
                      value={lang}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          sx={{
                            width: '16px',
                            height: '16px',
                            marginRight: '5px'
                          }}
                          src={`/images/flags/${lang}.png`}
                        />
                        {lang === 'en' ? 'English' : 'Português'}
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {showUserInfo && (
                <Stack direction="row" gap="16px" alignItems="center">
                  {user.name && (
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={() => go({
                        to: `usuarios/visualizar/${user?.id}`
                      })}
                    ><Typography variant="subtitle2">{user?.name}</Typography></Button>
                  )}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
