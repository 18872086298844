import { TOKEN_KEY } from 'config/client'
export class UploadAdapter {
  public loader: any
  public url: string
  public xhr!: XMLHttpRequest
  public token: string

  constructor (loader: any) {
    this.loader = loader
    this.url = process.env.NODE_ENV !== 'development' ? '/v1/api/file/upload' : 'http://localhost:3000/v1/api/file/upload'
    this.token = (TOKEN_KEY || localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)) ?? ''
  }

  upload () {
    return new Promise((resolve, reject) => {
      this.loader.file.then((file) => {
        this._initRequest()
        this._initListeners(resolve, reject, file)
        this._sendRequest(file)
      })
    })
  }

  abort () {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  _initRequest () {
    const xhr = (this.xhr = new XMLHttpRequest())
    xhr.open('POST', this.url, true)
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`)
    xhr.responseType = 'json'
  }

  _initListeners (resolve, reject, file) {
    const xhr = this.xhr
    const loader = this.loader
    const genericErrorText = "Couldn't upload file:" + ` ${file.name}.`
    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        )
      }

      resolve({
        default: response.url
      })
    })
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  _sendRequest (file) {
    const data = new FormData()
    data.append('upload', file)
    this.xhr.send(data)
  }
}
export default function USystemUploadAdapterPlugin (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader)
  }
}
