import { Box, Stack, Typography } from '@mui/material'
import { BarChart, Bar, Tooltip, ResponsiveContainer, XAxis } from 'recharts'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

import { ChartTooltip } from '../chart-tool'

export const DailyAttendances = ({ data }) => {
  const averageDailyCount = data?.reduce((sum, { count }) => sum + count, 0) / data?.length ?? 0
  const lastDayCount = data?.length > 0 ? data[data?.length - 1].count : 0
  const lastDayPercentage = !Number.isNaN(Math.floor((averageDailyCount / lastDayCount) * 100)) ? Math.floor((averageDailyCount / lastDayCount) * 100) : 0

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: '230px',
        p: 1,
        background: 'url(/images/icons/chat.png)',
        backgroundColor: '#11111190',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        backgroundSize: '54px'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h5"
          sx={{ color: '#fff', fontWeight: 700, mb: 0 }}
        >
          Seus Atendimentos
        </Typography>

        <Stack direction="row" alignItems="center">
          <Typography
            sx={{ fontWeight: 600, fontSize: 20, color: '#fff' }}
          >
            Média Hoje x Semana {lastDayPercentage}%
          </Typography>
          {lastDayPercentage > 0
            ? (
              <ArrowDropUp fontSize="large" color="success" />
            )
            : (
              <ArrowDropDown fontSize="large" color="error" />
            )}
        </Stack>
      </Stack>
      <Box sx={{ height: '130px' }}>
        <ResponsiveContainer width="99%">
          <BarChart data={(data ?? []).reverse()} barSize={15}>
            <XAxis colorProfile={'#ffffff'} color='#ffffff' fill='#ffffff' dataKey="day" />
            <Bar
              type="natural"
              dataKey="count"
              fill='rgba(218, 165, 32, 0.5)'
              radius={[4, 4, 0, 0]}
            />
            <Tooltip
              cursor={{
                fill: 'rgba(218, 165, 32, 0.2)',
                radius: 4
              }}
              content={<ChartTooltip />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Stack>
  )
}
