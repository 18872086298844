import { IFileServer } from 'interfaces'
import React, { useState, createContext, ReactNode, Dispatch, SetStateAction } from 'react'

type ModalContextProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  file: IFileServer | null
  setFile: Dispatch<SetStateAction<IFileServer | null>>
}

type ProviderProps = {
  children: ReactNode | ReactNode[]
}

export const ModalContext = createContext<ModalContextProps | null>(null)
export function ModalProvider ({ children }: ProviderProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [file, setFile] = useState<IFileServer | null>(null)

  return (
    <ModalContext.Provider value={{ open, setOpen, file, setFile }}>
      {children}
    </ModalContext.Provider>
  )
}
