import {
  createMongoAbility,
  AbilityBuilder,
  InferSubjects,
  MongoAbility,
  ExtractSubjectType
} from '@casl/ability'
import { EnumAction, EnumUserProfile } from 'enums'

type User = {
  name: string;
  access: string;
  email: string;
  cpf: string;
  profile: EnumUserProfile;
};
type Subjects = InferSubjects<User> | 'all';

export type AppAbility = MongoAbility<[EnumAction, Subjects]>;

const ability = new AbilityBuilder<AppAbility>(createMongoAbility)

export const defineRulesForDefault = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, cannot, build } = ability

  if (profile === EnumUserProfile.ADMIN) {
    can(EnumAction.Manage, 'all')
  } else {
    cannot(EnumAction.Delete, 'all')
    cannot(EnumAction.Update, 'all')
    cannot(EnumAction.Create, 'all')
  }

  can(EnumAction.Read, 'all')
  can(EnumAction.Show, 'all')

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForFinance = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, cannot, build } = ability

  const authorizedProfiles = [EnumUserProfile.ADMIN, EnumUserProfile.FINANCIAL]

  if (authorizedProfiles.includes(profile)) {
    can(EnumAction.Manage, 'all')
  } else {
    cannot(EnumAction.Manage, 'all')
  }

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForCommercial = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, cannot, build } = ability

  const authorizedProfiles = [
    EnumUserProfile.ADMIN,
    EnumUserProfile.FINANCIAL,
    EnumUserProfile.CONSULTANT,
    EnumUserProfile.CLERK
  ]

  if (authorizedProfiles.includes(profile)) {
    can(EnumAction.Manage, 'all')
  } else {
    cannot(EnumAction.Manage, 'all')
  }

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForAdmin = (profile: EnumUserProfile, params: any) => {
  const { can, cannot, build } = ability

  const authorizedProfiles = [EnumUserProfile.ADMIN]

  if (authorizedProfiles.includes(profile)) {
    can(EnumAction.Manage, 'all')
  } else {
    cannot(EnumAction.Manage, 'all')
  }

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForPassword = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, cannot, build } = ability
  const authorizedProfiles = [EnumUserProfile.ADMIN]

  if (authorizedProfiles.includes(profile)) {
    can(EnumAction.Manage, 'all')
  } else {
    if (params?.myId && params?.userId && params.myId === params.userId) {
      can(EnumAction.Manage, 'all', { id: params.myId })
    } else {
      cannot(EnumAction.Manage, 'all')
    }
  }

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForAttendance = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, cannot, build } = ability
  const authorizedProfiles = [EnumUserProfile.ADMIN]

  if (authorizedProfiles.includes(profile)) {
    can(EnumAction.Manage, 'all')
  } else {
    if (params?.myId && params?.userId && params.myId === params.userId) {
      can(EnumAction.Manage, 'all', { id: params.myId })
    } else {
      cannot(EnumAction.Manage, 'all')
    }
  }

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

export const defineRulesForMessageCustomer = (
  profile: EnumUserProfile,
  params: any
) => {
  const { can, build } = ability
  can(EnumAction.Manage, 'all')

  return build({
    detectSubjectType: (object) =>
      object.constructor as unknown as ExtractSubjectType<Subjects>
  })
}

const rules = {
  bankslip: defineRulesForFinance,
  clientes: defineRulesForCommercial,
  customersWallet: defineRulesForCommercial,
  activities: defineRulesForAdmin,
  passwords: defineRulesForPassword,
  attendances: defineRulesForAttendance,
  messageCustomer: defineRulesForMessageCustomer
}

export const Can = (action: any, subject: any, resource: any, params: any) => {
  let rule = rules[resource]
  if (!rule) rule = defineRulesForDefault

  const abilities = rule(subject, params)

  return abilities.can(action, subject)
}
