import { axiosInstance } from 'config/axios'

export const generatePDF = async (id: string, customerName: string) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)

  const { data } = await axiosInstance.get(`bank-slip/pdf/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (data.pdf) {
    const linkSource = `data:application/pdf;base64,${data.pdf}`
    const downloadLink = document.createElement('a')
    const fileName = `boleto${customerName.trim()}.pdf`
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
}
