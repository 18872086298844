import { useEffect, useMemo, useState } from 'react'
import {
  List,
  DeleteButton,
  ShowButton,
  EditButton,
  useDataGrid,
  DateField
} from '@refinedev/mui'
import { Theme } from '@mui/material/styles'
import { Button, Stack, Typography } from '@mui/material'
import {
  GridColumns,
  DataGrid,
  GridToolbar,
  GridLinkOperator,
  GridColumnVisibilityModel
} from '@mui/x-data-grid'
import { IDefaultFilters, IUser } from 'interfaces'
import { CanAccess, HttpError, useGetIdentity } from '@refinedev/core'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { EnumUserProfile } from 'enums'
import { Key } from '@mui/icons-material'
import { ChangePassword } from 'components/passwords'
import { useModalForm } from '@refinedev/react-hook-form'
import { handleSaveColumnVisibility } from 'components/datagrid/column-visibility'

export function UserList () {
  const tableId = 'user'
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      actions: true,
      name: true,
      access: true,
      profile: true,
      phone: true,
      email: true,
      createdAt: true,
      updatedAt: false,
      deletedAt: false
    })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find((item) => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<IUser>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 3,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} />
              <EditButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
              <CanAccess resource="passwords" action="update" params={{ userId: row.id, myId: user?.id }}>
                <Button
                  color="warning"
                  startIcon={<Key />}
                  onClick={() => showChangePasswordModal(row.id)}
                />
              </CanAccess>
            </Stack>
          )
        }
      },
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'access',
        headerName: 'Login',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'profile',
        headerName: 'Perfil',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return EnumUserProfile[row.profile]
        }
      },
      {
        field: 'phone',
        headerName: 'Telefone',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.phone
        }
      },
      {
        field: 'email',
        headerName: 'E-mail',
        flex: 1,
        minWidth: 250
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format="HH:mm D/M/YY" value={row.createdAt} />
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt
            ? (
              <DateField format="HH:mm D/M/YY" value={row.updatedAt} />
            )
            : (
              ''
            )
        }
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.deletedAt
            ? (
              <DateField format="HH:mm D/M/YY" value={row.deletedAt} />
            )
            : (
              ''
            )
        }
      }
    ],
    []
  )

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const changePasswordModal = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'password',
      metaData: {
        operation: 'user',
        fields: ['id']
      }
    }
  })

  const {
    modal: { show: showChangePasswordModal }
  } = changePasswordModal

  const { dataGridProps } = useDataGrid<IUser, HttpError, IDefaultFilters>({
    meta: {
      operation: 'users',
      fields: [
        'total',
        {
          nodes: [
            'id',
            'name',
            'access',
            'email',
            'profile',
            'phone',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    },
    pagination: {
      current: 0,
      pageSize: 25
    },
    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  return (
    <>
      <ChangePassword {...changePasswordModal} />
      <List
        headerProps={{
          title: <Typography variant="h5">Lista de Usuarios</Typography>
        }}
      >
        <DataGrid
          {...dataGridProps}
          disableColumnFilter
          columns={columns}
          filterModel={undefined}
          autoHeight
          pagination
          paginationMode="server"
          sortingMode="server"
          density="compact"
          components={{
            Toolbar: GridToolbar
          }}
          componentsProps={{
            filterPanel: {
              linkOperators: [GridLinkOperator.And],
              columnsSort: 'asc',
              filterFormProps: {
                linkOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small'
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' }
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' }
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small'
                  }
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' }
                  }
                }
              },
              sx: {
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterForm:nth-child(even)': {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                },
                '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 }
              }
            }
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            setColumnVisibilityModel(newModel)
            handleSaveColumnVisibility({
              tableId,
              userId: user?.id ?? '',
              column_visibility: newModel
            })
          }}
          localeText={DatagridLanguage()}
        />
      </List>
    </>
  )
}
