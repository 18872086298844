import React, { ReactNode, useEffect, useState } from 'react'
import { Edit } from '@refinedev/mui'
import {
  Box,
  Grid,
  Drawer,
  Stack,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Chip,
  Autocomplete,
  Button
} from '@mui/material'
import { Clear, CloseOutlined, Save } from '@mui/icons-material'
import { useOne } from '@refinedev/core'
import { StatusAttendance } from 'enums'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import UsystemEditor from 'usystem-ckeditor5'
import { ExtendedFile } from 'interfaces'
import { Controller, FieldValues } from 'react-hook-form'
import { axiosInstance } from 'config/axios'
import USystemUploadAdapterPlugin from 'components/ckeditor/upload-adapter'
import { FileInput } from 'components/file-input'

export const EditAttendance: React.FC<any> = ({
  register,
  formState: { errors },
  refineCore: { onFinish, id },
  handleSubmit,
  control,
  modal: { visible, close },
  setValue,
  saveButtonProps,
  customerId
}) => {
  const { data } = useOne({
    id,
    resource: 'attendance',
    metaData: {
      fields: [
        'id',
        'title',
        'content',
        'html',
        'attachment',
        'createdAt',
        {
          createdBy: ['id, name']
        },
        {
          files: [
            'id',
            'file_name',
            'mimetype',
            'size',
            'file_url',
            'key',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    }
  })
  const attendance = data?.data
  const [message, setMessage] = useState<string>('')
  const [files, setFiles] = useState<any[]>([])
  const [status, setStatus] = useState<number | null>(null)

  useEffect(() => {
    setFiles(attendance?.files || [])
    if (attendance?.createdAt) {
      const dateStr = new Date(attendance.createdAt).toISOString().slice(0, 16)
      setValue('return_date', dateStr)
    }
    setStatus(Object.keys(StatusAttendance).indexOf(attendance?.attendance_status as unknown as StatusAttendance))
  }, [attendance])

  const handleFileChange = async (selectedFiles: FileList) => {
    const uploadedFiles: ExtendedFile[] = []
    for (const file of Array.from(selectedFiles)) {
      const formData = new FormData()
      formData.append('upload', file)
      const accessToken = localStorage.getItem(
        process.env.REACT_APP_TOKEN_KEY as string
      )
      const { data } = await axiosInstance.post<{ url: string; key: string }>(
        'file/upload',
        formData,
        {
          withCredentials: false,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      const extendedFile = file as ExtendedFile
      extendedFile.url = data.url
      extendedFile.key = data.key

      uploadedFiles.push(extendedFile)
    }
    setFiles([...files, ...uploadedFiles])
  }

  const handleDeleteFile = async (fileToDelete: ExtendedFile) => {
    const accessToken = localStorage.getItem(
      process.env.REACT_APP_TOKEN_KEY as string
    )
    try {
      await axiosInstance.delete('file/remove', {
        data: { key: fileToDelete.key },
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
    } catch (error) {
      console.error('Error deleting file:', error)
    }
    setFiles(files.filter((file) => file !== fileToDelete))
  }

  const handleSubmitEdit = (values: FieldValues) => {
    const { attendance_status: st, return_date: returnDate } = values

    const indexOfTitle = message.indexOf('</h1>')
    const html = message.toString().slice(indexOfTitle)
    const div = document.createElement('div')
    div.innerHTML = html
    const content = div.textContent || div.innerText || ''

    onFinish({
      html: message,
      content,
      title: message.toString().slice(4, indexOfTitle),
      return_date: returnDate,
      attendance_status: status === -1 ? null : status,
      customerId,
      files:
        (files ?? []).length > 0
          ? files.filter((f) => f.name).map((file) => ({
            file_name: file.name,
            file_url: file.url,
            mimetype: file.type,
            size: file.size,
            key: file.key
          }))
          : null
    })
  }

  return (
    <>
      <Drawer
        sx={{ zIndex: '1301' }}
        PaperProps={{ sx: { width: { sm: '100%', md: '50%' } } }}
        open={visible}
        onClose={close}
        anchor="right"
      >
        <Edit
          headerProps={{
            avatar: (
              <IconButton
                onClick={() => close()}
                sx={{
                  height: '30px',
                  mb: '5px'
                }}
              >
                <CloseOutlined />
              </IconButton>
            ),
            action: null,
            title: (
              <Typography variant="h5" gutterBottom>
                Editar Atendimento
              </Typography>
            )
          }}
          wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
          breadcrumb={null}
          saveButtonProps={saveButtonProps}
          footerButtons={<></>}
        >
          <form onSubmit={handleSubmit(handleSubmitEdit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CKEditor
                  editor={UsystemEditor}
                  config={{ extraPlugins: [USystemUploadAdapterPlugin] }}
                  data={attendance?.html || ''}
                  onChange={(event: any, editor: { getData: () => any }) => {
                    const data = editor.getData()
                    setMessage(data)
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FileInput onFileChange={handleFileChange} />
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%',
                    paddingTop: 2
                  }}
                >
                  {files.map((file, index) => (
                    <Chip
                      key={index}
                      label={file.file_name ?? file.name}
                      onDelete={() => handleDeleteFile(file)}
                      deleteIcon={<Clear />}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controller
                  control={control}
                  name="attendance_status"
                  defaultValue={null as any}
                  render={({ field }) => (
                    <Autocomplete
                      disablePortal
                      {...field}
                      options={Object.values(StatusAttendance)}
                      onChange={(_, value) => {
                        field.onChange(Object.values(StatusAttendance).indexOf(value as unknown as StatusAttendance))
                        setStatus(Object.values(StatusAttendance).indexOf(value))
                      }}
                      getOptionLabel={(value) => {
                        let option = ''
                        option = (typeof value === 'number')
                          ? Object.values(StatusAttendance)[value]
                          : StatusAttendance[value]

                        if (!option) {
                          const indexOfS = Object.values(StatusAttendance).indexOf(value as unknown as StatusAttendance)
                          const key = Object.keys(StatusAttendance)[indexOfS]
                          option = StatusAttendance[key]
                        }
                        return option
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (typeof value === 'number') return option.toString() === Object.values(StatusAttendance)[value]

                        return value === undefined ||
                    option.toString() === StatusAttendance[value]
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status do Atendimento"
                          variant="outlined"
                          error={!!errors.attendance_status}
                          helperText={errors.attendance_status?.message as ReactNode}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  {...register('return_date', { valueAsDate: true })}
                  error={!!errors?.return_date}
                  helperText={errors.return_date?.message as ReactNode}
                  label="Data para Retorno"
                  placeholder="Data para Retorno"
                  name="return_date"
                  type="datetime-local"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Stack
              justifyContent="end"
              alignContent="end"
              alignItems="end"
              direction="row"
              spacing={4}
            >
              <Button type="submit" variant="contained" startIcon={<Save />}>
                Editar
              </Button>
            </Stack>
          </form>
        </Edit>
      </Drawer>
    </>
  )
}
