import React, { useState, createContext, ReactNode } from 'react'
import { IFile } from 'interfaces'

type FileUploaderContextProps = {
  files: IFile[]
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>
}

type ProviderProps = {
  children: ReactNode | ReactNode[]
}

export const FileUploaderContext = createContext<FileUploaderContextProps | null>(null)
export function FileUploaderProvider ({ children }: ProviderProps) {
  const [files, setFiles] = useState<IFile[]>([])

  return (
    <FileUploaderContext.Provider value={{
      files,
      setFiles
    }}>
      {children}
    </FileUploaderContext.Provider>
  )
}
