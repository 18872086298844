import React from 'react'
import { Show } from '@refinedev/mui'
import {
  Box,
  Grid,
  Drawer,
  Container,
  Paper,
  Stack,
  Typography,
  IconButton,
  TextField,
  Button
} from '@mui/material'
import ImagesInGrid from 'components/image-list-item'
import { CloseOutlined } from '@mui/icons-material'
import { useGetIdentity } from '@refinedev/core'
import { StatusAttendance } from 'enums'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import UsystemEditor from 'usystem-ckeditor5'

export function ShowAttendance ({ modal: { visible, close }, attendance }) {
  if (!attendance) return null
  const { data: user } = useGetIdentity<{
    name: string;
  }>()

  const indexOfTitle = attendance.html.indexOf('</h1>')
  const html = attendance.html.toString().slice(indexOfTitle)
  return (
    <>
      <Drawer
        sx={{ zIndex: '1301' }}
        PaperProps={{ sx: { width: { sm: '100%', md: '50%' } } }}
        open={visible}
        onClose={close}
        anchor="right"
      >
        <Show
          headerProps={{
            avatar: (
              <IconButton
                onClick={() => close()}
                sx={{
                  height: '30px',
                  mb: '5px'
                }}
              >
                <CloseOutlined />
              </IconButton>
            ),
            action: null,
            title: (
              <Typography variant="h5" gutterBottom>
                Visualizar Atendimento
              </Typography>
            )
          }}
          wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
          breadcrumb={null}
          footerButtons={<></>}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              pt: 8,
              pb: 6
            }}
          >
            <Container>
              <Grid container rowSpacing={2}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="info"
                      href={`/clientes/visualizar/${attendance?.customer?.id}`}
                    >
                      Visualizar Cliente
                    </Button>
                  </Grid>
                </Grid>
                {attendance?.customer?.name && (
                  <Grid item xs={12} justifyContent="center">
                    <TextField
                      disabled
                      label="Cliente"
                      defaultValue={attendance?.customer?.name}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={6}
                  paddingRight={1}
                  justifyContent="center"
                >
                  <TextField
                    disabled
                    label="Criado por"
                    defaultValue={attendance?.createdBy?.name ?? user?.name}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  paddingLeft={1}
                  justifyContent="center"
                >
                  <TextField
                    disabled
                    label="Criado em"
                    defaultValue={new Date(
                      attendance?.createdAt
                    ).toLocaleString()}
                    fullWidth
                  />
                  <Typography variant="body1" gutterBottom></Typography>
                </Grid>
                {attendance?.updatedBy?.name && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      paddingRight={1}
                      justifyContent="center"
                    >
                      <TextField
                        disabled
                        label="Atualizado por"
                        defaultValue={attendance?.updatedBy?.name}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      paddingLeft={1}
                      justifyContent="center"
                    >
                      <TextField
                        disabled
                        label="Atualizado em"
                        defaultValue={new Date(
                          attendance?.updatedAt
                        ).toLocaleString()}
                        fullWidth
                      />
                      <Typography variant="body1" gutterBottom></Typography>
                    </Grid>
                  </>
                )}
                {attendance?.return_date && (
                  <Grid item xs={12} justifyContent="center">
                    <TextField
                      disabled
                      label="Retorno agendado para"
                      defaultValue={new Date(
                        attendance?.return_date
                      ).toLocaleString()}
                      fullWidth
                    />
                    <Typography variant="body1" gutterBottom></Typography>
                  </Grid>
                )}
                {attendance?.attendance_status && (
                  <Grid item xs={12} justifyContent="center">
                    <TextField
                      disabled
                      label="Status do Atendimento"
                      defaultValue={
                        StatusAttendance[attendance?.attendance_status]
                      }
                      fullWidth
                    />
                    <Typography variant="body1" gutterBottom></Typography>
                  </Grid>
                )}
                <Grid item xs={12} justifyContent="center">
                  <Paper>
                    <Typography variant="h5" align="center" paragraph>
                      {attendance?.title}
                    </Typography>
                    <CKEditor
                      editor={UsystemEditor}
                      data={html}
                      disabled={true}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Paper>
                    <Typography variant="h5" align="center" paragraph>
                      {(attendance?.files ?? []).length === 0
                        ? 'Não possui '
                        : ''}
                      Anexos
                    </Typography>
                    <Stack
                      sx={{ pt: 4 }}
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                    >
                      <ImagesInGrid files={attendance?.files ?? []} />
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Show>
      </Drawer>
    </>
  )
}
