import React, { useState, ReactNode } from 'react'
import { Clear, Save } from '@mui/icons-material'
import { Paper, Grid, Typography, Autocomplete, TextField, Stack, Button, Box, Chip, InputAdornment } from '@mui/material'
import { useForm } from '@refinedev/react-hook-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import UsystemEditor from 'usystem-ckeditor5'
import USystemUploadAdapterPlugin from 'components/ckeditor/upload-adapter'
import { StatusAttendance } from 'enums'
import { Controller, FieldValues } from 'react-hook-form'
import { useParsed } from '@refinedev/core'
import { axiosInstance } from 'config/axios'
import { FileInput } from 'components/file-input'
import { ExtendedFile } from 'interfaces'

import '../../components/ckeditor/style.css'

export const CreateAttendance = ({ defaultValue, type }) => {
  const { id: customerId } = useParsed()
  const [message, setMessage] = useState<string>('')
  const [files, setFiles] = useState<ExtendedFile[]>([])

  const handleFileChange = async (selectedFiles: FileList) => {
    const uploadedFiles: ExtendedFile[] = []
    for (const file of Array.from(selectedFiles)) {
      const formData = new FormData()
      formData.append('upload', file)
      const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post<{ url: string, key: string }>(
        'file/upload',
        formData,
        {
          withCredentials: false,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      const extendedFile = file as ExtendedFile
      extendedFile.url = data.url
      extendedFile.key = data.key

      uploadedFiles.push(extendedFile)
    }
    setFiles([...files, ...uploadedFiles])
  }

  const handleDeleteFile = async (fileToDelete: ExtendedFile) => {
    const accessToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    try {
      await axiosInstance.delete('file/remove', {
        data: { key: fileToDelete.key },
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`
        }
      })
    } catch (error) {
      console.error('Error deleting file:', error)
    }
    setFiles(files.filter((file) => file !== fileToDelete))
  }

  const {
    refineCore: { onFinish },
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      resource: 'messageCustomer',
      redirect: false,
      successNotification: {
        type: 'success',
        message: 'Atendimento cadastrado com sucesso!',
        undoableTimeout: 3000
      },
      invalidates: ['all'],
      onMutationSuccess: () => {
        clear()
      }
    }
  })

  const clear = () => {
    setMessage('')
    setFiles([])
  }

  const handleSubmitCreate = (values: FieldValues) => {
    const { attendance_status: status, return_date: returnDate } = values

    const indexOfTitle = message.indexOf('</h1>')
    const html = message.toString().slice(indexOfTitle)
    const div = document.createElement('div')
    div.innerHTML = html
    const content = div.textContent || div.innerText || ''

    onFinish({
      html: message,
      content,
      title: message.toString().slice(4, indexOfTitle),
      customerId,
      type,
      return_date: returnDate,
      attendance_status: status ? Object.values(StatusAttendance).indexOf(status) : null,
      files: (files ?? []).length > 0
        ? files.map((file) => ({
          file_name: file.name,
          file_url: file.url,
          mimetype: file.type,
          size: file.size,
          key: file.key
        }))
        : null
    })
  }

  return (
    <Paper elevation={4} sx={{ p: 2, gap: 2 }} >
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Criar Atendimento</Typography>
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              editor={ UsystemEditor }
              config={{ extraPlugins: [USystemUploadAdapterPlugin] }}
              data={message}
              onChange={ (event: any, editor: { getData: () => any }) => {
                const data = editor.getData()
                setMessage(data)
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <FileInput onFileChange={handleFileChange} />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                width: '100%',
                paddingTop: 2
              }}
            >
              {files.map((file, index) => (
                <Chip
                  key={index}
                  label={file.name}
                  onDelete={() => handleDeleteFile(file)}
                  deleteIcon={<Clear />}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Controller
              control={control}
              name="attendance_status"
              defaultValue={defaultValue ? StatusAttendance[defaultValue] : null}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  options={Object.values(StatusAttendance).filter(value => typeof value === 'string')}
                  onChange={(_, value) => {
                    field.onChange(value)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status do Atendimento"
                      variant="outlined"
                      margin="none"
                      error={!!errors.attendance_status}
                      helperText={errors.attendance_status?.message as ReactNode}
                      fullWidth
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextField
              {...register('return_date', { valueAsDate: true })}
              error={!!errors?.return_date}
              helperText={errors.return_date?.message as ReactNode}
              label="Data para Retorno"
              placeholder='Data para Retorno'
              name="return_date"
              type='datetime-local'
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={1}>
              <Button color='warning' onClick={clear} type="button" variant="contained"><Clear/> Limpar</Button>
              <Button type="submit" variant="contained"><Save/> Adicionar</Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}
