import React from 'react'
import { useForm } from '@refinedev/react-hook-form'
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Container,
  Card,
  CardContent
} from '@mui/material'

import { BaseRecord, HttpError, useLogin } from '@refinedev/core'
import { ILoginForm } from 'interfaces'

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BaseRecord, HttpError, ILoginForm>({
    defaultValues: {
      remember: false,
      access: '',
      password: ''
    }
  })

  const { mutate: login, isLoading } = useLogin<ILoginForm>()

  return (
    <>
      <Box
        component="div"
        sx={{
          background:
            `radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url(${process.env.NODE_ENV !== 'production' ? '' : 'https://braconsultoria.com/wp-content/uploads/2022/12/Design-sem-nome-4.png'})`,
          backgroundSize: 'cover'
        }}
      >
        <Container
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                width: { xs: 200, lg: 'auto' }
              }}
            >
              <img
                src="/images/logo/icon.min.png"
                alt="Logo Bra Consultoria"
                width="200px"
              />
            </Box>
            <Box maxWidth="400px" mt={4}>
              <Card sx={{ padding: 1 }}>
                <CardContent>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: '700',
                      color: 'text.primary'
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ color: 'text.primary' }}
                    >
                      {'Login'}
                    </Box>
                    <Typography variant="h6" gutterBottom>{ process.env.NODE_ENV !== 'production' ? 'Sistema de Testes' : 'Sistema Operacional'}</Typography>
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={handleSubmit((data) => {
                      login(data)
                    })}
                  >
                    <TextField
                      {...register('access', {
                        required: true
                      })}
                      id="access"
                      margin="normal"
                      fullWidth
                      label='Acesso'
                      name="access"
                      autoFocus
                    />
                    <TextField
                      {...register('password', {
                        required: true
                      })}
                      id="password"
                      margin="normal"
                      fullWidth
                      name="password"
                      label='Senha'
                      helperText={
                        errors?.password?.message
                      }
                      type="password"
                      placeholder="●●●●●●●●"
                      autoComplete="current-password"
                    />
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          span: {
                            fontSize: '12px',
                            color: 'text.secondary'
                          }
                        }}
                        color="secondary"
                        control={
                          <Checkbox
                            size="small"
                            id="remember"
                            {...register(
                              'remember'
                            )}
                          />
                        }
                        label='Lembre de mim'
                      />
                      <Link
                        href="#"
                        sx={{
                          color: 'text.secondary',
                          textDecoration: 'none'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {'Esqueceu a senha?'}
                        </Typography>
                      </Link>
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      color={process.env.NODE_ENV !== 'production' ? 'success' : 'warning'}
                      variant="contained"
                      sx={{
                        my: '8px',
                        color: 'white'
                      }}
                      disabled={isLoading}
                    >
                      {'Entrar'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}
