import React, { useMemo, ReactNode, useState, useEffect } from 'react'
import { ICommercialActivities, IUser } from 'interfaces'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { Stack, Typography, Button, Grid, Card, CardHeader, CardContent, Autocomplete, TextField, InputAdornment, Theme } from '@mui/material'
import { GridColumns, DataGrid, GridToolbar, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { HttpError, CrudFilters, BaseRecord, getDefaultFilter, useGetIdentity } from '@refinedev/core'
import { DateField, useDataGrid, List, useAutocomplete } from '@refinedev/mui'
import { Controller } from 'react-hook-form'
import { useForm } from '@refinedev/react-hook-form'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function CommercialActivitiesList () {
  const tableId = 'activities'

  const date = new Date()
  const currentDateMinusSevenDays = new Date(date.setDate(date.getDate() - 7))

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    date: true,
    user: true,
    first_appointment_of_the_day: true,
    last_appointment_of_the_day: true,
    appointments_count: true,
    return_schedules_on_return_date: true
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<ICommercialActivities>>(
    () => [
      {
        field: 'date',
        headerName: 'Data',
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: function render ({ row }) {
          return row.date ? <DateField format='D/M/YY' value={row.date} /> : 'Não consta atendimento no período'
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Usuario',
        flex: 1,
        sortable: false,
        minWidth: 150
      },
      {
        field: 'first_appointment_of_the_day',
        headerName: 'Primeiro Atendimento do Dia',
        flex: 1,
        minWidth: 200,
        type: 'date',
        sortable: false,
        renderCell: function render ({ row }) {
          return row.first_appointment_of_the_day ? <DateField format='HH:mm D/M/YY' value={row.first_appointment_of_the_day} /> : '-'
        }
      },
      {
        field: 'last_appointment_of_the_day',
        headerName: 'Último Atendimento do Dia',
        flex: 1,
        minWidth: 200,
        type: 'date',
        sortable: false,
        renderCell: function render ({ row }) {
          return row.last_appointment_of_the_day ? <DateField format='HH:mm D/M/YY' value={row.last_appointment_of_the_day} /> : '-'
        }
      },
      {
        field: 'appointments_count',
        headerName: 'Qtd. de Atendimentos',
        flex: 1,
        sortable: false,
        minWidth: 200
      },
      {
        field: 'return_schedules_on_return_date',
        headerName: 'Qtd. Atendimentos com Data de retorno',
        flex: 1,
        sortable: false,
        minWidth: 100
      }
    ],
    []
  )

  const meta = {
    fields: [
      'user',
      'date',
      'first_appointment_of_the_day',
      'last_appointment_of_the_day',
      'appointments_count',
      {
        appointments_by_attendance_status: ['count', 'status']
      },
      'return_schedules_on_return_date'
    ],
    operation: 'messageCustomerDailyReport',
    variables: {
      grouping: {
        type: 'Int',
        required: true,
        value: 0
      }
    }
  }

  const { dataGridProps, search, filters } = useDataGrid<ICommercialActivities,
  HttpError
  >({
    resource: 'messageCustomerDailyReport',
    pagination: {
      mode: 'off'
    },
    syncWithLocation: false,
    meta,
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { id, start_date: startDate, end_date: endDate } = params

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id && id.length > 0 ? id?.toString() : undefined
      })

      filters.push({
        field: 'start_date',
        operator: 'gte',
        value: startDate || currentDateMinusSevenDays
      })

      filters.push({
        field: 'end_date',
        operator: 'lte',
        value: endDate || new Date()
      })

      return filters
    }
  })

  const { handleSubmit, control, register, formState: { errors } } = useForm<
  BaseRecord,
  HttpError
  >({
    defaultValues: {
      userId: getDefaultFilter('id', filters, 'eq'),
      start_date: getDefaultFilter('start_date', filters, 'gte'),
      end_date: getDefaultFilter('end_date', filters, 'lte')
    }
  })

  const { autocompleteProps } = useAutocomplete<IUser>({
    resource: 'users',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'name',
            'email',
            'profile',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    },
    pagination: {
      current: 1,
      pageSize: 25
    },
    filters: [{
      field: 'profile',
      operator: 'eq',
      value: '2'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '3'
    },
    {
      field: 'profile',
      operator: 'eq',
      value: '6'
    }]
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List headerProps={{
          title: <Typography variant="h5">Resumo de Atividades no Comercial</Typography>
        }}
        >
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <CardHeader title={'Filtros'} />
                <CardContent sx={{ pt: 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={4}>
                      <Controller
                        control={control}
                        name="id"
                        render={({ field }) => (
                          <Autocomplete
                            {...autocompleteProps}
                            {...field}
                            onChange={(_, value: IUser | null) => {
                              field.onChange(value?.id)
                            }}
                            getOptionLabel={(item: IUser) => {
                              return item.name
                                ? item.name
                                : autocompleteProps?.options?.find(
                                  (p) =>
                                    p.id.toString() ===
                                item.toString()
                                )?.name ?? ''
                            }}
                            isOptionEqualToValue={(option: any, value: any) => {
                              return value === undefined || option.id === value.id
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Usuarios"
                                variant="outlined"
                                margin="normal"
                                error={!!errors.id}
                                helperText={errors.id?.message as ReactNode}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...register('start_date', {
                          required: 'Data Inicial é obrigatória!',
                          valueAsDate: true
                        })}
                        defaultValue={currentDateMinusSevenDays}
                        label="Data Inicial"
                        name="start_date"
                        margin="normal"
                        type="date"
                        color="success"
                        required
                        InputProps={{
                          startAdornment: <InputAdornment position="start">De</InputAdornment>
                        }}
                        error={!!errors.start_date}
                        helperText={errors.start_date?.message as ReactNode}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...register('end_date', {
                          required: 'Data Final é obrigatória!',
                          validate: (value) => {
                            const invalid = new Date(value).getTime() >= new Date().getTime()
                            if (invalid) { return 'A data não pode ser superior à data atual!' }
                            return true
                          },
                          valueAsDate: true
                        })}
                        defaultValue={new Date()}
                        label="Data Final"
                        name="end_date"
                        margin="normal"
                        type="date"
                        color="success"
                        required
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Até</InputAdornment>
                        }}
                        error={!!errors.end_date}
                        helperText={errors.end_date?.message as ReactNode}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="right">
                        <Button type="button" variant="contained" onClick={handleSubmit(search)}>
                          Filtrar
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12}>
              <DataGrid
                disableColumnFilter
                keepNonExistentRowsSelected
                {...dataGridProps}
                columns={columns}
                filterModel={undefined}
                density='compact'
                autoHeight
                pagination
                paginationMode='server'
                sortingMode='client'
                components={{
                  Toolbar: GridToolbar
                }}
                componentsProps={{
                  filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: 'asc',
                    filterFormProps: {
                      linkOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small'
                      },
                      columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' }
                      },
                      operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' }
                      },
                      valueInputProps: {
                        InputComponentProps: {
                          variant: 'outlined',
                          size: 'small'
                        }
                      },
                      deleteIconProps: {
                        sx: {
                          '& .MuiSvgIcon-root': { color: '#d32f2f' }
                        }
                      }
                    },
                    sx: {
                      '& .MuiDataGrid-filterForm': { p: 2 },
                      '& .MuiDataGrid-filterForm:nth-child(even)': {
                        backgroundColor: (theme: Theme) =>
                          theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                      },
                      '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                    }
                  },
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel)
                  handleSaveColumnVisibility({
                    tableId,
                    userId: user?.id ?? '',
                    column_visibility: newModel
                  })
                }}
                localeText={DatagridLanguage()}
                getRowId={(row) => row.user + row.date}
              />
            </Grid>
          </Grid>
        </List>
      </Grid>
    </Grid>
  )
}
