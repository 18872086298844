import React, { useState, ReactNode, useEffect } from 'react'
import { CloseOutlined, Save } from '@mui/icons-material'
import { IState, IDistrict, ITrainingStudents } from 'interfaces'
import { EnumKindOfPerson } from 'enums'
import { Drawer, Typography, IconButton, Stack, Box, Grid, TextField, Autocomplete, Button, createFilterOptions } from '@mui/material'
import { HttpError, useShow } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { FieldValues, Controller } from 'react-hook-form'
import { Create } from '@refinedev/mui'

export const ModalStudent: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  reset,
  control,
  modal: { visible, close },
  saveButtonProps
}) => {
  const [states, setStates] = useState<IState[]>()
  const [districts, setDistricts] = useState<IDistrict[]>()
  const [stateName, setStateName] = useState<string>()
  const metaData = {
    fields: [
      'id'
    ]
  }
  const { showId: trainingId } = useShow<ITrainingStudents>({ metaData })

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      const json = await data.json()
      setStates(json)
    }
    fetchData()
      .catch(console.error)
  }, [])

  useEffect(() => {
    const stateSelected = states?.find(state => state.nome === stateName)
    const fetchData = async () => {
      const data = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateSelected?.id}/distritos`)
      const json = await data.json()
      setDistricts(json)
    }
    fetchData()
      .catch(console.error)
  }, [stateName])

  const handleSubmitPostCreate = (values: FieldValues) => {
    onFinish({
      ...values,
      trainingId
    })
    reset()
    close()
  }

  function compareStates (a, b) {
    return a.localeCompare(b)
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option ?? ''
  })

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 1000 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Create
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Adicionar Aluno</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            component="div"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitPostCreate)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('name', {
                      required: 'Nome é obrigatório!',
                      minLength: {
                        value: 3,
                        message: 'Nome deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 150,
                        message: 'Nome deve conter no máximo 150 letras'
                      }
                    })}
                    error={!!errors?.name}
                    helperText={errors.name?.message as ReactNode}
                    label="Nome"
                    name="name"
                    margin="normal"
                    size='small'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('cpf_cnpj', {
                      minLength: {
                        value: 3,
                        message: 'CPF/CNPJ deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 150,
                        message: 'CPF/CNPJ deve conter no máximo 150 letras'
                      }
                    })}
                    error={!!errors?.cpf_cnpj}
                    helperText={errors.cpf_cnpj?.message as ReactNode}
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="type_person"
                    rules={{ required: 'Tipo de Pessoa é obrigatório!' }}
                    render={({ field }) => (
                      <Autocomplete
                        disablePortal
                        {...field}
                        options={Object.values(EnumKindOfPerson).filter(value => typeof value === 'string')}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de Pessoa"
                            variant="outlined"
                            margin="normal"
                            size='small'
                            error={!!errors.type_person}
                            helperText={errors.type_person?.message as ReactNode}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('email')}
                    error={!!errors?.email}
                    helperText={errors.email?.message as ReactNode}
                    label="E-mail"
                    name="email"
                    margin="normal"
                    size='small'
                    type='email'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('phone')}
                    error={!!errors?.phone}
                    helperText={errors.phone?.message as ReactNode}
                    label="Telefone"
                    name="phone"
                    placeholder='XX XXXXX-XXXX'
                    margin="normal"
                    size='small'
                    type='tel'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="state_abbreviation"
                    render={({ field }) => (
                      <Autocomplete
                        groupBy={(option) => {
                          const match = states?.find(state => state.nome === option)
                          return match?.regiao.nome || ''
                        }}
                        {...field}
                        disablePortal
                        options={(states?.map(state => state.nome) || []).sort(compareStates)}
                        onChange={(_, value: string | null) => {
                          if (value) {
                            setStateName(value)
                            field.onChange(value)
                          }
                        }}
                        filterOptions={filterOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estado"
                            variant="outlined"
                            margin='normal'
                            size='small'
                            error={!!errors.state_abbreviation}
                            helperText={errors.state_abbreviation?.message as ReactNode}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={districts?.map(district => district?.nome).sort(compareStates) || []}
                        onChange={(_, value: string | null) => {
                          if (value) {
                            field.onChange(value)
                          }
                        }}
                        filterOptions={(options, { inputValue }) => {
                          return options.filter(option =>
                            option.toLowerCase().startsWith(inputValue.toLowerCase())
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            variant="outlined"
                            margin='normal'
                            size='small'
                            error={!!errors.city}
                            helperText={errors.city?.message as ReactNode}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('address', {
                      minLength: {
                        value: 1,
                        message: 'Endereço deve conter no mínimo 1 letra'
                      },
                      maxLength: {
                        value: 250,
                        message: 'Endereço deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.address}
                    helperText={errors.address?.message as ReactNode}
                    label="Endereço"
                    name="address"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('zip_code')}
                    error={!!errors?.zip_code}
                    helperText={errors.zip_code?.message as ReactNode}
                    placeholder='XXXXXXXX'
                    label="CEP"
                    name="zip_code"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('number')}
                    error={!!errors?.number}
                    helperText={errors.number?.message as ReactNode}
                    label="Número"
                    name="number"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('complement')}
                    error={!!errors?.complement}
                    helperText={errors.complement?.message as ReactNode}
                    label="Complemento"
                    name="complement"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    <Save />
                  }>Salvar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Create>
    </Drawer>
  )
}
