import React, { SyntheticEvent, useState, ReactNode } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { IFileFilterVariables, IFileServer } from 'interfaces'
import { Search, Wallet } from '@mui/icons-material'
import ImagesInGrid from 'components/image-list-item'
import { Stack, Button, Grid, Typography, TextField, InputAdornment, Fab, Tab } from '@mui/material'
import { useShow, HttpError, CrudFilters, BaseRecord, getDefaultFilter } from '@refinedev/core'
import { DateField, useDataGrid, Show, RefreshButton, List } from '@refinedev/mui'
import { useForm } from '@refinedev/react-hook-form'
import { MetaQuery } from '@refinedev/core/dist/interfaces/metaData'
import { WalletShow } from 'components/wallet'
import { ListContract } from 'components/contracts'

import '../../components/ckeditor/style.css'
import { ListAttendance } from 'components/attendance/list'
import { AttendanceStatus } from 'components/attendance'
import { TypeAttendance } from 'enums'

export function CustomerShow () {
  const date = new Date()
  const currentDateMinusSevenDays = new Date(date.setDate(date.getDate() - 7))

  const [tabSelected, setTabSelected] = useState('1')
  const [modalOpen, setModalOpen] = useState(false)

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTabSelected(newValue)
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const meta: MetaQuery = {
    fields: [
      'id',
      'cnpj',
      'inscricao_estadual',
      'name',
      'cep',
      'number',
      'address',
      'county_name',
      'state_abbreviation',
      'attendance_status',
      'status',
      'mail',
      'createdAt',
      'updatedAt',
      'deletedAt',
      'isInWallet',
      {
        contracts: ['id']
      }
    ],
    operation: 'simec'
  }
  const { queryResult, showId } = useShow<any>({
    meta
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const { dataGridProps: files, search: searchFiles, filters: fileFilters } = useDataGrid<IFileServer,
  HttpError,
  IFileFilterVariables
  >({
    pagination: {
      pageSize: 250,
      current: 0
    },
    syncWithLocation: false,
    resource: 'messagesCustomerFiles',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'file_name',
            'mimetype',
            'size',
            'file_url',
            'key',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ],
      variables: {
        customerId: {
          value: showId,
          name: 'customerId',
          required: true
        }
      }
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'asc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { start_date: startDate, end_date: endDate } = params

      filters.push({
        field: 'createdAt',
        operator: 'gte',
        value: startDate ?? currentDateMinusSevenDays
      })

      filters.push({
        field: 'createdAt',
        operator: 'lte',
        value: endDate ?? new Date()
      })

      return filters
    }
  })

  const { handleSubmit: handleSubmitFile, register: fileRegister, formState: { errors: fileErrors } } = useForm<
  BaseRecord,
  HttpError,
  IFileFilterVariables
  >({
    defaultValues: {
      start_date: getDefaultFilter('createdAt', fileFilters, 'gte'),
      end_date: getDefaultFilter('createdAt', fileFilters, 'lte')
    }
  })

  return (
    <>
      <WalletShow open={modalOpen} handleClose={handleModalClose} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Show
            isLoading={isLoading}
            headerProps={{
              title: <Typography variant="h5">Cliente: {record?.name}</Typography>,
              action: (
                <Stack direction="row" spacing={2}>
                  <RefreshButton meta={meta} recordItemId={showId} />
                  <Button
                    variant='contained'
                    startIcon={<Wallet />}
                    onClick={() => handleModalOpen()}
                  > Carteira</Button>
                </Stack>
              )
            }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    CNPJ
                </Typography>
                <Typography variant="body2">{record?.cnpj}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Inscrição Estadual
                </Typography>
                <Typography variant="body2">{record?.inscricao_estadual ? record?.inscricao_estadual : 'Não Possui'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Município
                </Typography>
                <Typography variant="body2">{record?.county_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Estado
                </Typography>
                <Typography variant="body2">{record?.state_abbreviation}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    CEP
                </Typography>
                <Typography variant="body2">{record?.cep}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Endereço
                </Typography>
                <Typography variant="body2">{record?.address}, {record?.number || 'S/N'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Criado em
                </Typography>
                <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.createdAt} /></Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Criado Por
                </Typography>
                <Typography variant="body2">{record?.createdBy?.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Atualizado em
                </Typography>
                <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.updatedAt} /></Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Atualizado Por
                </Typography>
                <Typography variant="body2">{record?.updatedBy?.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Carteira
                </Typography>
                <Typography color={record?.isInWallet ? 'green' : 'GrayText'} variant="body1" fontWeight="bold">{record?.isInWallet ? 'Está na sua carteira de clientes' : 'Não está na sua carteira de clientes'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Status de atendimento
                </Typography>
                <Typography variant="body2">{record?.attendance_status ? <AttendanceStatus status={record?.attendance_status} /> : null}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                    Status do contrato
                </Typography>
                <Typography color={record?.contracts.length > 0 ? 'green' : 'GrayText'} variant="body1">{record?.contracts.length > 0 ? 'Possui contrato no sistema' : 'Sem contrato no sistema'}</Typography>
              </Grid>
              {record?.deletedAt
                ? <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant="body1" fontWeight="bold">
                    Deletado em
                  </Typography>
                  <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.deletedAt} /></Typography>
                </Grid>
                : null }
            </Grid>
          </Show>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <TabContext value={tabSelected}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <TabList onChange={handleChangeTab} textColor='primary' indicatorColor='secondary'>
                <Tab label="Atendimentos" value="1" />
                <Tab label="Arquivos" value="2" />
                <Tab label="Contratos" value="3" />
              </TabList>
            </Stack>
            <TabPanel value="1">
              <ListAttendance defaultValue={record?.attendance_status} type={TypeAttendance.COMMERCIAL} />
            </TabPanel>
            <TabPanel value="2">
              <List headerProps={{
                title: <Typography variant="h5">Arquivos</Typography>,
                action: (
                  <Grid spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        {...fileRegister('start_date', {
                          required: 'Data Inicial é obrigatória!',
                          validate: (value) => {
                            const invalid = new Date(value).getTime() >= new Date().getTime()
                            if (invalid) { return 'A data não pode ser superior à data atual!' }
                            return true
                          },
                          valueAsDate: true
                        })}
                        error={!!fileErrors?.start_date}
                        helperText={fileErrors.start_date?.message as ReactNode}
                        label="Data Inicial"
                        name="start_date"
                        margin="normal"
                        type="date"
                        color="success"
                        required
                        InputProps={{
                          startAdornment: <InputAdornment position="start">De</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        {...fileRegister('end_date', {
                          required: 'Data Final é obrigatória!',
                          validate: (value) => {
                            const invalid = new Date(value).getTime() >= new Date().getTime()
                            if (invalid) { return 'A data não pode ser superior à data atual!' }
                            return true
                          },
                          valueAsDate: true
                        })}
                        error={!!fileErrors?.end_date}
                        helperText={fileErrors.end_date?.message as ReactNode}
                        label="Data Final"
                        name="end_date"
                        margin="normal"
                        type="date"
                        color="success"
                        required
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Até</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Fab onClick={handleSubmitFile(searchFiles)} size='medium' color="info" aria-label="search">
                          <Search />
                        </Fab>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }}
              breadcrumb={<></>}
              >
                <Grid item xs={12}>
                  <ImagesInGrid files={files.rows} />
                </Grid>
              </List>
            </TabPanel>
            <TabPanel value="3">
              <ListContract />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  )
}
