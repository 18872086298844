import React, { useState } from 'react'
import { HttpError, useShow } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Create } from '@refinedev/mui'

import {
  Drawer,
  FormLabel,
  Stack,
  Box,
  IconButton,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'

import { CloseOutlined, Mail } from '@mui/icons-material'
import { ICampaign } from 'interfaces'

export const ModalMailTest: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  setValue,
  reset,
  modal: { visible, close },
  saveButtonProps,
  control
}) => {
  const metaData = {
    fields: [
      'id'
    ]
  }
  const { showId: campaignId } = useShow<ICampaign>({ meta: metaData })

  const handleSubmitPostCreate = (values: FieldValues) => {
    onFinish({
      ...values,
      campaignId
    })
    reset()
    close()
  }

  const [radio, setRadio] = useState<number | null>(null)

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 400 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Create
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Envio de E-mail de Teste</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitPostCreate)}>
              <Stack sx={{ paddingBottom: 2 }} gap="10px" marginTop="30px">
                <FormControl>
                  <FormLabel
                    sx={{ marginTop: '10px' }}
                    required
                  >
                    Tipo de Envio
                  </FormLabel>
                  <Controller
                    control={control}
                    {...register('type')}
                    defaultValue={false}
                    render={({ field }) => (
                      <RadioGroup
                        id="type"
                        {...field}
                        onChange={(event, value) => {
                          setValue(
                            'type',
                            Number(value),
                            {
                              shouldValidate: true
                            }
                          )
                          setRadio(Number(value))
                          return value
                        }}
                        row
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Enviar para mim"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="Enviar no E-mail abaixo"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel required={(radio === 2)}>
                    E-mail
                  </FormLabel>
                  <OutlinedInput
                    id="mail"
                    {...register('mail', { required: (radio === 2) })}
                    style={{ height: '40px' }}
                    disabled={(radio === 1)}
                  />
                  {errors.description && (
                    <FormHelperText error>
                      {errors.description?.message as React.ReactNode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button type="submit" variant="contained"><Mail/>  Enviar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Create>
    </Drawer>
  )
}
