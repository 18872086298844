import { AuthBindings } from '@refinedev/core'
import { getColumnVisibility } from 'components/datagrid'
import { axiosInstance } from 'config/axios'
import { client } from 'config/client'
import { query as queryGQL, mutation } from 'gql-query-builder'

export const authProvider: AuthBindings = {
  login: async ({ access, password }) => {
    try {
      const { query, variables } = mutation({
        operation: 'login',
        variables: {
          input: {
            type: 'LoginInputDTO',
            required: true,
            value:
          {
            access,
            password
          }
          }
        },
        fields: [
          'accessToken',
          'profile'
        ]
      })

      const response: any = await client.request(query, variables)
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY as string, response.login.accessToken)
      localStorage.setItem('profile', response.login.profile)
      client.setHeader('authorization', `Bearer ${response.login.accessToken} `)
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${response.login.accessToken}`
      }

      const columns = await getColumnVisibility(response.login.accessToken)
      localStorage.setItem('columns', JSON.stringify(columns))

      return {
        success: true,
        redirectTo: '/'
      }
    } catch (err: any) {
      return {
        success: false,
        redirectTo: '/login',
        error: {
          name: 'Login não autorizado!',
          message: ''
        }
      }
    }
  },
  logout: async () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY as string)
    localStorage.removeItem('columns')

    return {
      success: true,
      redirectTo: '/login'
    }
  },
  onError: async (error) => {
    if (error?.response?.status === 401) {
      return {
        logout: true,
        redirectTo: '/login',
        error
      }
    }
    return {}
  },
  check: async () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    if (token) {
      const { query } = queryGQL({
        operation: 'me',
        fields: [
          'id',
          'name',
          'profile'
        ]
      })

      const response: any = await client.request(query)
      if (response.me?.id) {
        return {
          authenticated: true
        }
      }
    }
    return {
      authenticated: false,
      error: new Error('Sessão inválida ou expirada!'),
      logout: true,
      redirectTo: '/login'
    }
  },
  getPermissions: async () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    if (token) {
      const { query } = queryGQL({
        operation: 'me',
        fields: [
          'id',
          'name',
          'profile'
        ]
      })

      const response: any = await client.request(query)
      if (response.me?.id) {
        return response.me
      }
    }
    return null
  },
  getIdentity: async () => {
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    if (token) {
      const { query } = queryGQL({
        operation: 'me',
        fields: [
          'id',
          'name',
          'profile'
        ]
      })

      const response: any = await client.request(query)
      if (response.me?.id) {
        return response.me
      }
    }
    return null
  }
}
