import React, { ReactNode } from 'react'
import { HttpError, useShow } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Create } from '@refinedev/mui'
import { Drawer, Stack, Box, IconButton, FormControl, Typography, Button, TextField } from '@mui/material'
import { Cancel, CloseOutlined } from '@mui/icons-material'
import { IBankSlips } from 'interfaces'
import { Autocomplete } from '@mui/lab'
import { BankSlipCancellationReason } from 'enums'

export const ModalCancel: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  modal: { visible, close },
  saveButtonProps,
  control
}) => {
  const metaData = {
    fields: [
      'id'
    ]
  }
  const { showId: id } = useShow<IBankSlips>({ meta: metaData })

  const handleSubmitCancel = (values: FieldValues) => {
    onFinish({
      status: 5,
      cancellation_reason: Object.keys(BankSlipCancellationReason)[Object.values(BankSlipCancellationReason).indexOf(values.cancellation_reason)],
      id
    })
    close()
  }

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 400 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Create
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Cancelar Boleto</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitCancel)}>
              <Stack sx={{ paddingBottom: 2 }} gap="10px" marginTop="30px">
                <FormControl>
                  <Controller
                    control={control}
                    name="cancellation_reason"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={Object.values(BankSlipCancellationReason)}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Motivo do Cancelamento"
                            variant="outlined"
                            error={!!errors.cancellation_reason}
                            helperText={errors.cancellation_reason?.message as ReactNode}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button color="error" type="submit" variant="contained"><Cancel/>  Cancelar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Create>
    </Drawer>
  )
}
