import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, TextField, InputAdornment, Fab, Stack } from '@mui/material'
import { DataGrid, GridColumnVisibilityModel, GridColumns } from '@mui/x-data-grid'
import { CreateAttendance } from './create'
import { DateField, DeleteButton, EditButton, List, ShowButton, useDataGrid } from '@refinedev/mui'
import { BaseRecord, CanAccess, CrudFilters, HttpError, getDefaultFilter, useGetIdentity, useParsed } from '@refinedev/core'
import { IFileFilterVariables, IMessageCustomer } from 'interfaces'
import { Download, Search } from '@mui/icons-material'
import { useForm, useModalForm } from '@refinedev/react-hook-form'
import { ShowAttendance } from './show'
import { AttendanceStatus } from './status'
import { handleSaveColumnVisibility } from 'components/datagrid'
import { EditAttendance } from './edit'
import { axiosInstance } from 'config/axios'

export const ListAttendance = ({ defaultValue, type }) => {
  const tableId = 'attendance'

  const { id } = useParsed()
  const [attendance, setAttendance] = useState<IMessageCustomer | null>(null)

  const date = new Date()
  const currentDateMinusSevenDays = new Date(date.setDate(date.getDate() - 7))

  const attendanceViewModal = useModalForm<IMessageCustomer>()

  const {
    modal: { show: showAttendance }
  } = attendanceViewModal

  const editAttendance = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'attendance',
      metaData: {
        fields: [
          'id',
          'title',
          'content',
          'html',
          'attachment',
          'attendance_status',
          'return_date',
          'createdAt',
          {
            customer: ['id, name'],
            createdBy: ['id, name'],
            updatedBy: ['id, name']
          },
          {
            files: [
              'id',
              'file_name',
              'mimetype',
              'size',
              'file_url',
              'key',
              'createdAt',
              'updatedAt',
              'deletedAt'
            ]
          }
        ]
      }
    }
  })

  const {
    modal: { show: showEditModal }
  } = editAttendance

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    title: true,
    content: true,
    attendance_status: true,
    return_date: true,
    files: true,
    createdAt: true,
    createdBy: true
  })

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<IMessageCustomer>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} type='button' onClick={() => {
                setAttendance(row)
                showAttendance(row.id)
              }} />
              {
                user?.id && (
                  <>
                    <CanAccess resource="attendances" action="update" params={{ userId: row.createdBy?.id, myId: user?.id }}>
                      <EditButton
                        hideText
                        recordItemId={row.id}
                        type='button'
                        resource="messageCustomer"
                        onClick={() => {
                          showEditModal(row.id)
                        }}
                      />
                    </CanAccess>
                    <CanAccess resource="attendances" action="update" params={{ userId: row.createdBy?.id, myId: user?.id }}>
                      <DeleteButton
                        hideText
                        recordItemId={row.id}
                        resource="messageCustomer"
                        invalidates={['all']}
                      />
                    </CanAccess>
                  </>
                )
              }
            </Stack>
          )
        }
      },
      {
        field: 'title',
        headerName: 'Título',
        flex: 1,
        minWidth: 250
      },
      {
        field: 'content',
        headerName: 'Mensagem',
        flex: 4,
        minWidth: 400
      },
      {
        field: 'attendance_status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.attendance_status ? <AttendanceStatus status={row.attendance_status} /> : null
        }
      },
      {
        field: 'return_date',
        headerName: 'Data de Retorno',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.return_date ? <DateField format='D/M/YY' value={row.return_date} /> : null
        }
      },
      {
        field: 'files',
        headerName: 'Anexos',
        flex: 1,
        minWidth: 100,
        filterable: false,
        sortable: false,
        renderCell: function render ({ row }) {
          return (row.files ?? []).length
        }
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy',
        headerName: 'Criado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.updatedAt} />
        }
      },
      {
        field: 'updatedBy',
        headerName: 'Atualizado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.updatedBy?.name
        }
      }
    ],
    [user]
  )

  const { dataGridProps: messages, search: searchMessages, filters: messageFilters } = useDataGrid<IMessageCustomer,
  HttpError,
  IFileFilterVariables
  >({
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    resource: 'messagesCustomer',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'title',
            'content',
            'html',
            'attachment',
            'attendance_status',
            'return_date',
            'createdAt',
            {
              createdBy: ['id, name'],
              updatedBy: ['id, name'],
              customer: ['id, name']
            },
            {
              files: [
                'id',
                'file_name',
                'mimetype',
                'size',
                'file_url',
                'key',
                'createdAt',
                'updatedAt',
                'deletedAt'
              ]
            },
            'updatedAt'
          ]
        }
      ],
      variables: {
        customerId: {
          value: id,
          name: 'customerId',
          required: true
        },
        type: {
          value: type.toString(),
          name: 'type',
          required: true
        }
      }
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { start_date: startDate, end_date: endDate } = params

      filters.push({
        field: 'createdAt',
        operator: 'gte',
        value: startDate ?? currentDateMinusSevenDays
      })

      filters.push({
        field: 'createdAt',
        operator: 'lte',
        value: endDate ?? new Date()
      })

      return filters
    }
  })

  const { handleSubmit: handleSubmitMessages, register: messageRegister, formState: { errors: messageErrors }, getValues } = useForm<
  BaseRecord,
  HttpError,
  IFileFilterVariables
  >({
    defaultValues: {
      end_date: getDefaultFilter('createdAt', messageFilters, 'lte'),
      start_date: getDefaultFilter('createdAt', messageFilters, 'gte')
    }
  })

  const [loading, setLoading] = useState<boolean>(false)

  async function generateXlsx (startDate: Date, endDate: Date) {
    setLoading(true)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    const { data } = await axiosInstance.post(`attendance/xlsx/${id}`, {
      startDate,
      endDate
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    })
    if (data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'relatótio-de-atendimento.xlsx')
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      setLoading(false)
    }
  }

  const validateDates = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      alert('Ambas as datas são obrigatórias!')
      return false
    }

    if (startDate.getTime() > endDate.getTime()) {
      alert('A data inicial não pode ser maior que a data final!')
      return false
    }

    return true
  }
  const generateXlsxWithDates = () => {
    const startDate = getValues('start_date')
    const endDate = getValues('end_date')

    // eslint-disable-next-line no-console
    console.log(startDate, endDate)
    if (validateDates(startDate, endDate)) {
      generateXlsx(startDate, endDate)
    }
  }
  return (
    <>
      <ShowAttendance attendance={attendance} {...attendanceViewModal} />
      <EditAttendance customerId={id} {...editAttendance} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List headerProps={{
            title: <Typography variant="h5">Histórico de Atendimento</Typography>,
            action: (
              <Grid spacing={2}
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    {...messageRegister('start_date', {
                      required: 'Data Inicial é obrigatória!',
                      validate: (value) => {
                        const invalid = new Date(value).getTime() >= new Date().getTime()
                        if (invalid) { return 'A data não pode ser superior à data atual!' }
                        return true
                      },
                      valueAsDate: true
                    })}
                    error={!!messageErrors?.start_date}
                    helperText={messageErrors.start_date?.message as ReactNode}
                    label="Data Inicial"
                    name="start_date"
                    margin="normal"
                    type="date"
                    color="success"
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start">De</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    {...messageRegister('end_date', {
                      required: 'Data Final é obrigatória!',
                      validate: (value) => {
                        const invalid = new Date(value).getTime() >= new Date().getTime()
                        if (invalid) { return 'A data não pode ser superior à data atual!' }
                        return true
                      },
                      valueAsDate: true
                    })}
                    error={!!messageErrors?.end_date}
                    helperText={messageErrors.end_date?.message as ReactNode}
                    label="Data Final"
                    name="end_date"
                    margin="normal"
                    type="date"
                    color="success"
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Até</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Fab onClick={handleSubmitMessages(searchMessages)} size='small' color="info" aria-label="search">
                      <Search />
                    </Fab>
                    <Fab onClick={generateXlsxWithDates} size='small' color="success" aria-label="download">
                      <Download />
                    </Fab>
                  </Stack>
                </Grid>
              </Grid>
            )
          }}
          breadcrumb={<></>}
          headerButtons={<></>}>
            <Grid item xs={12}>
              <DataGrid
                {...messages}
                columns={columns}
                density='compact'
                autoHeight
                pagination
                paginationMode='server'
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel)
                  handleSaveColumnVisibility({
                    tableId,
                    userId: user?.id ?? '',
                    column_visibility: newModel
                  })
                }}
              />
            </Grid>
          </List>
        </Grid>
        <Grid item xs={12}>
          <CreateAttendance defaultValue={defaultValue} type={type} />
        </Grid>
      </Grid>
    </>
  )
}
