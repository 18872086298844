import { CloseOutlined, Save } from '@mui/icons-material'
import { Box, Button, Drawer, Grid, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import { HttpError } from '@refinedev/core'
import { Edit } from '@refinedev/mui'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { ReactNode, useMemo, useState } from 'react'
import { FieldValues } from 'react-hook-form'

export const ChangePassword: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  reset,
  getValues,
  modal: { visible, close },
  saveButtonProps
}) => {
  const handleSubmitEdit = (values: FieldValues) => {
    onFinish(values)
    reset()
    close()
    setPass('')
    setPassConfirmation('')
  }

  const [passwordStrength, setPasswordStrength] = useState(0)
  const [hasUpperCase, setHasUpperCase] = useState(false)
  const [hasLowerCase, setHasLowerCase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecialChar, setHasSpecialChar] = useState(false)
  const [hasSixCharacters, setHasSixCharacters] = useState(false)

  const [pass, setPass] = useState('')
  const [passConfirmation, setPassConfirmation] = useState('')

  const passwordStrengthLabel = useMemo(() => {
    if (passwordStrength <= 0) return ''
    if (passwordStrength <= 40) return 'Senha Fraca'
    if (passwordStrength <= 80) return 'Senha Forte'
    return 'Senha Muito Forte'
  }, [passwordStrength])

  const passwordStrengthColor = useMemo(() => {
    if (passwordStrength <= 40) return 'error'
    if (passwordStrength <= 60) return 'warning'
    if (passwordStrength <= 80) return 'info'
    return 'success'
  }, [passwordStrength])

  const validatePasswordStrength = (password: string) => {
    setPass(password)
    const upperCase = /[A-Z]/.test(password)
    const lowerCase = /[a-z]/.test(password)
    const number = /[0-9]/.test(password)
    const specialChar = /[^A-Za-z0-9]/.test(password)
    const sixCharacters = password.length >= 6

    setHasUpperCase(upperCase)
    setHasLowerCase(lowerCase)
    setHasNumber(number)
    setHasSpecialChar(specialChar)
    setHasSixCharacters(sixCharacters)

    const strength = [upperCase, lowerCase, number, specialChar, sixCharacters].filter(Boolean).length

    setPasswordStrength(strength * 20)

    return strength > 2
      ? true
      : 'A senha deve ser forte'
  }

  const validatePasswordMatch = (value: string) => {
    setPassConfirmation(value)
    return value === getValues('password') || 'As senhas não correspondem!'
  }

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 400 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: (
            <>
              <Typography variant="h5">Alterar Senha</Typography>
              <Typography variant="subtitle1">A senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial</Typography>
            </>
          ),
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            component="div"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitEdit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>Força da Senha: {passwordStrengthLabel}</Typography>
                    <LinearProgress variant="determinate" value={passwordStrength} color={passwordStrengthColor} />
                  </Box>
                  <Stack spacing={1}>
                    <Typography color={hasSixCharacters ? 'text.primary' : 'text.secondary'}>6 caracteres</Typography>
                    <Typography color={hasUpperCase ? 'text.primary' : 'text.secondary'}>Letra maiúscula</Typography>
                    <Typography color={hasLowerCase ? 'text.primary' : 'text.secondary'}>Letra minúscula</Typography>
                    <Typography color={hasNumber ? 'text.primary' : 'text.secondary'}>Número</Typography>
                    <Typography color={hasSpecialChar ? 'text.primary' : 'text.secondary'}>Caractere especial</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('password', {
                      required: 'Senha é obrigatório!',
                      minLength: {
                        value: 6,
                        message: 'Confirmação da Senha deve conter no mínimo 6 caracteres'
                      },
                      maxLength: {
                        value: 30,
                        message: 'Senha deve conter no máximo 30 caracteres'
                      },
                      validate: {
                        passwordStrength: validatePasswordStrength
                      }
                    })}
                    error={!!errors?.password}
                    helperText={errors.password?.message as ReactNode}
                    label="Senha"
                    name="password"
                    margin="normal"
                    required
                    fullWidth
                    onChange={e => validatePasswordStrength(e.target.value)}
                    value={pass}
                    type='password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('password_confirmation', {
                      required: 'Confirmação da Senha é obrigatório!',
                      minLength: {
                        value: 6,
                        message: 'Confirmação da Senha deve conter no mínimo 6 caracteres'
                      },
                      maxLength: {
                        value: 30,
                        message: 'Confirmação da Senha deve conter no máximo 30 caracteres'
                      },
                      validate: {
                        passwordMatch: validatePasswordMatch
                      }
                    })}
                    error={!!errors?.password_confirmation}
                    helperText={errors.password_confirmation?.message as ReactNode || (pass !== passConfirmation && 'As senhas não correspondem!')}
                    label="Confirmação da Senha"
                    name="password_confirmation"
                    margin="normal"
                    onChange={e => validatePasswordMatch(e.target.value)}
                    value={passConfirmation}
                    required
                    fullWidth
                    type='password'
                  />
                </Grid>
              </Grid>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={pass !== passConfirmation || passwordStrength < 40}
                  startIcon={
                    <Save />
                  }>Alterar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Edit>
    </Drawer>
  )
}
