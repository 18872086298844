import React, { useEffect, useMemo, useState } from 'react'
import { IBankSlips } from 'interfaces'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { BankSlipStatus } from 'components/bank-slips/status'
import { CopyAllOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import CopyToClipboard from 'components/clipboard-copy'
import { generatePDF } from 'components/bank-slips/generate-pdf'
import { Stack, Button, Grid, Typography, Theme } from '@mui/material'
import { GridColumns, DataGrid, GridToolbar, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { HttpError, CrudFilters, BaseRecord, getDefaultFilter, useGetIdentity } from '@refinedev/core'
import { useDataGrid, ShowButton, NumberField, DateField, List } from '@refinedev/mui'
import { useForm } from '@refinedev/react-hook-form'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function BankSlipsList () {
  const tableId = 'bankSlip'

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const { dataGridProps, filters } = useDataGrid<
  IBankSlips,
  HttpError,
  any
  >({
    pagination: {
      pageSize: 25,
      current: 0
    },
    metaData: {
      operation: 'bankSlips',
      fields: [
        'total',
        {
          nodes: [
            'id',
            {
              customer: ['id', 'name']
            },
            'value',
            'value_received',
            'cancellation_reason',
            'due_date',
            'status',
            'bar_code',
            'createdAt'
          ]
        }
      ]
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { q, store, user, status } = params

      filters.push({
        field: 'q',
        operator: 'eq',
        value: q !== '' ? q : undefined
      })

      filters.push({
        field: 'store.id',
        operator: 'eq',
        value: (store ?? [].length) > 0 ? store : undefined
      })

      filters.push({
        field: 'user.id',
        operator: 'eq',
        value: user
      })

      filters.push({
        field: 'status.text',
        operator: 'in',
        value: (status ?? []).length > 0 ? status : undefined
      })

      return filters
    }
  })

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    status: true,
    'customer.name': true,
    value: true,
    due_date: true,
    value_received: true,
    cancellation_reason: true,
    createdAt: true
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<IBankSlips>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} />
              <Button
                color='error'
                disabled={row.status.toString() !== 'PENDING_PAYMENT'}
                onClick={() => generatePDF(row.id, row.customer.name)}
                key={row.id}><PictureAsPdfOutlined/>
              </Button>
              <CopyToClipboard>
                {({ copy }) => (
                  <Button
                    disabled={row.status.toString() !== 'PENDING_PAYMENT'}
                    color='info'
                    onClick={() => copy(row.bar_code)}
                  >
                    <CopyAllOutlined/>
                  </Button>
                )}
              </CopyToClipboard>
            </Stack>
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return <BankSlipStatus status={row.status} />
        }
      },
      {
        field: 'customer.name',
        headerName: 'Cliente',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.customer.name
        }
      },
      {
        field: 'value',
        headerName: 'Valor',
        flex: 1,
        minWidth: 150,
        type: 'number',
        renderCell: function render ({ row }) {
          return <NumberField
            options={{
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2
            }}
            value={row.value}
            sx={{ fontSize: '14px' }}
          />
        }
      },
      {
        field: 'due_date',
        headerName: 'Data de Vencimento',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='D/M/YY' value={row.due_date} />
        }
      },
      {
        field: 'value_received',
        headerName: 'Valor Pago',
        flex: 1,
        minWidth: 150,
        type: 'number',
        renderCell: function render ({ row }) {
          return row.value_received
            ? <NumberField
              options={{
                currency: 'BRL',
                style: 'currency',
                minimumFractionDigits: 2
              }}
              value={row.value_received}
              sx={{ fontSize: '14px' }}
            />
            : null
        }
      },
      {
        field: 'cancellation_reason',
        headerName: 'Motivo do Cancelamento',
        flex: 2,
        minWidth: 300
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      }
    ],
    []
  )

  const { register, handleSubmit, control } = useForm<
  BaseRecord,
  HttpError,
  any
  >({
    defaultValues: {
      status: getDefaultFilter('status.text', filters, 'in'),
      q: getDefaultFilter('q', filters, 'eq'),
      store: getDefaultFilter('store.id', filters, 'eq'),
      user: getDefaultFilter('user.id', filters, 'eq')
    }
  })

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} lg={12}>
        <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
          <CardHeader title={t('bank-slip.filter.title')} />
          <CardContent sx={{ pt: 0 }}>
            <Box
              component="form"
              sx={{ display: 'flex', flexDirection: 'column' }}
              autoComplete="off"
              onSubmit={handleSubmit(search)}
            >
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <Autocomplete
                        options={[]}
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        isOptionEqualToValue={(
                          option,
                          value
                        ) => {
                          return (
                            option === value
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              'bank-slip.filter.status.label'
                            )}
                            placeholder={t(
                              'bank-slip.filter.status.placeholder'
                            )}
                            margin="normal"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="right">
                  <Button type="submit" variant="contained">
                    {t('bank-slip.filter.submit')}
                  </Button>
                </Stack>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid> */}
      <Grid item xs={12} lg={12}>
        <List headerProps={{
          title: <Typography variant="h5">Lista de Boletos</Typography>
        }}>
          <DataGrid
            disableColumnFilter
            {...dataGridProps}
            columns={columns}
            autoHeight
            pagination
            paginationMode='server'
            density='compact'
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              filterPanel: {
                linkOperators: [GridLinkOperator.And],
                columnsSort: 'asc',
                filterFormProps: {
                  linkOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  },
                  deleteIconProps: {
                    sx: {
                      '& .MuiSvgIcon-root': { color: '#d32f2f' }
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-filterForm': { p: 2 },
                  '& .MuiDataGrid-filterForm:nth-child(even)': {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                  },
                  '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                }
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibilityModel(newModel)
              handleSaveColumnVisibility({
                tableId,
                userId: user?.id ?? '',
                column_visibility: newModel
              })
            }}
            localeText={DatagridLanguage()}
          />
        </List>
      </Grid>
    </Grid>
  )
}
