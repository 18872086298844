import React from 'react'
import { useLink, TitleProps } from '@refinedev/core'
import { Box, Button } from '@mui/material'

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const Link = useLink()

  return (
    <Button fullWidth variant="text" disableRipple>
      <Link to="/">
        {collapsed
          ? (
            <img src="/images/logo/icon.min.png" alt="Logo Minimizada Bra Consultoria" width="50px" />
          )
          : (
            <Box sx={{
              paddingTop: '20px'
            }}>
              <img src="/images/logo/icon.png" alt="Logo Bra Consultoria" width="140px" />
            </Box>
          )}
      </Link>
    </Button>
  )
}
