import { DocumentScanner } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { GridColumns, DataGrid } from '@mui/x-data-grid'
import { HttpError, useInvalidate } from '@refinedev/core'
import { DateField, List, RefreshButton, useDataGrid } from '@refinedev/mui'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { useMemo, useState } from 'react'

export const ReportHistorySingle = ({ id }: { id: string }) => {
  const invalidate = useInvalidate()
  const [isLoading, setIsLoading] = useState(false)

  const { dataGridProps: dataGridPropsReport } = useDataGrid<any,
  HttpError
  >({
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    resource: 'reportsGenerateHistoryByCustomer',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'url',
            'createdAt',
            {
              createdBy: ['id, name']
            },
            {
              customer: [
                'id',
                'name'
              ]
            }
          ]
        }
      ],
      operation: 'reportsGenerateHistoryByCustomer',
      variables: {
        id: {
          value: id,
          type: 'String',
          required: true
        }
      }
    }
  })

  async function generateDocx (url: string, customer: string, createdAt: any) {
    try {
      setIsLoading(true)
      const response = await fetch(url)
      const blob = await response.blob()
      const fileName = `${customer}${createdAt}`.split('/').pop() || 'downloaded_file'
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = fileName

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      alert('Erro ao gerar o documento, caso persista comunique o suporte de T.I.')
    } finally {
      setIsLoading(false)
    }
  }
  const columnsReport = useMemo<GridColumns<any>>(
    () => [
      {
        field: 'url',
        headerName: 'Relatório',
        flex: 2,
        renderCell: function render ({ row }) {
          return row.url
            ? (
              <LoadingButton
                loading={isLoading}
                onClick={() => generateDocx(row.url, row.customer.name, row.createdAt)}
                loadingPosition="end"
                sx={{ margin: 0, padding: 0 }}
                color='inherit'
                variant='text'
                size='small'
                startIcon={<DocumentScanner color='info' /> }
              />
            )
            : 'S/R'
        }
      },
      {
        field: 'customer.name',
        headerName: 'Prefeitura',
        flex: 2,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer.name
        }
      },
      {
        field: 'createdAt',
        headerName: 'Gerado em',
        flex: 2,
        type: 'date',
        minWidth: 200,
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy',
        headerName: 'Gerado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      }
    ],
    []
  )

  const handleInvalidate = () => {
    invalidate({
      resource: 'reportsGenerateHistory',
      invalidates: ['all']
    })
  }

  return (
    <List
      breadcrumb={null}
      headerProps={{
        title: (<>
          <Typography variant="h5">Histórico de Relatórios Gerados</Typography>
          <Typography variant="subtitle1">Visão Geral de todas as prefeituras.</Typography>
        </>),
        action: <RefreshButton onClick={handleInvalidate} />
      }}>
      <DataGrid
        {...dataGridPropsReport}
        columns={columnsReport}
        disableColumnFilter
        autoHeight
        pagination
        paginationMode='server'
        density='compact'
        localeText={DatagridLanguage()}
      />
    </List>
  )
}
