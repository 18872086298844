import React, { useState } from 'react'
import { Backdrop, Box, Card, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Stack, Typography, useTheme } from '@mui/material'
import { IFileServer } from 'interfaces'
import { styled } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Close, Download } from '@mui/icons-material'
import { Document, Page } from 'react-pdf'
import { DateField } from '@refinedev/mui'

const ThumbnailImage = styled('img')(({ theme }: { theme: Theme }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  maxHeight: '150px',
  maxWidth: '150px',
  marginLeft: 'auto',
  marginRight: 'auto',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  '&:hover': {
    transform: 'scale(1.05, 1.05)',
    boxShadow: theme.shadows[4]
  }
}))

const ImagesInGrid = ({ files }) => {
  const theme = useTheme()
  const [openFileModal, setOpenFileModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<IFileServer | null>(null)

  const handleModalOpen = (file: IFileServer) => {
    setSelectedFile(file)
    setOpenFileModal(true)
  }

  const handleFileModalClose = () => {
    setOpenFileModal(false)
  }

  const handleDownload = async (fileUrl: string | undefined) => {
    if (!fileUrl) return
    try {
      const response = await fetch(fileUrl)
      const blob = await response.blob()
      const fileName = fileUrl.split('/').pop() || 'downloaded_file'

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = fileName

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error)
    }
  }

  const getFileThumbnail = (file: IFileServer) => {
    if (file?.mimetype?.includes('image')) {
      return file.file_url
    } else if (file?.mimetype?.includes('pdf')) {
      return '/images/extensions/pdf.png'
    } else if (file?.mimetype?.includes('officedocument')) {
      return '/images/extensions/word.png'
    } else {
      return '/images/extensions/doc.png'
    }
  }

  function renderSwitch () {
    switch (true) {
      case ['image', 'image/png', 'image/jpg', 'image/jpeg'].includes(selectedFile?.mimetype ?? ''):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <img
                  src={selectedFile?.file_url}
                  srcSet={selectedFile?.file_url}
                  alt={selectedFile?.file_name}
                  loading="lazy"
                  style={{
                    maxHeight: '900px',
                    maxWidth: '100%'
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        )
      case selectedFile?.mimetype?.includes('application/pdf'):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <Document
                  file={selectedFile?.file_url}
                  loading={'Carregando PDF, por favor aguarde um momento...'}
                >
                  <Page
                    pageNumber={1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="pdf-page"
                  />
                </Document>
              </Card>
            </Grid>
          </Grid>
        )
      case selectedFile?.mimetype?.includes('officedocument'):
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <iframe
                  src={`https://docs.google.com/gview?url=${selectedFile?.file_url}&embedded=true`}
                  loading="lazy"
                  style={{
                    maxWidth: '700px',
                    maxHeight: '900px',
                    minHeight: '600px',
                    minWidth: '500px'
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        )
      default:
        return (
          <Grid container justifyContent="center">
            <Grid paddingTop={2} paddingBottom={2} item xs={12} lg={10}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <Document
                  file={selectedFile?.file_url}
                  loading={'Carregando PDF, por favor aguarde um momento...'}
                >
                  <Page
                    pageNumber={1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="pdf-page"
                  />
                </Document>
              </Card>
            </Grid>
          </Grid>
        )
    }
  }

  const Modalfile = () => (
    <Modal
      open={openFileModal}
      onClose={handleFileModalClose}
      closeAfterTransition
      sx={{
        zIndex: 999999999999999
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90%',
          overflowY: 'auto',
          zIndex: 999999999999999
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 0,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            paddingBottom: '25px'
          }}
        >
          <Close
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'red',
              cursor: 'pointer'
            }}
            onClick={handleFileModalClose}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {renderSwitch()}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: 2
              }}
            >
              <Typography variant="h5">{selectedFile?.file_name}</Typography>
              <Typography variant="body2">
                Criado em: <DateField format="D/M/YY" value={selectedFile?.createdAt} />
              </Typography>
              <Box marginTop={2}>
                <Typography variant="h6">Ações:</Typography>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    onClick={() => handleDownload(selectedFile?.file_url)}
                  >
                    <Download />
                  </IconButton>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )

  return (
    <>
      <Modalfile />
      <ImageList cols={6} gap={6}>
        {files.map((file: IFileServer, index: number) => (
          <ImageListItem
            key={index}
            style={{ overflow: 'hidden' }}
          >
            <ThumbnailImage
              theme={theme}
              src={getFileThumbnail(file)}
              srcSet={getFileThumbnail(file)}
              alt={file.file_name}
              loading="lazy"
              onClick={() => handleModalOpen(file)}
            />
            <ImageListItemBar
              title={file.file_name}
              subtitle={file?.createdBy?.name}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}

export default ImagesInGrid
