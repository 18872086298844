import React, { SyntheticEvent, useState, ReactNode } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { ISimec, IFileFilterVariables, IFileServer } from 'interfaces'
import { Person, PersonAdd, PersonOff, Search } from '@mui/icons-material'
import { EnumCustomerStatus, EnumStatus, TypeAttendance } from 'enums'
import ImagesInGrid from 'components/image-list-item'
import SimecTable from 'components/customers/simec'
import Reports from 'components/customers/reports'
import {
  Stack,
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Fab,
  Tab
} from '@mui/material'
import {
  useUpdate,
  useShow,
  HttpError,
  CrudFilters,
  BaseRecord,
  getDefaultFilter,
  CanAccess
} from '@refinedev/core'
import {
  DateField,
  useDataGrid,
  Show,
  RefreshButton,
  List
} from '@refinedev/mui'
import { useForm } from '@refinedev/react-hook-form'
import { MetaQuery } from '@refinedev/core/dist/interfaces'
import { ListContract } from 'components/contracts'
import { ListAttendance } from 'components/attendance/list'

import '../../components/ckeditor/style.css'
import { AttendanceStatus } from 'components/attendance'

export function SimecShow () {
  const date = new Date()
  const currentDateMinusSevenDays = new Date(date.setDate(date.getDate() - 7))
  const [tabSelected, setTabSelected] = useState('1')
  const { mutate: mutateUpdate } = useUpdate<ISimec>()

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTabSelected(newValue)
  }

  const metaData: MetaQuery = {
    fields: [
      'id',
      'code',
      'cnpj',
      'inscricao_estadual',
      'name',
      'mail',
      'mayor_name',
      'mayor_cpf',
      'mayor_phone',
      'mayor_mail',
      'mayor_cellphone',
      'leader_name',
      'leader_mail',
      'leader_cpf',
      'leader_phone',
      'leader_cellphone',
      'county_code',
      'county_name',
      'state_code',
      'state_abbreviation',
      'state_name',
      'region_abbreviation',
      'attendance_status',
      'region_code',
      'region_name',
      'microregion_code',
      'microregion_name',
      'mesoregion_code',
      'mesoregion_name',
      'cep',
      'simec_update_date',
      'sigpc_update_date',
      'sigpc_programs_update_date',
      'sigef_update_date',
      'cauc_update_date',
      'cacs_update_date',
      'cae_update_date',
      'pdde_update_date',
      'pnae_update_date',
      'update_in_progress',
      'number',
      'address',
      'status',
      'createdAt',
      'updatedAt',
      'deletedAt',
      {
        contracts: ['id']
      }
    ],
    operation: 'simec'
  }
  const { queryResult, showId } = useShow<ISimec>({
    metaData
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const {
    dataGridProps: files,
    search: searchFiles,
    filters: fileFilters
  } = useDataGrid<IFileServer, HttpError, IFileFilterVariables>({
    pagination: {
      pageSize: 250,
      current: 0
    },
    syncWithLocation: false,
    resource: 'messagesCustomerFiles',
    metaData: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'file_name',
            'mimetype',
            'size',
            'file_url',
            'key',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ],
      variables: {
        customerId: {
          value: showId,
          name: 'customerId',
          required: true
        }
      }
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'asc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { start_date: startDate, end_date: endDate } = params

      filters.push({
        field: 'createdAt',
        operator: 'gte',
        value: startDate ?? currentDateMinusSevenDays
      })

      filters.push({
        field: 'createdAt',
        operator: 'lte',
        value: endDate ?? new Date()
      })

      return filters
    }
  })

  const {
    handleSubmit: handleSubmitFile,
    register: fileRegister,
    formState: { errors: fileErrors }
  } = useForm<BaseRecord, HttpError, IFileFilterVariables>({
    defaultValues: {
      start_date: getDefaultFilter('createdAt', fileFilters, 'gte'),
      end_date: getDefaultFilter('createdAt', fileFilters, 'lte')
    }
  })

  const changeStatus = (status: EnumStatus) => {
    mutateUpdate({
      resource: 'simec',
      invalidates: ['all'],
      id: showId as string,
      values: {
        status
      },
      undoableTimeout: 3000,
      metaData: {
        fields: ['id']
      }
    })
  }

  function statusSwitch (status: string) {
    switch (status) {
      case 'ACTIVED':
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => changeStatus(2)}
            startIcon={<PersonOff />}
          >
            Desabilitar Cliente
          </Button>
        )
      case 'DISABLED':
        return (
          <Button
            variant="contained"
            color="warning"
            onClick={() => changeStatus(0)}
            startIcon={<Person />}
          >
            Alterar Status para PENDENTE
          </Button>
        )
      default:
        return (
          <Button
            variant="contained"
            color="success"
            onClick={() => changeStatus(1)}
            startIcon={<PersonAdd />}
          >
            Habilitar Cliente
          </Button>
        )
    }
  }

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Show
            isLoading={isLoading}
            headerProps={{
              title: (
                <Typography variant="h5">Cliente: {record?.name}</Typography>
              ),
              action: (
                <Stack direction="row" spacing={2}>
                  <RefreshButton meta={metaData} recordItemId={showId} />
                  <CanAccess resource="customers" action="create">
                    {statusSwitch(record?.status?.toString() ?? '')}
                  </CanAccess>
                </Stack>
              )
            }}
          >
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  CNPJ
                </Typography>
                <Typography variant="body2">{record?.cnpj}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Inscrição Estadual
                </Typography>
                <Typography variant="body2">
                  {record?.inscricao_estadual
                    ? record?.inscricao_estadual
                    : 'Não Possui'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Município
                </Typography>
                <Typography variant="body2">
                  {record?.county_code} - {record?.county_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Estado
                </Typography>
                <Typography variant="body2">
                  {record?.state_code} - {record?.state_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Região
                </Typography>
                <Typography variant="body2">
                  {record?.region_code} - {record?.region_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Micro-Região
                </Typography>
                <Typography variant="body2">
                  {record?.microregion_code} - {record?.microregion_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Meso-Região
                </Typography>
                <Typography variant="body2">
                  {record?.mesoregion_code} - {record?.mesoregion_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  CEP
                </Typography>
                <Typography variant="body2">{record?.cep}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Endereço
                </Typography>
                <Typography variant="body2">
                  {record?.address} - {record?.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Criado em
                </Typography>
                <Typography variant="body2">
                  <DateField format="HH:mm D/M/YY" value={record?.createdAt} />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Atualizado em
                </Typography>
                <Typography variant="body2">
                  <DateField format="HH:mm D/M/YY" value={record?.updatedAt} />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Status de atendimento
                </Typography>
                <Typography variant="body2">
                  {record?.attendance_status
                    ? (
                      <AttendanceStatus status={record?.attendance_status} />
                    )
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Status do contrato
                </Typography>
                <Typography
                  color={
                    (record?.contracts ?? []).length > 0 ? 'green' : 'GrayText'
                  }
                  variant="body1"
                >
                  {(record?.contracts ?? []).length > 0
                    ? 'Possui contrato no sistema'
                    : 'Sem contrato no sistema'}
                </Typography>
              </Grid>
              {record?.deletedAt
                ? (
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant="body1" fontWeight="bold">
                    Deletado em
                    </Typography>
                    <Typography variant="body2">
                      <DateField
                        format="HH:mm D/M/YY"
                        value={record?.deletedAt}
                      />
                    </Typography>
                  </Grid>
                )
                : null}
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="body1" fontWeight="bold">
                  Status
                </Typography>
                <Typography variant="body2">
                  {EnumCustomerStatus[record?.status || '']}
                </Typography>
              </Grid>
            </Grid>
          </Show>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <TabContext value={tabSelected}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <TabList
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="secondary"
              >
                <Tab label="Atendimentos" value="1" />
                <Tab label="SIMEC" value="2" />
                <Tab label="Arquivos" value="3" />
                <Tab label="Relatórios" value="4" />
                <Tab label="Contratos" value="5" />
              </TabList>
            </Stack>
            <TabPanel value="1">
              <ListAttendance
                defaultValue={record?.attendance_status}
                type={TypeAttendance.PREFECTURES}
              />
            </TabPanel>
            <TabPanel value="2">
              <Grid item xs={12}>
                <SimecTable cityHall={record} />
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <List
                headerProps={{
                  title: <Typography variant="h5">Arquivos</Typography>,
                  action: (
                    <Grid
                      spacing={2}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <TextField
                          {...fileRegister('start_date', {
                            required: 'Data Inicial é obrigatória!',
                            validate: (value) => {
                              const invalid =
                                new Date(value).getTime() >=
                                new Date().getTime()
                              if (invalid) {
                                return 'A data não pode ser superior à data atual!'
                              }
                              return true
                            },
                            valueAsDate: true
                          })}
                          error={!!fileErrors?.start_date}
                          helperText={
                            fileErrors.start_date?.message as ReactNode
                          }
                          label="Data Inicial"
                          name="start_date"
                          margin="normal"
                          type="date"
                          color="success"
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                De
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          {...fileRegister('end_date', {
                            required: 'Data Final é obrigatória!',
                            validate: (value) => {
                              const invalid =
                                new Date(value).getTime() >=
                                new Date().getTime()
                              if (invalid) {
                                return 'A data não pode ser superior à data atual!'
                              }
                              return true
                            },
                            valueAsDate: true
                          })}
                          error={!!fileErrors?.end_date}
                          helperText={fileErrors.end_date?.message as ReactNode}
                          label="Data Final"
                          name="end_date"
                          margin="normal"
                          type="date"
                          color="success"
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Até
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Fab
                            onClick={handleSubmitFile(searchFiles)}
                            size="medium"
                            color="info"
                            aria-label="search"
                          >
                            <Search />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }}
                breadcrumb={<></>}
              >
                <Grid item xs={12}>
                  <ImagesInGrid files={files.rows} />
                </Grid>
              </List>
            </TabPanel>
            <TabPanel value="4">
              <Reports customer={record} />
            </TabPanel>
            <TabPanel value="5">
              <ListContract />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  )
}
