export enum EnumResources {
  REGISTER = 'Cadastros',
}

export enum EnumOperations {
  NOTHING = 'Nenhum',
  READOWN = 'Ler proprio',
  READALL = 'Ler todos',
  CREATEOWN = 'Criar proprio',
  CREATEALL = 'Criar todos',
  UPDATEOWN = 'Atualizar proprio',
  UPDATEALL = 'Atualizar todos',
  DELETEOWN = 'Apagar proprio',
  DELETEALL = 'Apagar todos',
  ALL = 'Todos os acessos'
}

export enum EnumStatus {
  PENDING,
  ACTIVED,
  DISABLED,
  FINISHED,
}

export enum EnumCampaigns {
  EMAIL = 'EMAIL',
}

export enum EnumCustomerStatus {
  PENDING = 'PENDENTE',
  ACTIVED = 'ATIVO',
  DISABLED = 'DESATIVO',
}

export enum EnumAction {
  Manage = 'manage',
  Create = 'create',
  Read = 'list',
  Update = 'edit',
  Delete = 'delete',
  Show = 'show',
}

export enum EnumUserProfile {
  USER = 'Usuario',
  SIMEC = 'Simec',
  CONSULTANT = 'Consultor',
  FINANCIAL = 'Financeiro',
  LEGAL = 'Jurídico',
  CLERK = 'Atendente',
  ADMIN = 'Administrador',
}

export enum EnumTypeTraining {
  ONLINE = 'Online',
  PRESENTIAL = 'Presencial',
  BOTH = 'Ambos',
}

export enum CodeBankSlipDiscount {
  NAOTEMDESCONTO = 'Não tem desconto',
  VALORFIXODATAINFORMADA = 'Valor fixo conforme data informada',
  PERCENTUALDATAINFORMADA = 'Percentual conforme data informada',
}

export enum CodeBankSlipFine {
  NAOTEMMULTA = 'Não tem multa',
  VALORFIXO = 'Valor fixo',
  PERCENTUAL = 'Percentual',
}

export enum CodeBankSlipInterest {
  ISENTO = 'Isento',
  VALORDIA = 'Valor dia',
  TAXAMENSAL = 'Taxa mensal',
}

export enum StatusBankSlip {
  PENDING_API = 'Pendente API',
  PENDING_PAYMENT = 'Pagamento Pendente',
  PAYMENT_CONFIRMED = 'Pagamento Confirmado',
  OVERDUE = 'Boleto Vencido',
  ANALYSIS = 'Em Análise',
  CANCELED = 'Boleto Cancelado',
  EXPIRED = 'Boleto Expirado',
}

export enum BankSlipCancellationReason {
  ACERTOS = 'Cancelado por acerto',
  APEDIDODOCLIENTE = 'Cancelado a pedido do cliente',
  PAGODIRETOAOCLIENTE = 'Cancelado por ter sido pago diretamente',
  SUBSTITUICAO = 'Cancelado por substituição',
}

export enum EnumKindOfPerson {
  PHYSICS = 'FISICA',
  LEGAL = 'JURIDICA',
}

export enum TypeCustomer {
  PHYSICAL_PERSON = 'Pessoa Fisica',
  LEGAL_PERSON = 'Pessoa Juridica',
  CITY_HALL = 'Prefeitura',
}

export enum TypeLesson {
  VIDEO,
  PDF,
  HTML,
}

export enum TypeContract {
  MONTHLY = 'Mensal',
  FIXED = 'Fixo',
}

export enum ModelContract {
  SPEAKER = 'Palestra',
  ADVISORY = 'Consultoria',
  TRAINING = 'Formação',
}

export enum StatusAttendance {
  IN_PROGRESS = 'Em Progresso',
  URGENT = 'Urgente',
  FINISHED = 'Finalizado',
  PENDING = 'Não Atendeu',
}

export enum TypeAttendance {
  COMMERCIAL,
  PREFECTURES,
}

export enum SendingEmail {
  COMMERCIAL = 'comercial@braconsultoria.com',
  PERSONAL = 'gilsonsantoss@gmail.com',
}

export enum TypeWhatsApp {
  USER = 'Usuario',
  CAMPAIGN = 'Campanha',
  ROBOT = 'Automação',
}
