import {
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@mui/material'
import { EditMayorAndLeader } from './edit-mayor-leader'
import { useModalForm } from '@refinedev/react-hook-form'
import { Edit } from '@mui/icons-material'
import { useParsed } from '@refinedev/core'

const SimecTable = ({ cityHall }) => {
  const { id } = useParsed()
  const editContractModal = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'simec',
      id,
      metaData: {
        fields: [
          'mayor_name',
          'mayor_cpf',
          'mayor_mail',
          'mayor_phone',
          'mayor_cellphone',
          'leader_name',
          'leader_cpf',
          'leader_mail',
          'leader_phone',
          'leader_cellphone'
        ]
      }
    }
  })

  const {
    modal: { show: showEdit }
  } = editContractModal

  return (
    <>
      <EditMayorAndLeader {...editContractModal} />
      <Stack spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cargo</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>E-Mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Celular</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">Prefeito</TableCell>
                <TableCell>{cityHall?.mayor_name ? cityHall?.mayor_name : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.mayor_cpf ? cityHall?.mayor_cpf : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.mayor_mail ? cityHall?.mayor_mail : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.mayor_phone ? cityHall?.mayor_phone : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.mayor_cellphone ? cityHall?.mayor_cellphone : 'Não Informado'}</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">Secretário da Educação</TableCell>
                <TableCell>{cityHall?.leader_name ? cityHall?.leader_name : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.leader_cpf ? cityHall?.leader_cpf : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.leader_mail ? cityHall?.leader_mail : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.leader_phone ? cityHall?.leader_phone : 'Não Informado'}</TableCell>
                <TableCell>{cityHall?.leader_cellphone ? cityHall?.leader_cellphone : 'Não Informado'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack paddingTop={2} justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => showEdit()}
          startIcon={
            <Edit />
          }>Editar</Button>
      </Stack>
    </>
  )
}

export default SimecTable
