import { Card, CardContent, Typography, CardActions, Button, Stack, Grid } from '@mui/material'

export const WidgetStatusAttendances = ({ status, qty, color, text, handleClick, isSelected, disabled }) => {
  return (
    <Card
      sx={{
        borderColor: isSelected ? color : 'transparent',
        borderWidth: 1,
        borderStyle: 'solid'
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Status do Atendimento
        </Typography>
        <Typography variant="h5" component="div" color={color}>
          {status}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1, mt: 0.7 }} color="text.primary">
          Quantidade: {qty}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={1}>
              <Button variant='contained' color='info' disabled={disabled} onClick={handleClick} size="small">{isSelected ? 'Remover Filtro' : 'Filtrar'}</Button>
            </Stack>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
