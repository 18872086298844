import React, { useMemo, useState, useEffect } from 'react'
import { ISimec, ICustomerFilterVariables, IMesoregion, IState, IRegion } from 'interfaces'
import { getBackgroundColor, getHoverBackgroundColor } from 'contexts'
import { FileDownload, PersonAddAlt, PersonAddDisabled, Save, Visibility } from '@mui/icons-material'
import { EnumCustomerStatus, EnumStatus } from 'enums'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import states from 'data/states.json'
import regions from 'data/regions.json'
import { axiosInstance } from 'config/axios'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography, Button, Grid, Card, CardHeader, CardContent, Autocomplete, TextField, Box, Theme, Collapse } from '@mui/material'
import { GridRowId, GridColumns, DataGrid, GridLinkOperator, GridToolbar, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { useTranslate, useGetIdentity, useUpdateMany, useInvalidate, HttpError, CrudFilters, BaseRecord, getDefaultFilter } from '@refinedev/core'
import { ShowButton, DateField, useDataGrid, List, useAutocomplete } from '@refinedev/mui'
import { Controller } from 'react-hook-form'
import { useForm } from '@refinedev/react-hook-form'
import { ReportHistory } from 'components/reports'
import CaptchaBalance from 'components/reports/balance'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function SimecList () {
  const tableId = 'simec'

  const t = useTranslate()
  const [region, setRegion] = useState<IRegion[] | null>(null)
  const [state, setState] = useState<IState | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [mesoregions, setMesoregions] = useState<IMesoregion[]>()
  const { data: user } = useGetIdentity<{
    id: string;
    name: string;
    profile: any;
  }>()

  const [isCollapsed, setIsCollapsed] = useState(true)

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [seeNotCustomers, setSeeNotCustomers] = useState<boolean>(false)
  const { mutate: mutateMany } = useUpdateMany<number[]>()
  const invalidate = useInvalidate()

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    code: true,
    name: true,
    cnpj: true,
    county_name: true,
    state_abbreviation: true,
    region_name: true,
    microregion_name: true,
    mesoregion_name: true,
    cep: true,
    address: true,
    mayor_name: false,
    mayor_mail: false,
    mayor_phone: false,
    mayor_cellphone: false,
    leader_name: false,
    leader_mail: false,
    leader_phone: false,
    leader_cellphone: false,
    status: true,
    createdAt: true,
    updatedAt: false,
    deletedAt: false
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<ISimec>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hide: isClicked,
        hideable: isClicked,
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} />
            </Stack>
          )
        }
      },
      {
        field: 'code',
        headerName: 'Código Municipio',
        flex: 1,
        minWidth: 150,
        type: 'number'
      },
      {
        field: 'name',
        headerName: 'Razão Social',
        flex: 2,
        minWidth: 200
      },
      {
        field: 'cnpj',
        headerName: 'CNPJ',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'county_name',
        headerName: 'Prefeitura',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'state_abbreviation',
        headerName: 'Estado',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'region_name',
        headerName: 'Região',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'microregion_name',
        headerName: 'Micro Região',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'mesoregion_name',
        headerName: 'Meso Região',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'cep',
        headerName: 'CEP',
        flex: 1,
        minWidth: 150,
        hide: false,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'address',
        headerName: 'Endereço',
        flex: 2,
        minWidth: 300,
        hide: false,
        getApplyQuickFilterFn: undefined,
        renderCell: function render ({ row }) {
          return `${row.address} ${row.number}`
        }
      },
      {
        field: 'mayor_name',
        headerName: 'Prefeito',
        flex: 1,
        minWidth: 100,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'mayor_mail',
        headerName: 'Prefeito E-Mail',
        flex: 1,
        minWidth: 100,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'mayor_phone',
        headerName: 'Prefeito Tel.',
        flex: 1,
        minWidth: 100,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'mayor_cellphone',
        headerName: 'Prefeito Cel.',
        flex: 1,
        minWidth: 100,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'leader_name',
        headerName: 'Secretário',
        flex: 1,
        minWidth: 100,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'leader_mail',
        headerName: 'Secretário E-Mail',
        flex: 2,
        minWidth: 200,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'leader_phone',
        headerName: 'Secretário Tel.',
        flex: 2,
        minWidth: 200,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'leader_cellphone',
        headerName: 'Secretário Cel.',
        flex: 2,
        minWidth: 200,
        hide: true,
        getApplyQuickFilterFn: undefined
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: Object.values(EnumCustomerStatus).map(obj => {
          return {
            value: Object.values(EnumCustomerStatus).indexOf(obj),
            label: obj
          }
        }),
        renderCell: function render ({ row }) {
          return EnumCustomerStatus[row.status]
        }
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.deletedAt ? <DateField format='HH:mm D/M/YY' value={row.deletedAt} /> : ''
        }
      }
    ],
    []
  )

  const fields = [
    'total',
    {
      nodes: [
        'id',
        'code',
        'cnpj',
        'inscricao_estadual',
        'name',
        'mail',
        'mayor_name',
        'mayor_cpf',
        'mayor_phone',
        'mayor_mail',
        'mayor_cellphone',
        'leader_name',
        'leader_mail',
        'leader_cpf',
        'leader_phone',
        'leader_cellphone',
        'county_code',
        'county_name',
        'state_code',
        'state_abbreviation',
        'state_name',
        'region_abbreviation',
        'region_name',
        'microregion_name',
        'mesoregion_name',
        'cep',
        'number',
        'address',
        'status',
        'createdAt',
        'updatedAt',
        'deletedAt'
      ]
    }
  ]

  const { dataGridProps, search, filters } = useDataGrid<ISimec,
  HttpError,
  ICustomerFilterVariables
  >({
    resource: 'simecCustomers',
    pagination: {
      pageSize: 25,
      current: 0
    },
    syncWithLocation: false,
    filters: {
      permanent: [{
        field: 'status',
        value: `${+seeNotCustomers}`,
        operator: 'ne'
      }]
    },
    metaData: {
      fields
    },
    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { name, status, region_name: regionName, mesoregion_name: mesoregionName, microregion_name: microregionName, state_name: stateName } = params

      filters.push({
        field: 'name',
        operator: 'in',
        value: name && name.length > 0 ? name?.toString() : undefined
      })

      filters.push({
        field: 'status',
        operator: 'in',
        value: status && status.length > 0 ? status?.toString() : undefined
      })

      filters.push({
        field: 'region_name',
        operator: 'in',
        value: regionName && regionName.length > 0 ? regionName?.toString() : undefined
      })

      filters.push({
        field: 'state_name',
        operator: 'in',
        value: stateName && stateName.length > 0 ? stateName?.toString() : undefined
      })

      filters.push({
        field: 'mesoregion_name',
        operator: 'in',
        value: mesoregionName && mesoregionName.length > 0 ? mesoregionName?.toString() : undefined
      })

      filters.push({
        field: 'microregion_name',
        operator: 'in',
        value: microregionName && microregionName.length > 0 ? microregionName?.toString() : undefined
      })

      return filters
    }
  })

  const { handleSubmit, control } = useForm<
  BaseRecord,
  HttpError,
  ICustomerFilterVariables
  >({
    defaultValues: {
      status: getDefaultFilter('status', filters, 'in'),
      region_name: getDefaultFilter('region_name', filters, 'in'),
      state_name: getDefaultFilter('state_name', filters, 'in'),
      mesoregion_name: getDefaultFilter('mesoregion_name', filters, 'in'),
      microregion_name: getDefaultFilter('microregion_name', filters, 'in'),
      name: getDefaultFilter('name', filters, 'in')
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      const dataMeso = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state?.id}/mesorregioes`)
      const jsonMeso = await dataMeso.json()
      setMesoregions(jsonMeso)
    }
    fetchData()
      .catch(console.error)
  }, [state])

  const handleAdd = () => {
    mutateMany({
      resource: 'simec',
      ids: selectionModel,
      values: { status: EnumStatus.ACTIVED },
      undoableTimeout: 3000
    }, {
      onSuccess: () => {
        setSelectionModel([])
        setIsClicked(false)
        handleInvalidate()
      }
    })
  }

  const handleInvalidate = () => {
    invalidate({
      resource: 'simecCustomers',
      invalidates: ['all']
    })
  }

  const handleSelectBox = (value: boolean) => {
    setIsClicked(value)
    setSeeNotCustomers(value)
  }

  async function generateXlsx () {
    setLoading(true)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    const { data } = await axiosInstance.post('simec/xlsx', {
      filters
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    })
    if (data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'prefeituras.xlsx')
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      setLoading(false)
    }
  }

  const { autocompleteProps: customerProps } = useAutocomplete<ISimec>({
    resource: 'simecCustomers',
    metaData: {
      fields: [
        {
          nodes: ['id', 'name']
        }
      ]
    },
    pagination: {
      current: 1,
      pageSize: 25
    },
    sort: [
      {
        field: 'name',
        order: 'asc'
      }
    ],
    // filters: [
    //   {
    //     field: 'region_name',
    //     operator: 'in',
    //     value: region && region.length > 0 ? region?.toString() : undefined
    //   },
    //   {
    //     field: 'state_name',
    //     operator: 'in',
    //     value: state
    //   },
    //   {
    //     field: 'mesoregion_name',
    //     operator: 'in',
    //     value: mesoregions && mesoregions.length > 0 ? mesoregions?.toString() : undefined
    //   }
    // ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'contains',
        value: `%${value}%`
      }
    ]
  })

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <Button color='info' onClick={handleCollapse} variant='contained' >{isCollapsed ? 'Abrir ' : 'Fechar '}Histórico de relatórios gerados</Button>
        </Box>
        <Collapse in={!isCollapsed}>
          <ReportHistory />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <List headerProps={{
          title: <Typography variant="h5">{!isClicked ? `Lista de ${seeNotCustomers ? 'Não' : ''} Clientes ${!seeNotCustomers ? ' - Ativos e Desativados' : ''}` : 'Adesão de Clientes'}</Typography>
        }}
        headerButtons={
          <Stack direction="row" spacing={2}>
            {
              isClicked
                ? <>
                  <Button variant='contained' color='error' onClick={() => handleSelectBox(false)} startIcon={<PersonAddDisabled />}>Cancelar</Button>
                  <Button variant='contained' color='success' onClick={() => handleAdd()} startIcon={<Save />}>Salvar</Button>
                </>
                : <>
                  <LoadingButton
                    variant='contained'
                    color='info'
                    onClick={generateXlsx}
                    loading={loading}
                    startIcon={<FileDownload />}
                  >Gerar Excel</LoadingButton>
                  <Button variant='contained' color='info' onClick={() => setSeeNotCustomers(!seeNotCustomers)} startIcon={<Visibility />}>Visualizar {!seeNotCustomers ? 'Não' : ''} Clientes</Button>
                  <Button variant='contained' color='success' onClick={() => handleSelectBox(true)} startIcon={<PersonAddAlt />}>Adicionar clientes</Button>
                </>
            }
          </Stack>
        }
        >
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <CardHeader title={t('customer.filter.title')} />
                <CardContent sx={{ pt: 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Controller
                        control={control}
                        name="region_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            options={regions}
                            onChange={(_, value) => {
                              setRegion(value)
                              field.onChange(
                                value?.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.region.label'
                                )}
                                placeholder={t(
                                  'customer.filter.region.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Controller
                        control={control}
                        name="state_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            groupBy={(option) => option?.regiao.nome || ''}
                            multiple
                            options={states}
                            onChange={(_, value) => {
                              if (value.length === 1) {
                                setState(value[0])
                              } else setState(null)
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.state.label'
                                )}
                                placeholder={t(
                                  'customer.filter.state.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Controller
                        control={control}
                        name="mesoregion_name"
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disabled={!state}
                            {...field}
                            options={mesoregions || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.mesoregion.label'
                                )}
                                placeholder={t(
                                  'customer.filter.mesoregion.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <Autocomplete
                            {...customerProps}
                            {...field}
                            multiple
                            onChange={(_, value: any) => {
                              field.onChange(
                                value.map((p) => p.name ?? p)
                              )
                            }}
                            getOptionLabel={(item: any) => {
                              return item.name
                                ? item.name
                                : customerProps?.options?.find(
                                  (p) =>
                                    p.id.toString() ===
                              item.toString()
                                )?.name ?? ''
                            }}
                            isOptionEqualToValue={(option: any, value: any) => {
                              return value === undefined || option.id === value.id
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.city_​​hall.label'
                                )}
                                placeholder={t(
                                  'customer.filter.city_​​hall.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="right">
                        <Button type="button" variant="contained" onClick={handleSubmit(search)}>
                          {t('customer.filter.submit')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  width: '100%',
                  '& .super-app-theme--ACTIVED': {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                    '&:hover': {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.success.main,
                          theme.palette.mode
                        )
                    }
                  },
                  '& .super-app-theme--DISABLED': {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                    '&:hover': {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.error.main, theme.palette.mode
                        )
                    }
                  }
                }}
              >
                <DataGrid
                  {...dataGridProps}
                  keepNonExistentRowsSelected
                  disableColumnFilter
                  columns={columns}
                  filterModel={undefined}
                  density='compact'
                  autoHeight
                  pagination
                  paginationMode='server'
                  checkboxSelection={isClicked}
                  disableSelectionOnClick={isClicked}
                  getRowClassName={({ row }) => `super-app-theme--${row.status?.toString()}`}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterLogicOperator: GridLinkOperator.Or
                      }
                    }
                  }}
                  components={{
                    Toolbar: GridToolbar
                  }}
                  componentsProps={{
                    filterPanel: {
                      linkOperators: [GridLinkOperator.And],
                      columnsSort: 'asc',
                      filterFormProps: {
                        linkOperatorInputProps: {
                          variant: 'outlined',
                          size: 'small'
                        },
                        columnInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' }
                        },
                        operatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' }
                        },
                        valueInputProps: {
                          InputComponentProps: {
                            variant: 'outlined',
                            size: 'small'
                          }
                        },
                        deleteIconProps: {
                          sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' }
                          }
                        }
                      },
                      sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-child(even)': {
                          backgroundColor: (theme: Theme) =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                        },
                        '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                      }
                    },
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(ids) => {
                    setSelectionModel(ids)
                  }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel)
                    handleSaveColumnVisibility({
                      tableId,
                      userId: user?.id ?? '',
                      column_visibility: newModel
                    })
                  }}
                  localeText={DatagridLanguage()}
                />
              </Box>
            </Grid>
          </Grid>
        </List>
      </Grid>
      <Grid item xs={12}>
        <CaptchaBalance />
      </Grid>
    </Grid>
  )
}
