import React from 'react'
import { IBankSlips } from 'interfaces'
import { Clear, PictureAsPdfOutlined } from '@mui/icons-material'
import { generatePDF } from 'components/bank-slips/generate-pdf'
import { ModalCancel } from 'components/bank-slips/cancel'
import {
  Grid,
  Typography,
  Stack,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { CanAccess, useShow } from '@refinedev/core'
import { Show, RefreshButton, DateField } from '@refinedev/mui'
import { useModalForm } from '@refinedev/react-hook-form'

export function BankSlipShow () {
  const meta = {
    fields: [
      'id',
      'number_ticket',
      'bar_code',
      'due_date',
      'cancellation_days',
      'value',
      'createdAt',
      'status',
      {
        createdBy: ['id', 'name']
      },
      {
        canceledBy: ['id', 'name']
      },
      {
        bank_slip_payer: [
          'cpf_cnpj',
          'type_person',
          'name',
          'address',
          'city',
          'state_abbreviation',
          'zip_code'
        ]
      },
      {
        bank_slip_interest: ['code_interest', 'date', 'percentage', 'value']
      },
      {
        bank_slip_fine: ['code_fine', 'date', 'percentage', 'value']
      },
      {
        bank_slip_discount1: ['code_discount', 'date', 'percentage', 'value']
      },
      {
        bank_slip_discount2: ['code_discount', 'date', 'percentage', 'value']
      },
      {
        bank_slip_discount3: ['code_discount', 'date', 'percentage', 'value']
      }
    ]
  }
  const { queryResult, showId } = useShow<IBankSlips>({
    meta
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const cancelModal = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'bankSlip',
      metaData: {
        operation: 'bankSlip',
        fields: ['id'],
        variables: {
          id: {
            name: 'id',
            value: showId,
            required: true
          }
        }
      }
    }
  })

  const {
    modal: { show: showModal }
  } = cancelModal

  return (
    <>
      <ModalCancel {...cancelModal} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Show
            isLoading={isLoading}
            headerProps={{
              title: <Typography variant="h5">Boleto: {record?.id}</Typography>,
              action: (
                <Stack direction="row" spacing={2}>
                  <RefreshButton meta={meta} recordItemId={showId} />
                  {record?.status.toString() !== 'PENDING_API'
                    ? (
                      <Button
                        disabled={!record?.bar_code}
                        variant="contained"
                        color="info"
                        onClick={() =>
                          generatePDF(
                            record?.id || '',
                            record?.bank_slip_payer?.name || ''
                          )
                        }
                      >
                        <PictureAsPdfOutlined /> Gerar PDF
                      </Button>
                    )
                    : null}
                  {record?.status.toString() === 'PENDING_PAYMENT'
                    ? (
                      <CanAccess resource="customers" action="create">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => showModal()}
                        >
                          <Clear /> Cancelar Boleto
                        </Button>
                      </CanAccess>
                    )
                    : null}
                </Stack>
              )
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Data de Vencimento
                </Typography>
                <Typography variant="body2">
                  <DateField format="HH:mm D/M/YY" value={record?.due_date} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Dias Úteis para cancelamento após vencimento
                </Typography>
                <Typography variant="body2">
                  {record?.cancellation_days}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Valor do boleto
                </Typography>
                <Typography variant="body2">
                  {record?.value.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Situação
                </Typography>
                <Typography variant="body2">{record?.status}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Codigo de Barras
                </Typography>
                <Typography variant="body2">{record?.bar_code}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Criado em
                </Typography>
                <Typography variant="body2">
                  <DateField format="HH:mm D/M/YY" value={record?.createdAt} />
                </Typography>
              </Grid>
              {record?.bank_slip_payer?.name
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper>
                        <Stack spacing={2}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Cliente</TableCell>
                                  <TableCell>Cnpj</TableCell>
                                  <TableCell>Endereço</TableCell>
                                  <TableCell>Cidade</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0
                                    }
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {record?.bank_slip_payer?.name}
                                  </TableCell>
                                  <TableCell>
                                    {record?.bank_slip_payer?.cpf_cnpj}
                                  </TableCell>
                                  <TableCell>
                                    {record?.bank_slip_payer?.address} -{' '}
                                    {record?.bank_slip_payer?.state_abbreviation}
                                  </TableCell>
                                  <TableCell>
                                    {record?.bank_slip_payer?.city}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
              {record?.bank_slip_fine?.code_fine &&
              record?.bank_slip_fine?.code_fine !== 'NO_FINE'
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                            Multa
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Codigo
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_fine?.code_fine}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Data
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_fine?.date
                                ? (
                                  <DateField
                                    format="D/M/YY"
                                    value={record?.bank_slip_fine?.date}
                                  />
                                )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Porcentagem
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_fine?.percentage} %
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Valor
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_fine?.value
                                ? record?.bank_slip_fine?.value.toLocaleString(
                                  'pt-BR',
                                  {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2
                                  }
                                )
                                : 'R$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
              {record?.bank_slip_interest?.code_interest &&
              record?.bank_slip_interest?.code_interest !== 'FREE'
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                            Juros
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Codigo
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_interest?.code_interest}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Data
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_interest?.date
                                ? (
                                  <DateField
                                    format="D/M/YY"
                                    value={record?.bank_slip_interest?.date}
                                  />
                                )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Porcentagem
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_interest?.percentage} %
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Valor
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_interest?.value
                                ? record?.bank_slip_interest?.value.toLocaleString(
                                  'pt-BR',
                                  {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2
                                  }
                                )
                                : 'R$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
              {record?.bank_slip_discount1?.code_discount &&
              record?.bank_slip_discount1?.code_discount !== 'NO_DISCOUNT'
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                            Desconto 1
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Codigo
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount1?.code_discount}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Data
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount1?.date
                                ? (
                                  <DateField
                                    format="D/M/YY"
                                    value={record?.bank_slip_discount1?.date}
                                  />
                                )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Porcentagem
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount1?.percentage} %
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Valor
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount1?.value
                                ? record?.bank_slip_discount1?.value.toLocaleString(
                                  'pt-BR',
                                  {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2
                                  }
                                )
                                : 'R$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
              {record?.bank_slip_discount2?.code_discount &&
              record?.bank_slip_discount2?.code_discount !== 'NO_DISCOUNT'
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                            Desconto 2
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Codigo
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount2?.code_discount}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Data
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount2?.date
                                ? (
                                  <DateField
                                    format="D/M/YY"
                                    value={record?.bank_slip_discount2?.date}
                                  />
                                )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Porcentagem
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount2?.percentage} %
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Valor
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount2?.value
                                ? record?.bank_slip_discount2?.value.toLocaleString(
                                  'pt-BR',
                                  {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2
                                  }
                                )
                                : 'R$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
              {record?.bank_slip_discount3?.code_discount &&
              record?.bank_slip_discount3?.code_discount !== 'NO_DISCOUNT'
                ? (
                  <>
                    <Grid paddingTop={4} item xs={12}>
                      <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                            Desconto 3
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Codigo
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount3?.code_discount}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Data
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount3?.date
                                ? (
                                  <DateField
                                    format="D/M/YY"
                                    value={record?.bank_slip_discount3?.date}
                                  />
                                )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Porcentagem
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount3?.percentage} %
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" fontWeight="bold">
                            Valor
                            </Typography>
                            <Typography variant="body2">
                              {record?.bank_slip_discount3?.value
                                ? record?.bank_slip_discount3?.value.toLocaleString(
                                  'pt-BR',
                                  {
                                    currency: 'BRL',
                                    style: 'currency',
                                    minimumFractionDigits: 2
                                  }
                                )
                                : 'R$ 0.00'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )
                : null}
            </Grid>
          </Show>
        </Grid>
      </Grid>
    </>
  )
}
