import { Stack, Grid, Typography, Button } from '@mui/material'
import { DataGrid, GridColumnVisibilityModel, GridColumns } from '@mui/x-data-grid'
import { ShowButton, EditButton, DeleteButton, DateField, List, useDataGrid } from '@refinedev/mui'
import { useModalForm } from '@refinedev/react-hook-form'
import { ModelContract, TypeContract } from 'enums'
import { IContract, IDefaultFilters } from 'interfaces'
import { useEffect, useMemo, useState } from 'react'
import { CreateContract } from './create'
import { EditContract } from './edit'
import { HttpError, useGetIdentity, useParsed } from '@refinedev/core'
import ShowContract from './show'
import { DocumentScanner } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { axiosInstance } from 'config/axios'
import { handleSaveColumnVisibility } from 'components/datagrid'

export const ListContract = () => {
  const tableId = 'contract'

  const { id } = useParsed()
  const [contractId, setContractId] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { dataGridProps } = useDataGrid<IContract,
  HttpError,
  IDefaultFilters
  >({
    meta: {
      operation: 'contracts',
      fields: [
        'total',
        {
          nodes: [
            'id',
            'title',
            'start_date',
            'end_date',
            'start_hour',
            'end_hour',
            'type',
            'model',
            'qty',
            'value_unit',
            'value_total',
            'createdAt',
            'updatedAt',
            'deletedAt',
            {
              files: [
                'id'
              ]
            },
            {
              createdBy: ['id', 'name']
            },
            {
              updatedBy: ['id', 'name']
            }
          ]
        }
      ],
      variables: {
        customerId: {
          type: 'String',
          required: true,
          value: id
        }
      }
    },

    pagination: {
      current: 0,
      pageSize: 25
    },

    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  async function generateDocx (contractId: string, customerName: string) {
    setIsLoading(true)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
    const { data } = await axiosInstance.post('docs/contract', {
      contractId
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    })

    if (data) {
      const blob = new Blob([data], { type: 'application/msword' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${customerName}-${new Date().toLocaleDateString()}.doc`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
      setIsLoading(false)
    }
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    title: true,
    start_date: true,
    end_date: true,
    type: true,
    model: true,
    qty: true,
    value_unit: true,
    value_total: true,
    files: true,
    createdAt: true,
    createdBy: true,
    updatedAt: true,
    updatedBy: true
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<IContract>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 3,
        minWidth: 300,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={isLoading}
                onClick={() => generateDocx(row.id, row.title)}
                loadingPosition="end"
                sx={{ margin: 0, padding: 0 }}
                color='inherit'
                variant='text'
                size='small'
                startIcon={<DocumentScanner color='info' /> }
              />
              <ShowButton hideText recordItemId={row.id} type='button' onClick={() => handleModalOpen(row.id)} />
              <EditButton hideText recordItemId={row.id} resource="contract" onClick={() => showEditContractModal(row.id)} />
              <DeleteButton
                hideText
                recordItemId={row.id}
                resource="contract"
                invalidates={['all']}
              />
            </Stack>
          )
        }
      },
      {
        field: 'title',
        headerName: 'Título',
        flex: 1,
        minWidth: 250
      },
      {
        field: 'start_date',
        headerName: 'Data de Início',
        flex: 1,
        minWidth: 250,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='D/M/YY' value={row.start_date} />
        }
      },
      {
        field: 'end_date',
        headerName: 'Data de Término',
        flex: 1,
        minWidth: 250,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.end_date ? <DateField format='D/M/YY' value={row.end_date} /> : 'Não foi informada'
        }
      },
      {
        field: 'type',
        headerName: 'Tipo',
        flex: 1,
        minWidth: 100,
        renderCell: function render ({ row }) {
          return TypeContract[row.type]
        }
      },
      {
        field: 'model',
        headerName: 'Modelo',
        flex: 1,
        minWidth: 100,
        renderCell: function render ({ row }) {
          return ModelContract[row.model]
        }
      },
      {
        field: 'qty',
        headerName: 'Quantidade',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'value_unit',
        headerName: 'Valor Unit.',
        flex: 1,
        minWidth: 100,
        renderCell: function render ({ row }) {
          return (row.value_unit ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })
        }
      },
      {
        field: 'value_total',
        headerName: 'Valor Total.',
        flex: 1,
        minWidth: 100,
        renderCell: function render ({ row }) {
          return (row.value_total ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })
        }
      },
      {
        field: 'files',
        headerName: 'Anexos',
        flex: 1,
        minWidth: 100,
        filterable: false,
        sortable: false,
        renderCell: function render ({ row }) {
          return row.files?.length ?? 0
        }
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy',
        headerName: 'Criado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'updatedBy',
        headerName: 'Atualizado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.updatedBy?.name
        }
      }
    ],
    []
  )

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const createContractModal = useModalForm({
    refineCoreProps: {
      action: 'create',
      redirect: false,
      invalidates: ['all'],
      resource: 'contract',
      metaData: {
        fields: ['id'],
        operation: 'contract'
      }
    }
  })

  const {
    modal: { show: showContractModal }
  } = createContractModal

  const editContractModal = useModalForm({
    refineCoreProps: {
      action: 'edit',
      redirect: false,
      invalidates: ['all'],
      resource: 'contract',
      metaData: {
        fields: [
          'id',
          'title',
          'start_date',
          'end_date',
          'start_hour',
          'speaker_name',
          'speaker_cpf',
          'end_hour',
          'type',
          'model',
          'qty',
          'value_unit',
          'value_total',
          'conditions',
          'notes'
        ]
      }
    }
  })

  const {
    modal: { show: showEditContractModal }
  } = editContractModal

  const handleModalOpen = (id: string) => {
    setContractId(id)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <EditContract {...editContractModal} />
      <CreateContract {...createContractModal} />
      <ShowContract contractId={contractId} open={modalOpen} handleClose={handleModalClose} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List headerProps={{
            title: <Typography variant="h5">Contratos</Typography>,
            action: <Button
              variant='contained'
              color='success'
              onClick={() => showContractModal()}
            >Novo Contrato</Button>
          }}
          breadcrumb={<></>}
          headerButtons={<></>}>
            <Grid item xs={12}>
              <DataGrid
                {...dataGridProps}
                columns={columns}
                density='compact'
                autoHeight
                pagination
                paginationMode='server'
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel)
                  handleSaveColumnVisibility({
                    tableId,
                    userId: user?.id ?? '',
                    column_visibility: newModel
                  })
                }}
              />
            </Grid>
          </List>
        </Grid>
      </Grid>
    </>
  )
}
