import React, { ReactNode } from 'react'
import {
  Create
} from '@refinedev/mui'
import {
  Box,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  InputAdornment
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { useForm } from '@refinedev/react-hook-form'

import { EnumTypeTraining } from 'enums'

export function TrainingCreate () {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors }
  } = useForm()

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Formação</Typography>
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('name', { required: 'Nome é obrigatório!', minLength: 5, maxLength: 50 })}
              error={!!errors?.name}
              helperText={errors.name?.message as ReactNode}
              label="Nome"
              name="name"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Controller
              control={control}
              name="type"
              rules={{ required: 'Tipo é obrigatório!' }}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  options={Object.values(EnumTypeTraining)}
                  onChange={(_, value) => {
                    field.onChange(Object.values(EnumTypeTraining).indexOf(value as unknown as EnumTypeTraining))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo"
                      variant="outlined"
                      margin="normal"
                      error={!!errors.type}
                      helperText={errors.type?.message as ReactNode}
                      required
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('training_init', {
                valueAsDate: true
              })}
              error={!!errors?.training_init}
              helperText={errors.training_init?.message as ReactNode}
              label="Data de Inicío"
              name="training_init"
              margin="normal"
              type="date"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('training_end', {
                valueAsDate: true
              })}
              error={!!errors?.training_end}
              helperText={errors.training_end?.message as ReactNode}
              label="Data de Término"
              name="training_end"
              margin="normal"
              type="date"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('text', { required: 'Texto da primeira página é obrigatório!', minLength: 5, maxLength: 500 })}
              error={!!errors?.text}
              helperText={errors.text?.message as ReactNode}
              label="Texto da primeira página"
              name="text"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('description', { maxLength: 500 })}
              error={!!errors?.description}
              helperText={errors.description?.message as ReactNode}
              label="Texto da segunda página"
              name="description"
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  )
}
