import { useEffect, useMemo, useState } from 'react'
import { IDefaultFilters, ICampaign } from 'interfaces'
import { EnumCampaigns, EnumStatus, SendingEmail } from 'enums'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { CampaignStatus } from 'components/campaigns/status'
import { Grid, Stack, Theme, Typography } from '@mui/material'
import { GridColumns, DataGrid, GridToolbar, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { HttpError, useGetIdentity, useList } from '@refinedev/core'
import { ShowButton, DeleteButton, DateField, useDataGrid, List } from '@refinedev/mui'
import { EmailStatusChart } from 'components/mail'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function CampaignList () {
  const tableId = 'campaign'

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    status: true,
    title: true,
    type_campaigns: true,
    sending_email: true,
    description: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<ICampaign>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton
                hideText
                recordItemId={row.id}
              />
            </Stack>
          )
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return <CampaignStatus status={row.status ?? EnumStatus.PENDING} />
        }
      },
      {
        field: 'title',
        headerName: 'Título',
        flex: 2,
        minWidth: 200
      },
      {
        field: 'type_campaigns',
        headerName: 'Tipo',
        flex: 1,
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: Object.values(EnumCampaigns).map(obj => {
          return {
            value: Object.values(EnumCampaigns).indexOf(obj),
            label: obj
          }
        })
      },
      {
        field: 'sending_email',
        headerName: 'E-mail de Envio',
        flex: 1,
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: Object.values(SendingEmail).map(obj => {
          return {
            value: Object.values(SendingEmail).indexOf(obj),
            label: obj
          }
        }),
        renderCell: function render ({ row }) {
          return row.sending_email ? SendingEmail[row.sending_email] : ''
        }
      },
      {
        field: 'description',
        headerName: 'Descrição',
        flex: 3,
        minWidth: 200
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.deletedAt ? <DateField format='HH:mm D/M/YY' value={row.deletedAt} /> : ''
        }
      }
    ],
    []
  )

  const { dataGridProps } = useDataGrid<ICampaign,
  HttpError,
  IDefaultFilters
  >({
    pagination: {
      pageSize: 25,
      current: 0
    },
    metaData: {
      operation: 'campaigns',
      fields: [
        'total',
        {
          nodes: [
            'id',
            'title',
            'type_campaigns',
            'sending_email',
            'description',
            'status',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        }
      ]
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc'
        }
      ]
    }
  })

  const { data } = useList({
    resource: 'getEmailData',
    pagination: {
      mode: 'off'
    },
    meta: {
      operation: 'getEmailData',
      fields: [
        'date',
        'requests',
        'delivered',
        'opened',
        'clicked',
        'bounces',
        'spamReports'
      ]
    }
  })
  const graph = data?.data

  return (
    <List headerProps={{
      title: <Typography variant="h5">Lista de Campanhas</Typography>
    }}>
      <Grid container>
        <Grid item xs={12}>
          {
            graph && (<EmailStatusChart emailData={graph} />)
          }
        </Grid>
      </Grid>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        pagination
        paginationMode='server'
        density='compact'
        components={{
          Toolbar: GridToolbar
        }}
        componentsProps={{
          filterPanel: {
            linkOperators: [GridLinkOperator.And],
            columnsSort: 'asc',
            filterFormProps: {
              linkOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small'
                }
              },
              deleteIconProps: {
                sx: {
                  '& .MuiSvgIcon-root': { color: '#d32f2f' }
                }
              }
            },
            sx: {
              '& .MuiDataGrid-filterForm': { p: 2 },
              '& .MuiDataGrid-filterForm:nth-child(even)': {
                backgroundColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
              },
              '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormValueInput': { width: 200 }
            }
          }
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel)
          handleSaveColumnVisibility({
            tableId,
            userId: user?.id ?? '',
            column_visibility: newModel
          })
        }}
        localeText={DatagridLanguage()}
      />
    </List>
  )
}
