/* eslint-disable dot-notation */
import React, { ReactNode, useState } from 'react'
import { Create, useAutocomplete } from '@refinedev/mui'

import {
  Box,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  Stack
} from '@mui/material'

import { useForm } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Add, Clear, Save } from '@mui/icons-material'
import { CodeBankSlipDiscount, CodeBankSlipFine, CodeBankSlipInterest } from 'enums'
import { ISimec } from 'interfaces'
import { BaseRecord, HttpError } from '@refinedev/core'

export function BankSlipCreate () {
  const [value, setValue] = useState<number>(0)
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [discount1, setDiscount1] = useState<string | null>(null)
  const [discount2, setDiscount2] = useState<string | null>(null)
  const [discount3, setDiscount3] = useState<string | null>(null)
  const [fine, setFine] = useState<string | null>(null)
  const [interest, setInterest] = useState<string | null>(null)
  const [lines, setLines] = useState<number>(1)
  const [discounts, setDiscounts] = useState<number>(1)

  const { autocompleteProps: customerProps } = useAutocomplete<ISimec>({
    resource: 'simecCustomers',

    meta: {
      fields: [
        {
          nodes: ['id', 'name', 'county_name', 'state_abbreviation']
        }
      ]
    },

    pagination: {
      current: 1,
      pageSize: 25
    },

    onSearch: (value) => [
      {
        field: 'name',
        operator: 'contains',
        value: `%${value}%`
      }
    ],

    sorters: [
      {
        field: 'name',
        order: 'asc'
      }
    ]
  })

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<
  BaseRecord,
  HttpError,
  any
  >({
    warnWhenUnsavedChanges: true,
    defaultValues: {
      value: 0,
      cancellation_days: '',
      customer: null,
      due_date: '',
      discount1: {
        code_discount: null,
        date: '',
        percentage: '',
        value: ''
      },
      discount2: {
        code_discount: null,
        date: '',
        percentage: '',
        value: ''
      },
      discount3: {
        code_discount: null,
        date: '',
        percentage: '',
        value: ''
      },
      fine: {
        code_fine: null,
        date: '',
        percentage: '',
        value: ''
      },
      interest: {
        code_interest: null,
        date: '',
        percentage: '',
        value: ''
      },
      message: {
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        line5: ''
      }
    }
  })

  const handleSubmitCreate = (values: FieldValues) => {
    const { customer, interest, fine, discount1, discount2, discount3, ...rest } = values

    onFinish({
      ...rest,
      customer_id: customer.id,
      interest: interest.code_interest
        ? {
          ...interest,
          code_interest: Object.keys(CodeBankSlipInterest)[Object.values(CodeBankSlipInterest).indexOf(interest.code_interest)]
        }
        : null,
      discount1: {
        ...discount1,
        code_discount: Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(discount1.code_discount)]
      },
      discount2: discount2?.code_discount
        ? {
          ...discount2,
          code_discount: Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(discount2.code_discount)]
        }
        : null,
      discount3: discount3?.code_discount
        ? {
          ...discount3,
          code_discount: Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(discount3.code_discount)]
        }
        : null,
      fine: fine.code_fine
        ? {
          ...fine,
          code_fine: Object.keys(CodeBankSlipFine)[Object.values(CodeBankSlipFine).indexOf(fine.code_fine)]
        }
        : null
    })
  }

  function addLine () {
    if (lines < 5) {
      setLines(lines + 1)
    }
  }

  function removeLine () {
    setLines(lines - 1)
  }

  function addDiscount () {
    if (discounts < 3) {
      setDiscounts(discounts + 1)
    }
  }

  function removeDiscount () {
    setDiscounts(discounts - 1)
  }

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Boleto Bancário</Typography>
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column' }}
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('value', {
                  min: {
                    value: 2.5,
                    message: 'Valor minimo é de R$2.50'
                  },
                  required: 'Valor é obrigatório!',
                  valueAsNumber: true
                })}
                error={!!errors?.value}
                helperText={errors?.value?.message as ReactNode}
                label="Valor do Boleto"
                name="value"
                margin="normal"
                color="success"
                type="number"
                onChange={(event) => setValue(Number(event.target.value))}
                fullWidth
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('due_date', {
                  required: 'Obrigatório informar a data de Vencimento do Boleto!',
                  validate: (value) => {
                    const d = new Date(value)
                    const dueDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
                    const now = new Date()
                    dueDate.setHours(12, 0, 0, 0)
                    now.setHours(12, 0, 0, 0)
                    return dueDate.getTime() < now.getTime() ? 'A data de vencimento não pode ser inferior à data atual!' : true
                  },
                  valueAsDate: true
                })}
                error={!!errors?.due_date}
                helperText={errors?.due_date?.message as ReactNode}
                onChange={((event) => setDueDate(new Date(event.target.value)))}
                label="Data de Vencimento"
                name="due_date"
                margin="normal"
                type="date"
                color="success"
                required
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('cancellation_days', {
                  required: 'Número de dias para cancelamento é obrigatório!',
                  min: {
                    value: 0,
                    message: 'Valor minimo é de 0 dias'
                  },
                  valueAsNumber: true
                })}
                error={!!errors?.cancellation_days}
                helperText={errors?.cancellation_days?.message as ReactNode ?? 'Número de dias corridos após o vencimento para o cancelamento efetivo automático do boleto'}
                label="Dias para cancelamento"
                name="cancellation_days"
                margin="normal"
                required
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">Dias</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="customer"
                rules={{ required: 'Cliente é obrigatório!' }}
                render={({ field }) => (
                  <Autocomplete
                    {...customerProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    getOptionLabel={(item) => {
                      return item.name
                        ? `${item.name} - ${item.county_name} - ${item.state_abbreviation}`
                        : customerProps?.options?.find(
                          (p) =>
                            p.id.toString() ===
                            item.toString()
                        )?.name ?? ''
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.id === value.id
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors?.customer}
                        helperText={errors?.customer?.message as ReactNode ?? 'Selecione o município que deseja gerar o boleto'}
                        label={'Cliente'}
                        placeholder={'Selecione o município'}
                        margin="normal"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Typography variant="h5" gutterBottom>
                      Desconto
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      (Opcional) É possível adicionar até 3 descontos.
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} lg={6}>
                    <Stack alignItems={'end'}>
                      <Button variant='contained' disabled={(discounts === 3)} onClick={() => addDiscount()}><Add /> Novo desconto</Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Controller
                      control={control}
                      name="discount1.code_discount"
                      rules={{ required: 'Tipo de Desconto é obrigatório!' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={Object.values(CodeBankSlipDiscount).filter(value => typeof value === 'string')}
                          onChange={(_, value) => {
                            const findIndex = value ? Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(value)] : null
                            setDiscount1(findIndex)
                            field.onChange(value)
                          } }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Desconto"
                              margin="normal"
                              variant="outlined"
                              error={!!errors?.discount1?.['code_discount']}
                              helperText={errors?.discount1?.['code_discount']?.message as ReactNode} />
                          )} />
                      )} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('discount1.date', {
                        required: {
                          value: (discount1 !== '' && discount1 !== 'NAOTEMDESCONTO'),
                          message: 'Obrigatório informar a data de Desconto do Boleto!'
                        },
                        validate: (value) => {
                          let invalid: string | boolean = true
                          invalid = !!(dueDate && new Date(value).getTime() > dueDate.getTime())
                          if (invalid) { return 'A data de desconto do boleto não pode ser superior à data de vencimento do boleto!' }
                          invalid = new Date(value).getTime() <= new Date().getTime()
                          if (invalid) { return 'A data de desconto do boleto não pode ser inferior à data atual!' }

                          return true
                        },
                        valueAsDate: true
                      })}
                      error={!!errors?.discount1?.['date']}
                      helperText={errors?.discount1?.['date']?.message as ReactNode ?? (discount1 !== 'NAOTEMDESCONTO' && discount1 ? 'Obrigatório!' : '')}
                      label="Data de Desconto do boleto"
                      name="discount1.date"
                      margin="normal"
                      required={(discount1 !== 'NAOTEMDESCONTO')}
                      disabled={(discount1 === 'NAOTEMDESCONTO')}
                      type="date"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('discount1.percentage', {
                        required: (discount1 === 'PERCENTUALDATAINFORMADA'),
                        min: {
                          value: 0,
                          message: 'Valor minímo é 0'
                        },
                        max: {
                          value: 100,
                          message: 'Valor máximo é 100'
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.discount1?.['percentage']}
                      helperText={errors?.discount1?.['percentage']?.message as ReactNode ?? (discount1 === 'PERCENTUALDATAINFORMADA' ? 'Obrigatório!' : '')}
                      label="Taxa Percentual"
                      name="discount1.percentage"
                      margin="normal"
                      type="number"
                      required={(discount1 === 'PERCENTUALDATAINFORMADA')}
                      disabled={(discount1 !== 'PERCENTUALDATAINFORMADA')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('discount1.value', {
                        required: (discount1 === 'VALORFIXODATAINFORMADA'),
                        max: {
                          value,
                          message: `Valor máximo é ${value}`
                        },
                        validate: (discountValue) => {
                          return discountValue === value ? 'O valor do desconto não pode ser igual ao valor do boleto' : true
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.discount1?.['value']}
                      helperText={errors?.discount1?.['value']?.message as ReactNode ?? (discount1 === 'VALORFIXODATAINFORMADA' ? 'Obrigatório!' : '')}
                      label="Valor"
                      name="discount1.value"
                      margin="normal"
                      type="number"
                      required={(discount1 === 'VALORFIXODATAINFORMADA')}
                      disabled={(discount1 !== 'VALORFIXODATAINFORMADA')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }} />
                  </Grid>

                  {
                    discounts >= 2
                      ? (
                        <>
                          <Grid item xs={12} md={6} lg={3}>
                            <Controller
                              control={control}
                              name="discount2.code_discount"
                              rules={{ required: 'Tipo de Desconto é obrigatório!' }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  options={Object.values(CodeBankSlipDiscount).filter(value => typeof value === 'string')}
                                  onChange={(_, value) => {
                                    const findIndex = value ? Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(value)] : null
                                    setDiscount2(findIndex)
                                    field.onChange(value)
                                  } }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Tipo de Desconto"
                                      margin="normal"
                                      variant="outlined"
                                      error={!!errors?.discount2?.['code_discount']}
                                      helperText={errors?.discount2?.['code_discount']?.message as ReactNode} />
                                  )} />
                              )} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount2.date', {
                                required: {
                                  value: (discount2 !== '' && discount2 !== 'NAOTEMDESCONTO'),
                                  message: 'Obrigatório informar a data de Desconto do Boleto!'
                                },
                                validate: (value) => {
                                  let invalid: string | boolean = true
                                  invalid = !!(dueDate && new Date(value).getTime() > dueDate.getTime())
                                  if (invalid) { return 'A data de desconto do boleto não pode ser superior à data de vencimento do boleto!' }
                                  invalid = new Date(value).getTime() <= new Date().getTime()
                                  if (invalid) { return 'A data de desconto do boleto não pode ser inferior à data atual!' }

                                  return true
                                },
                                valueAsDate: true
                              })}
                              error={!!errors?.discount2?.['date']}
                              helperText={errors?.discount2?.['date']?.message as ReactNode ?? (discount2 !== 'NAOTEMDESCONTO' && discount2 ? 'Obrigatório!' : '')}
                              label="Data de Desconto do boleto"
                              name="discount2.date"
                              margin="normal"
                              required={(discount2 !== 'NAOTEMDESCONTO')}
                              disabled={(discount2 === 'NAOTEMDESCONTO')}
                              type="date"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>
                              }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount2.percentage', {
                                required: (discount2 === 'PERCENTUALDATAINFORMADA'),
                                min: {
                                  value: 0,
                                  message: 'Valor minímo é 0'
                                },
                                max: {
                                  value: 100,
                                  message: 'Valor máximo é 100'
                                },
                                valueAsNumber: true
                              })}
                              error={!!errors?.discount2?.['percentage']}
                              helperText={errors?.discount2?.['percentage']?.message as ReactNode ?? (discount2 === 'PERCENTUALDATAINFORMADA' ? 'Obrigatório!' : '')}
                              label="Taxa Percentual"
                              name="discount2.percentage"
                              margin="normal"
                              type="number"
                              required={(discount2 === 'PERCENTUALDATAINFORMADA')}
                              disabled={(discount2 !== 'PERCENTUALDATAINFORMADA')}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>
                              }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount2.value', {
                                required: (discount2 === 'VALORFIXODATAINFORMADA'),
                                min: {
                                  value: 0,
                                  message: 'Valor minímo é 0'
                                },
                                validate: (discountValue) => {
                                  return discountValue === value ? 'O valor do desconto não pode ser igual ao valor do boleto' : true
                                },
                                valueAsNumber: true
                              })}
                              error={!!errors?.discount2?.['value']}
                              helperText={errors?.discount2?.['value']?.message as ReactNode ?? (discount2 === 'VALORFIXODATAINFORMADA' ? 'Obrigatório!' : '')}
                              label="Valor"
                              name="discount2.value"
                              margin="normal"
                              type="number"
                              required={(discount2 === 'VALORFIXODATAINFORMADA')}
                              disabled={(discount2 !== 'VALORFIXODATAINFORMADA')}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                endAdornment: discounts === 2
                                  ? (
                                    <InputAdornment position="end">
                                      <IconButton color="error" sx={{ p: '10px' }} aria-label="add">
                                        <Clear onClick={() => removeDiscount()} />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                  : null
                              }} />
                          </Grid>
                        </>
                      )
                      : null
                  }

                  {
                    discounts === 3
                      ? (
                        <>
                          <Grid item xs={12} md={6} lg={3}>
                            <Controller
                              control={control}
                              name="discount3.code_discount"
                              rules={{ required: 'Tipo de Desconto é obrigatório!' }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  options={Object.values(CodeBankSlipDiscount).filter(value => typeof value === 'string')}
                                  onChange={(_, value) => {
                                    const findIndex = value ? Object.keys(CodeBankSlipDiscount)[Object.values(CodeBankSlipDiscount).indexOf(value)] : null
                                    setDiscount3(findIndex)
                                    field.onChange(value)
                                  } }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Tipo de Desconto"
                                      margin="normal"
                                      variant="outlined"
                                      error={!!errors?.discount3?.['code_discount']}
                                      helperText={errors?.discount3?.['code_discount']?.message as ReactNode} />
                                  )} />
                              )} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount3.date', {
                                required: {
                                  value: (discount3 !== '' && discount3 !== 'NAOTEMDESCONTO'),
                                  message: 'Obrigatório informar a data de Desconto do Boleto!'
                                },
                                validate: (value) => {
                                  let invalid: string | boolean = true
                                  invalid = !!(dueDate && new Date(value).getTime() > dueDate.getTime())
                                  if (invalid) { return 'A data de desconto do boleto não pode ser superior à data de vencimento do boleto!' }
                                  invalid = new Date(value).getTime() <= new Date().getTime()
                                  if (invalid) { return 'A data de desconto do boleto não pode ser inferior à data atual!' }

                                  return true
                                },
                                valueAsDate: true
                              })}
                              error={!!errors?.discount3?.['date']}
                              helperText={errors?.discount3?.['date']?.message as ReactNode ?? (discount3 !== 'NAOTEMDESCONTO' && discount3 ? 'Obrigatório!' : '')}
                              label="Data de Desconto do boleto"
                              name="discount3.date"
                              margin="normal"
                              required={(discount3 !== 'NAOTEMDESCONTO')}
                              disabled={(discount3 === 'NAOTEMDESCONTO')}
                              type="date"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>
                              }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount3.percentage', {
                                required: (discount3 === 'PERCENTUALDATAINFORMADA'),
                                min: {
                                  value: 0,
                                  message: 'Valor minímo é 0'
                                },
                                max: {
                                  value: 100,
                                  message: 'Valor máximo é 100'
                                },
                                valueAsNumber: true
                              })}
                              error={!!errors?.discount3?.['percentage']}
                              helperText={errors?.discount3?.['percentage']?.message as ReactNode ?? (discount3 === 'PERCENTUALDATAINFORMADA' ? 'Obrigatório!' : '')}
                              label="Taxa Percentual"
                              name="discount3.percentage"
                              margin="normal"
                              type="number"
                              required={(discount3 === 'PERCENTUALDATAINFORMADA')}
                              disabled={(discount3 !== 'PERCENTUALDATAINFORMADA')}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>
                              }} />
                          </Grid>
                          <Grid item xs={12} md={6} lg={3}>
                            <TextField
                              {...register('discount3.value', {
                                required: (discount3 === 'VALORFIXODATAINFORMADA'),
                                min: {
                                  value: 0,
                                  message: 'Valor minímo é 0'
                                },
                                validate: (discountValue) => {
                                  return discountValue === value ? 'O valor do desconto não pode ser igual ao valor do boleto' : true
                                },
                                valueAsNumber: true
                              })}
                              error={!!errors?.discount3?.['value']}
                              helperText={errors?.discount3?.['value']?.message as ReactNode ?? (discount3 === 'VALORFIXODATAINFORMADA' ? 'Obrigatório!' : '')}
                              label="Valor"
                              name="discount3.value"
                              margin="normal"
                              type="number"
                              required={(discount2 === 'VALORFIXODATAINFORMADA')}
                              disabled={(discount2 !== 'VALORFIXODATAINFORMADA')}
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                endAdornment: discounts === 3
                                  ? (
                                    <InputAdornment position="end">
                                      <IconButton color="error" sx={{ p: '10px' }} aria-label="add">
                                        <Clear onClick={() => removeDiscount()} />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                  : null
                              }} />
                          </Grid>
                        </>
                      )
                      : null
                  }

                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }} >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Multa
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Controller
                      control={control}
                      name="fine.code_fine"
                      rules={{ required: 'Tipo de Multa é obrigatório!' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={Object.values(CodeBankSlipFine).filter(value => typeof value === 'string')}
                          onChange={(_, value) => {
                            const findIndex = value ? Object.keys(CodeBankSlipFine)[Object.values(CodeBankSlipFine).indexOf(value)] : null
                            setFine(findIndex)
                            field.onChange(value)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Multa"
                              margin="normal"
                              variant="outlined"
                              error={!!errors?.fine?.['fine_code']}
                              helperText={errors?.fine?.['fine_code']?.message as ReactNode}
                              required
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('fine.date', {
                        required: {
                          value: (fine !== '' && fine !== 'NAOTEMMULTA'),
                          message: 'Obrigatório informar a data de Multa do Boleto!'
                        },
                        validate: (value) => {
                          return new Date(value).getTime() <= (new Date() || dueDate).getTime() ? 'A data de Multa do boleto não pode ser inferior à data de vencimento do boleto!' : true
                        },
                        valueAsDate: true
                      })}
                      error={!!errors?.fine?.['date']}
                      helperText={errors?.fine?.['date']?.message as ReactNode ?? (fine !== 'NAOTEMMULTA' && fine ? 'Obrigatório!' : '')}
                      label="Data da Multa do boleto"
                      name="fine.date"
                      margin="normal"
                      required={(fine !== 'NAOTEMMULTA')}
                      disabled={(fine === 'NAOTEMMULTA')}
                      type="date"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('fine.percentage', {
                        required: (fine === 'PERCENTUAL'),
                        min: {
                          value: 0,
                          message: 'Valor minímo é 0'
                        },
                        max: {
                          value: 100,
                          message: 'Valor máximo é 100'
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.fine?.['percentage']}
                      helperText={errors?.fine?.['percentage']?.message as ReactNode ?? (fine === 'PERCENTUAL' ? 'Obrigatório!' : '')}
                      label="Taxa Percentual"
                      name="fine.percentage"
                      margin="normal"
                      type="number"
                      required={(fine === 'PERCENTUAL')}
                      disabled={(fine !== 'PERCENTUAL')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('fine.value', {
                        required: (fine === 'VALORFIXO'),
                        min: {
                          value: 0,
                          message: 'Valor minímo é 0'
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.fine?.['value']}
                      helperText={errors?.fine?.['value']?.message as ReactNode ?? (fine === 'VALORFIXO' ? 'Obrigatório!' : '')}
                      label="Valor"
                      name="fine.value"
                      margin="normal"
                      type="number"
                      required={(fine === 'VALORFIXO')}
                      disabled={(fine === 'NAOTEMMULTA')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }} >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Juros
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Controller
                      control={control}
                      name="interest.code_interest"
                      rules={{ required: 'Tipo de Juros é obrigatório!' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={Object.values(CodeBankSlipInterest).filter(value => typeof value === 'string')}
                          onChange={(_, value) => {
                            const findIndex = value ? Object.keys(CodeBankSlipInterest)[Object.values(CodeBankSlipInterest).indexOf(value)] : null
                            setInterest(findIndex)
                            field.onChange(value)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Juros"
                              margin="normal"
                              variant="outlined"
                              error={!!errors?.interest?.['code_interest']}
                              helperText={errors?.interest?.['code_interest']?.message as ReactNode}
                              required
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('interest.date', {
                        required: {
                          value: (interest !== 'ISENTO' && interest !== ''),
                          message: 'Obrigatório informar a data de Juros do Boleto!'
                        },
                        validate: (value) => {
                          return new Date(value).getTime() <= (dueDate || new Date()).getTime() ? 'A data deve ser maior que a data de vencimento e marca a data de início de cobrança de juros (incluindo essa data)' : true
                        },
                        valueAsDate: true
                      })}
                      error={!!errors?.interest?.['date']}
                      helperText={errors?.interest?.['date']?.message as ReactNode ?? (interest !== 'ISENTO' && interest ? 'Obrigatório!' : '')}
                      label="Data da Juros do boleto"
                      name="interest.date"
                      margin="normal"
                      required={(interest !== 'ISENTO')}
                      disabled={(interest === 'ISENTO')}
                      type="date"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('interest.percentage', {
                        required: (interest === 'TAXAMENSAL'),
                        min: {
                          value: 0,
                          message: 'Valor minímo é 0'
                        },
                        max: {
                          value: 100,
                          message: 'Valor máximo é 100'
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.interest?.['percentage']}
                      helperText={errors?.interest?.['percentage']?.message as ReactNode ?? (interest === 'TAXAMENSAL' ? 'Obrigatório!' : '')}
                      label="Taxa Percentual"
                      name="interest.percentage"
                      margin="normal"
                      type="number"
                      required={(interest === 'TAXAMENSAL')}
                      disabled={(interest !== 'TAXAMENSAL')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      {...register('interest.value', {
                        required: (interest === 'VALORDIA'),
                        min: {
                          value: 0,
                          message: 'Valor minímo é 0'
                        },
                        valueAsNumber: true
                      })}
                      error={!!errors?.interest?.['value']}
                      helperText={errors?.interest?.['value']?.message as ReactNode ?? (interest === 'VALORDIA' ? 'Obrigatório!' : '')}
                      label="Valor"
                      name="interest.value"
                      margin="normal"
                      type="number"
                      required={(interest === 'VALORDIA')}
                      disabled={(interest !== 'VALORDIA')}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }} >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                      Mensagem
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      (Opcional) É possível adicionar até 5 linhas com 78 caracteres cada.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack alignItems={'end'}>
                      <Button
                        variant='contained'
                        disabled={(lines === 5)}
                        onClick={() => addLine()}
                        startIcon={
                          <Add />
                        }>Nova linha</Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      [...Array(lines)].map((line, i) => (
                        <TextField
                          key={i}
                          {...register(`message.line${i + 1}`, { maxLength: 78 })}
                          error={!!errors?.message?.[`line${i + 1}`]}
                          helperText={errors?.message?.[`line${i + 1}`].message as ReactNode ?? `Linha ${i + 1} do campo de texto de mensagem no boleto`}
                          label={'Mensagem'}
                          name={`message.line${i + 1}`}
                          margin="normal"
                          fullWidth
                          InputProps={{
                            endAdornment: lines === (i + 1) && i > 0
                              ? (
                                <InputAdornment position="end">
                                  <IconButton color="error" sx={{ p: '10px' }} aria-label="add">
                                    <Clear onClick={() => removeLine()} />
                                  </IconButton>
                                </InputAdornment>
                              )
                              : null
                          }}
                        />
                      ))
                    }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Stack paddingTop={2} justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
          <Button type="submit" variant="contained"><Save/> Gerar Boleto</Button>
        </Stack>
      </form>
    </Create>
  )
}
