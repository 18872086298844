import React, { useContext } from 'react'
import { ModalContext } from 'contexts/modal'
import { Document, Page, pdfjs } from 'react-pdf'
import { Container, Stack, Paper, Modal, Backdrop, Fade } from '@mui/material'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const ModalImage = () => {
  const context = useContext(ModalContext)
  const onCloseModal = () => context?.setOpen(false)

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }

  function renderSwitch () {
    switch (true) {
      case context?.file?.mimetype?.includes('image'):
        return (
          <img
            src={context?.file?.file_url}
            srcSet={context?.file?.file_url}
            alt={context?.file?.file_name}
            loading="lazy"
            style={{
              maxHeight: '900px',
              maxWidth: '100%'
            }}
          />
        )
      case context?.file?.mimetype?.includes('pdf'):
        return (
          <Document
            file={context?.file?.file_url}
          ><Page pageNumber={1} />
          </Document>
        )
      case context?.file?.mimetype?.includes('officedocument'):
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${context?.file?.file_url}&embedded=true`}
            loading="lazy"
            style={{
              maxWidth: '700px',
              maxHeight: '900px',
              minHeight: '600px',
              minWidth: '500px'
            }}
          />
        )
      default:
        return (
          <Document
            file={context?.file?.file_url}
          ><Page pageNumber={1} />
          </Document>
        )
    }
  }

  return (
    <Modal
      open={context?.open ?? false}
      onClose={onCloseModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={context?.open}>
        <Container maxWidth='md' sx={style}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Paper elevation={24}>
              {renderSwitch()}
            </Paper>
          </Stack>
        </Container>
      </Fade>
    </Modal>
  )
}

export default ModalImage
