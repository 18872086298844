import React, { useState, ReactNode, useEffect } from 'react'
import { CloseOutlined, Save } from '@mui/icons-material'
import { IState, IDistrict } from 'interfaces'
import { EnumKindOfPerson } from 'enums'
import { Drawer, Typography, IconButton, Stack, Box, Grid, TextField, Autocomplete, Button } from '@mui/material'
import { HttpError } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { FieldValues, Controller } from 'react-hook-form'
import { Edit } from '@refinedev/mui'

export const EditStudent: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  reset,
  control,
  modal: { visible, close },
  saveButtonProps
}) => {
  const [states, setStates] = useState<IState[]>()
  const [districts, setDistricts] = useState<IDistrict[]>()
  const [stateName, setStateName] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      const json = await data.json()
      setStates(json)
    }
    fetchData()
      .catch(console.error)
  }, [])

  useEffect(() => {
    const stateSelected = states?.find(state => state.nome === stateName)
    const fetchData = async () => {
      const data = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateSelected?.id}/distritos`)
      const json = await data.json()
      setDistricts(json)
    }
    fetchData()
      .catch(console.error)
  }, [stateName])

  const handleSubmitEdit = (values: FieldValues) => {
    const { type_person: typePerson, ...rest } = values
    onFinish({
      ...rest,
      type_person: Object.values(EnumKindOfPerson)[typePerson]
    })
    reset()
    close()
  }

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 1000 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Editar Aluno</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            component="div"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitEdit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('name', {
                      required: 'Nome é obrigatório!',
                      minLength: {
                        value: 3,
                        message: 'Nome deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 150,
                        message: 'Nome deve conter no máximo 150 letras'
                      }
                    })}
                    defaultValue={' '}
                    error={!!errors?.name}
                    helperText={errors.name?.message as ReactNode}
                    label="Nome"
                    name="name"
                    margin="normal"
                    size='small'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('cpf_cnpj', {
                      minLength: {
                        value: 3,
                        message: 'CPF/CNPJ deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 150,
                        message: 'CPF/CNPJ deve conter no máximo 150 letras'
                      }
                    })}
                    defaultValue={' '}
                    error={!!errors?.cpf_cnpj}
                    helperText={errors.cpf_cnpj?.message as ReactNode}
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    defaultValue={null as any}
                    control={control}
                    name="type_person"
                    rules={{ required: 'Tipo de Pessoa é obrigatório!' }}
                    render={({ field }) => (
                      <Autocomplete
                        disablePortal
                        {...field}
                        options={Object.values(EnumKindOfPerson).filter(value => typeof value === 'string')}
                        onChange={(_, value) => {
                          field.onChange(Object.values(EnumKindOfPerson).indexOf(value as unknown as EnumKindOfPerson))
                        }}
                        getOptionLabel={(value) => {
                          let option = ''
                          option = (typeof value === 'number')
                            ? Object.values(EnumKindOfPerson)[value]
                            : EnumKindOfPerson[value]

                          if (!option) {
                            const indexOfS = Object.values(EnumKindOfPerson).indexOf(value as unknown as EnumKindOfPerson)
                            const key = Object.keys(EnumKindOfPerson)[indexOfS]
                            option = EnumKindOfPerson[key]
                          }
                          return option
                        }}
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'number') return option.toString() === Object.values(EnumKindOfPerson)[value]

                          return value === undefined ||
                          option.toString() === EnumKindOfPerson[value]
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de Pessoa"
                            variant="outlined"
                            margin="normal"
                            size='small'
                            error={!!errors.type_person}
                            helperText={errors.type_person?.message as ReactNode}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('email')}
                    defaultValue={' '}
                    error={!!errors?.email}
                    helperText={errors.email?.message as ReactNode}
                    label="E-mail"
                    name="email"
                    margin="normal"
                    size='small'
                    type='email'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('phone')}
                    defaultValue={' '}
                    error={!!errors?.phone}
                    helperText={errors.phone?.message as ReactNode}
                    label="Telefone"
                    name="phone"
                    placeholder='XX XXXXX-XXXX'
                    margin="normal"
                    size='small'
                    type='tel'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    defaultValue={null as any}
                    control={control}
                    name="state_abbreviation"
                    render={({ field }) => (
                      <Autocomplete
                        groupBy={(option) => {
                          const match = states?.find(state => state.nome === option)
                          return match?.regiao.nome || ''
                        }}
                        {...field}
                        disablePortal
                        options={states?.map(state => state.nome) || []}
                        onChange={(_, value: string) => {
                          setStateName(value)
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Estado"
                            variant="outlined"
                            margin='normal'
                            size='small'
                            error={!!errors.state_abbreviation}
                            helperText={errors.state_abbreviation?.message as ReactNode}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    defaultValue={null as any}
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={districts?.map(district => district?.nome) || []}
                        onChange={(_, value: string) => {
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cidade"
                            variant="outlined"
                            margin='normal'
                            size='small'
                            error={!!errors.city}
                            helperText={errors.city?.message as ReactNode}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('address', {
                      minLength: {
                        value: 1,
                        message: 'Endereço deve conter no mínimo 1 letra'
                      },
                      maxLength: {
                        value: 250,
                        message: 'Endereço deve conter no máximo 250 letras'
                      }
                    })}
                    defaultValue={' '}
                    error={!!errors?.address}
                    helperText={errors.address?.message as ReactNode}
                    label="Endereço"
                    name="address"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('zip_code')}
                    defaultValue={' '}
                    error={!!errors?.zip_code}
                    helperText={errors.zip_code?.message as ReactNode}
                    placeholder='XXXXX-XXX'
                    label="CEP"
                    name="zip_code"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('number')}
                    defaultValue={' '}
                    error={!!errors?.number}
                    helperText={errors.number?.message as ReactNode}
                    label="Número"
                    name="number"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    {...register('complement')}
                    defaultValue={' '}
                    error={!!errors?.complement}
                    helperText={errors.complement?.message as ReactNode}
                    label="Complemento"
                    name="complement"
                    margin="normal"
                    size='small'
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    <Save />
                  }>
                  Editar
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Edit>
    </Drawer>
  )
}
