import React from 'react'
import { createRoot } from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import App from './App'
import './i18n'
import { FileUploaderProvider } from 'contexts/file-uploader-context'
import { ModalProvider } from 'contexts/modal'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <FileUploaderProvider>
    <ModalProvider>
      <App />
    </ModalProvider>
  </FileUploaderProvider>
)

reportWebVitals()
