import { Box, Stack, Typography } from '@mui/material'
import { BarChart, Bar, Tooltip, ResponsiveContainer, XAxis } from 'recharts'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

import { ChartTooltip } from '../chart-tool'

export const NewCustomers = ({ total, trend, data }) => {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: '230px',
        p: 1,
        background: 'url(/images/icons/premium.png)',
        backgroundColor: '#11111190',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        backgroundSize: '54px'
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h5"
          sx={{ color: '#fff', fontWeight: 700, mb: 0 }}
        >
          Seus Clientes
        </Typography>

        <Stack alignItems="end">
          <Typography
            sx={{ fontWeight: 600, fontSize: 20, color: '#fff' }}
          >
            Total {total ?? 0}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 700,
                color: '#fff',
                fontSize: 20
              }}
            >
              Novos na semana {trend ?? 0}
            </Typography>
            {(trend ?? 0) > 0
              ? (
                <ArrowDropUp fontSize="large" color="success" />
              )
              : (
                <ArrowDropDown fontSize="large" color="error" />
              )}
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ height: '130px' }}>
        <ResponsiveContainer width="99%">
          <BarChart data={(data ?? []).reverse()} barSize={15}>
            <XAxis color='rgb(255, 255, 255)' fill='rgb(255, 255, 255)' dataKey="day" />
            <Bar
              type="natural"
              dataKey="count"
              fill='rgba(218, 165, 32, 0.5)'
              radius={[4, 4, 0, 0]}
            />
            <Tooltip
              cursor={{
                fill: 'rgba(218, 165, 32, 0.2)',
                radius: 4
              }}
              content={<ChartTooltip />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Stack>
  )
}
