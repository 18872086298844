import React, { ReactNode } from 'react'
import { Create } from '@refinedev/mui'
import { Box, TextField, Grid, Typography, Autocomplete } from '@mui/material'
import { useForm } from '@refinedev/react-hook-form'
import { Controller } from 'react-hook-form'
import { EnumUserProfile } from 'enums'

export function UserCreate () {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors }
  } = useForm()

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Usuario</Typography>
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('name', { required: 'Nome é obrigatório!', minLength: 5, maxLength: 50 })}
              error={!!errors?.name}
              helperText={errors.name?.message as ReactNode}
              label="Nome"
              name="name"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('email', { maxLength: 250 })}
              error={!!errors?.email}
              helperText={errors.email?.message as ReactNode}
              label="E-mail - BRA"
              name="email"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('access', { required: 'Login é obrigatório!', minLength: 5, maxLength: 50 })}
              error={!!errors?.access}
              helperText={errors.access?.message as ReactNode}
              label="Login"
              name="access"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('password', { required: 'Senha é obrigatório!', minLength: 6 })}
              error={!!errors?.password}
              helperText={errors.password?.message as ReactNode}
              label="Senha"
              name="password"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('cpf', { required: 'CPF é obrigatório!', minLength: 9, maxLength: 11 })}
              error={!!errors?.cpf}
              helperText={errors.cpf?.message as ReactNode}
              label="Cpf"
              name="cpf"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('phone', { minLength: 9, maxLength: 12 })}
              error={!!errors?.phone}
              helperText={errors.phone?.message as ReactNode}
              label="WhatsApp"
              name="phone"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Controller
              control={control}
              name="profile"
              rules={{ required: 'Perfil é obrigatório!' }}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  options={Object.values(EnumUserProfile)}
                  onChange={(_, value) => {
                    field.onChange(Object.values(EnumUserProfile).indexOf(value as unknown as EnumUserProfile))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Perfil"
                      variant="outlined"
                      margin="normal"
                      error={!!errors.profile}
                      helperText={errors.profile?.message as ReactNode}
                      required
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  )
}
