import { CrudFilter, DataProvider } from '@refinedev/core'
import { TOKEN_KEY } from 'config/client'
import pluralize from 'pluralize'
import camelCase from 'camelcase'
import { query as queryGQL, mutation } from 'gql-query-builder'
import { GraphQLClient } from '@refinedev/graphql'
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter'
import { convertOperatorToOrm } from 'utils/orm-operators'
import { HttpError } from '@refinedev/core/dist/interfaces'

export const dataProvider = (client: GraphQLClient): DataProvider => {
  client.setHeader('authorization', `Bearer ${TOKEN_KEY} `)
  return {
    getList: async ({
      filters,
      resource,
      meta,
      pagination,
      sorters
    }) => {
      try {
        const camelResource = camelCase(resource)
        const operation = meta?.operation ?? camelResource
        const gqlPagination = pagination?.mode === 'server'
          ? {
            pageSize: pagination?.pageSize,
            current: (((pagination?.current ?? 0) - 1) > 0 ? ((pagination?.current ?? 0) - 1) : 0) * (pagination?.pageSize ?? 25)
          }
          : null

        const gqlVariables: any = {
          ...meta?.variables,
          ...gqlPagination
        }

        if (filters && filters.length > 0) {
          gqlVariables.filters = {
            value: filters.map((filter: CrudFilter) => {
              const op = convertOperatorToOrm(filter.operator)
              if (!op) return filter
              return {
                ...filter,
                operator: op[1]
              }
            }),
            type: '[FilterArgs!]',
            required: false
          }
        }

        if (sorters && sorters.length > 0) {
          gqlVariables.sorters = {
            value: sorters.map(sorter => ({
              ...sorter,
              order: sorter.order.toUpperCase()
            })),
            type: '[SorterArgs!]',
            required: false
          }
        }

        const { query, variables } = queryGQL({
          operation,
          variables: gqlVariables,
          fields: meta?.fields
        })

        const response: any = await client.request(query, variables)
        return {
          data: response[operation].nodes ?? response[operation],
          total: response[operation].total
        }
      } catch (err: any) {
        if (err?.response?.errors[0].statusCode === 401 || err?.response?.errors[0].statusCode === 403) {
          localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY as string)
        }
        const error: HttpError = {
          message: err?.response?.errors[0].message || 'Algo de errado aconteceu!\nCaso o erro persista comunique em suporte@usystem.com.br.',
          statusCode: err?.response?.errors[0].statusCode || 500
        }
        return Promise.reject(error)
      }
    },

    getMany: async ({ resource, ids, meta }) => {
      try {
        const camelResource = camelCase(resource)
        const operation = meta?.operation ?? camelResource
        let { query } = queryGQL({
          operation,
          fields: meta?.fields
        })

        query = query.replace(operation, `${operation} (filter: { id: { in: [${ids}] } })`)

        const response: any = await client.request(query)

        return {
          data: response[operation].nodes ?? response[operation],
          total: response[operation].totalCount
        }
      } catch (err: any) {
        const error: HttpError = {
          message: err?.response?.errors[0].message || 'Algo de errado aconteceu!\nCaso o erro persista comunique em suporte@usystem.com.br.',
          statusCode: err?.response?.errors[0].statusCode || 500
        }
        return Promise.reject(error)
      }
    },

    create: async ({ resource, variables: values, meta }) => {
      try {
        const singularResource = capitalizeFirstLetter(pluralize.singular(resource ?? meta?.operation))
        const { query, variables } = mutation({
          operation: (`create${singularResource}`),
          variables: {
            [`create${singularResource}Input`]: {
              type: `Create${singularResource}Input`,
              required: true,
              value: values
            }
          },
          fields: meta?.fields ?? ['id']
        })

        const response: any = await client.request(query, variables)
        return {
          data: response[`create${singularResource}`]
        }
      } catch (err: any) {
        const error: HttpError = {
          message: err?.response?.errors[0].message || 'Algo de errado aconteceu!\nCaso o erro persista comunique em suporte@usystem.com.br.',
          statusCode: err?.response?.errors[0].statusCode || 500
        }
        return Promise.reject(error)
      }
    },

    createMany: async ({ resource, variables: values, meta }) => {
      const singularResource = capitalizeFirstLetter(pluralize.singular(resource ?? meta?.operation))
      const response = await Promise.all(
        values.map(async (value) => {
          const { query, variables } = mutation({
            operation: (`create${singularResource}`),
            variables: {
              [`create${singularResource}Input`]: {
                type: `Create${singularResource}Input`,
                required: true,
                value
              }
            },
            fields: meta?.fields ?? ['id']
          })

          const response: any = await client.request(query, variables)
          return response[`create${singularResource}`]
        })
      )
      return {
        data: response
      }
    },

    update: async ({ resource, id, variables: values, meta }) => {
      try {
        const singularResource = capitalizeFirstLetter(pluralize.singular(resource ?? meta?.operation))
        const { query, variables } = mutation({
          operation: (`update${singularResource}`),
          variables: {
            [`update${singularResource}Input`]: {
              type: `Update${singularResource}Input`,
              required: true,
              value: { id, ...values }
            }
          },
          fields: meta?.fields ?? ['id']
        })

        const response = await client.request(query, variables)
        return {
          data: response as any
        }
      } catch (err: any) {
        const error: HttpError = {
          message: err?.response?.errors[0].message || 'Algo de errado aconteceu!\nCaso o erro persista comunique em suporte@usystem.com.br.',
          statusCode: err?.response?.errors[0].statusCode || 500
        }
        return Promise.reject(error)
      }
    },

    updateMany: async ({ resource, ids, variables, meta }) => {
      const operation = meta?.operation ?? camelCase(`update - Many${capitalizeFirstLetter(resource)}`)
      const { query, variables: graphqlVar } = mutation({
        operation,
        variables: {
          [`${operation}Input`]: {
            type: capitalizeFirstLetter(`${operation}Input`),
            required: true,
            value: { ids, ...variables }
          }
        },
        fields: []
      })

      const response: any = await client.request(query, graphqlVar)
      return {
        data: response[operation][resource]
      }
    },

    getOne: async ({ resource, id, meta }) => {
      try {
        const operation = meta?.operation ?? resource
        const { query, variables } = queryGQL({
          operation,
          fields: meta?.fields,
          variables: {
            id: {
              value: id,
              required: true
            }
          }
        })

        const response: any = await client.request(query, variables)
        const result = response[operation].nodes ?? response[operation]
        return {
          data: result
        }
      } catch (err: any) {
        const error: HttpError = {
          message: err?.response?.errors[0].message || 'Algo de errado aconteceu!\nCaso o erro persista comunique em suporte@usystem.com.br.',
          statusCode: err?.response?.errors[0].statusCode || 500
        }
        return Promise.reject(error)
      }
    },

    deleteOne: async ({ resource, id, meta }) => {
      const singularResource = capitalizeFirstLetter(pluralize.singular(resource ?? meta?.operation))
      const { query, variables } = mutation({
        operation: (`remove${singularResource}`),
        variables: {
          id: {
            type: 'String',
            required: true,
            value: id
          }
        },
        fields: []
      })

      const response = await client.request(query, variables)
      return {
        data: response as any
      }
    },

    deleteMany: async ({ resource, ids, meta }) => {
      const camelDeleteName = camelCase(`delete -Many${resource} `)
      const operation = meta?.operation ?? camelDeleteName
      const query = `mutation {
        ${operation} (
          input: { filter: { id: { in: [${ids}]} } }
        ) {
          deletedCount}
      }`

      const response = await client.request(query)
      return {
        data: response as any
      }
    },

    getApiUrl: () => {
      return process.env.NODE_ENV !== 'development' ? '/v1/api/' : 'http://localhost:3000/v1/api/'
    },

    custom: async ({ url, method, meta }) => {
      const variables: any = meta?.variables
      if (method === 'patch') {
        const query = `mutation {${url} (input: ${variables.id}){id}}`
        const response = await client.request(query)
        return {
          data: response as any
        }
      } else {
        throw Error(
          'GraphQL need to operation, fields and variables values in metaData object.'
        )
      }
    }
  }
}
