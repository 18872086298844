import { useTheme } from '@mui/material/styles'
import { Chip, ChipProps } from '@mui/material'
import { StatusBankSlip } from 'enums'

type StatusBankSlipProps = {
  status: StatusBankSlip;
};

export const BankSlipStatus: React.FC<StatusBankSlipProps> = ({ status }) => {
  const { palette } = useTheme()

  let color: ChipProps['color']

  switch (status.toString()) {
    case 'PENDING_API':
      color = palette.mode === 'dark' ? 'default' : 'secondary'
      break
    case 'PENDING_PAYMENT':
      color = 'info'
      break
    case 'ANALYSIS':
      color = 'warning'
      break
    case 'PAYMENT_CONFIRMED':
      color = 'success'
      break
    case 'CANCELED':
      color = 'error'
      break
    case 'OVERDUE':
      color = 'warning'
      break
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      color={color}
      label={StatusBankSlip[status]}
    />
  )
}
