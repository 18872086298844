import { Chip, ChipProps } from '@mui/material'
import { StatusAttendance } from 'enums'

type AttendanceProps = {
  status: StatusAttendance;
};

export const AttendanceStatus: React.FC<AttendanceProps> = ({ status }) => {
  let color: ChipProps['color']

  switch (status.toString()) {
    case 'IN_PROGRESS':
      color = 'warning'
      break
    case 'URGENT':
      color = 'error'
      break
    case 'FINISHED':
      color = 'success'
      break
    case 'PENDING':
      color = 'info'
      break
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      color={color}
      label={StatusAttendance[status]}
    />
  )
}
