import React, { ReactNode, useEffect, useState } from 'react'
import { IDistrict, IState } from 'interfaces'
import { Save } from '@mui/icons-material'
import { Typography, Box, Grid, TextField, Autocomplete, Stack, Button } from '@mui/material'
import { FieldValues, Controller } from 'react-hook-form'
import { Create } from '@refinedev/mui'
import { useForm } from '@refinedev/react-hook-form'

export function SimecCreate () {
  const [states, setStates] = useState<IState[]>()
  const [districts, setDistricts] = useState<IDistrict[]>()
  const [stateName, setStateName] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      const json = await data.json()
      setStates(json)
    }
    fetchData()
      .catch(console.error)
  }, [])

  useEffect(() => {
    const stateSelected = states?.find(state => state.nome === stateName)
    const fetchData = async () => {
      const data = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateSelected?.id}/distritos`)
      const json = await data.json()
      setDistricts(json)
    }
    fetchData()
      .catch(console.error)
  }, [stateName])

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const handleSubmitCreate = (values: FieldValues) => {
    const countyName: string = values.county
    const county = districts?.find(district => district.nome === countyName)
    const { state, county: removed, ...rest } = values
    onFinish({
      ...rest,
      county_code: county?.id,
      county_name: county?.nome,
      state_code: county?.municipio.microrregiao.mesorregiao.UF.id,
      state_abbreviation: county?.municipio.microrregiao.mesorregiao.UF.sigla,
      state_name: county?.municipio.microrregiao.mesorregiao.UF.nome,
      region_code: county?.municipio.microrregiao.mesorregiao.UF.regiao.id,
      region_abbreviation: county?.municipio.microrregiao.mesorregiao.UF.regiao.sigla,
      region_name: county?.municipio.microrregiao.mesorregiao.UF.regiao.nome,
      microregion_code: county?.municipio.microrregiao.id,
      microregion_name: county?.municipio.microrregiao.nome,
      mesoregion_code: county?.municipio.microrregiao.mesorregiao.id,
      mesoregion_name: county?.municipio.microrregiao.mesorregiao.nome
    })
  }

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Cliente</Typography>
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column' }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('business_name', { required: true })}
                error={!!errors?.business_name}
                helperText={errors.business_name?.message as ReactNode}
                label="Razão Social"
                name="business_name"
                margin='normal'
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('fantasy_name', { required: true })}
                error={!!errors?.fantasy_name}
                helperText={errors.fantasy_name?.message as ReactNode}
                label="Nome Fantasia"
                name="fantasy_name"
                margin='normal'
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('cnpj', { required: true })}
                error={!!errors?.cnpj}
                helperText={errors.cnpj?.message as ReactNode}
                label="CNPJ"
                name="cnpj"
                margin='normal'
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                {...register('ie')}
                error={!!errors?.ie}
                helperText={errors.ie?.message as ReactNode}
                label="Inscrição Estadual"
                name="ie"
                margin='normal'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="state"
                rules={{ required: 'Estado é obrigatório!' }}
                render={({ field }) => (
                  <Autocomplete
                    groupBy={(option) => {
                      const match = states?.find(state => state.nome === option)
                      return match?.regiao.nome || ''
                    }}
                    {...field}
                    disablePortal
                    options={states?.map(state => state.nome) || []}
                    onChange={(_, value: string) => {
                      setStateName(value)
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        variant="outlined"
                        margin='normal'
                        required
                        error={!!errors.state}
                        helperText={errors.state?.message as ReactNode}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name="county"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={districts?.map(district => district?.nome) || []}
                    onChange={(_, value: string) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Município"
                        variant="outlined"
                        margin='normal'
                        required
                        error={!!errors.county}
                        helperText={errors.county?.message as ReactNode}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
          <Button type="submit" variant="contained"><Save/> Adicionar</Button>
        </Stack>
      </form>
    </Create>
  )
}
