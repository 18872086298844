import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from 'recharts'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'

const colors = {
  requests: '#8884d8',
  delivered: '#82ca9d',
  opened: '#ffc658',
  clicked: '#ff7300',
  bounces: '#a80000',
  spamReports: '#000000'
}

const statusLabels = {
  requests: 'Enviados',
  delivered: 'Entregues',
  opened: 'Abertos',
  clicked: 'Clicados',
  bounces: 'Recusas',
  spamReports: 'Reportados'
}

const formatDate = (dateStr) => {
  const date = new Date(dateStr)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  return `${day}/${month}`
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#f5f5f5',
          padding: '10px',
          border: '1px solid #ccc'
        }}
      >
        <p>{formatDate(label)}</p>
        {payload.map((item) => (
          <p key={item.dataKey} style={{ color: item.color }}>
            {statusLabels[item.dataKey]}: {item.value}
          </p>
        ))}
      </div>
    )
  }
  return null
}

const renderStatusCard = (title, percentage, value, color) => (
  <Grid item xs={12} sm={6} md={4} lg={2}>
    <Card style={{ margin: '1rem', flexGrow: 1 }}>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <hr style={{ borderColor: 'lightgray', margin: '0 0 1rem 0' }} />
        {title !== 'Enviados' && (
          <Typography
            variant="h4"
            align="center"
            style={{ color, fontWeight: 'bold' }}
          >
            {percentage === '-' ? '-' : !Number.isNaN(Math.round(percentage)) ? `${Math.round(percentage)}%` : '-'}
          </Typography>
        )}
        <Typography
          variant={title === 'Enviados' ? 'h4' : 'subtitle1'}
          align="center"
          style={{ color, fontWeight: 'bold' }}
        >
          {value ?? 0}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)

const CustomLegendContent = (props) => {
  const { payload } = props
  const statusLabels = {
    requests: 'Enviados',
    delivered: 'Entregues',
    opened: 'Abertos',
    clicked: 'Clicados',
    bounces: 'Recusas',
    spamReports: 'Reportados'
  }

  return (
    <ul style={{ display: 'flex', justifyContent: 'center', listStyle: 'none', margin: 0, padding: 0 }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 10, height: 10, marginRight: 5, backgroundColor: entry.color }} />
          <span>{statusLabels[entry.value]}</span>
        </li>
      ))}
    </ul>
  )
}

export const EmailStatusChart = ({ emailData }) => {
  const result = emailData.reduce((acc, data) => {
    Object.keys(data).forEach((key) => {
      if (key !== 'date') {
        acc[key] = (acc[key] || 0) + data[key]
      }
    })
    return acc
  }, {})

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Aqui está sua atividade de e-mail recente
      </Typography>
      <Grid container>
        {renderStatusCard('Enviados', result.requests, result.requests, colors.requests)}
        {renderStatusCard('Entregues', result.delivered === 0 ? '-' : (result.delivered / (result.requests || 1)) * 100, result.delivered, colors.delivered)}
        {renderStatusCard('Abertos', result.opened === 0 ? '-' : (result.opened / (result.requests || 1)) * 100, result.opened, colors.opened)}
        {renderStatusCard('Clicados', result.clicked === 0 ? '-' : (result.clicked / (result.requests || 1)) * 100, result.clicked, colors.clicked)}
        {renderStatusCard('Recusas', result.bounces === 0 ? '-' : (result.bounces / (result.requests || 1)) * 100, result.bounces, colors.bounces)}
        {renderStatusCard('Reportados', result.spamReports === 0 ? '-' : (result.spamReports / (result.requests || 1)) * 100, result.spamReports, colors.spamReports)}
      </Grid>
      {emailData.length > 0
        ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={emailData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="date">
                <Label value="Dia" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis>
                <Label value="Quantidade" angle={-90} position="insideLeft" />
              </YAxis>
              <Tooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegendContent />} />
              <Bar dataKey="requests" fill={colors.requests} />
              <Bar dataKey="delivered" fill={colors.delivered} />
              <Bar dataKey="opened" fill={colors.opened} />
              <Bar dataKey="clicked" fill={colors.clicked} />
              <Bar dataKey="bounces" fill={colors.bounces} />
              <Bar dataKey="spamReports" fill={colors.spamReports} />
            </BarChart>
          </ResponsiveContainer>
        )
        : (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
            <Typography variant="h6">Sem dados para visualizar</Typography>
          </Box>
        )}
    </div>
  )
}

export default EmailStatusChart
