import React, { useEffect, useMemo, useState } from 'react'
import { List, DeleteButton, ShowButton, EditButton, useDataGrid, DateField } from '@refinedev/mui'
import { Theme } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'
import { GridColumns, DataGrid, GridToolbar, GridLinkOperator, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { IDefaultFilters, ITraining } from 'interfaces'
import { HttpError, useGetIdentity } from '@refinedev/core'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { EnumTypeTraining } from 'enums'
import { handleSaveColumnVisibility } from 'components/datagrid'

export function TrainingList () {
  const tableId = 'training'
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    actions: true,
    name: true,
    text: true,
    description: true,
    type: true,
    training_init: true,
    training_end: true,
    createdAt: true,
    createdBy: true,
    updatedAt: false,
    updatedBy: false,
    deletedAt: false
  })

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<ITraining>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'actions',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <ShowButton hideText recordItemId={row.id} />
              <EditButton hideText recordItemId={row.id} />
              <DeleteButton
                hideText
                recordItemId={row.id}
              />
            </Stack>
          )
        }
      },
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'text',
        headerName: 'Texto da primeira página',
        flex: 3,
        minWidth: 200
      },
      {
        field: 'description',
        headerName: 'Texto da segunda página',
        flex: 3,
        minWidth: 200
      },
      {
        field: 'type',
        headerName: 'Tipo',
        flex: 1,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return EnumTypeTraining[row.type]
        }
      },
      {
        field: 'training_init',
        headerName: 'Data de Inicío',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.training_init ? <DateField format='D/M/YY' value={row.training_init} /> : null
        }
      },
      {
        field: 'training_end',
        headerName: 'Data de Término',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.training_end ? <DateField format='D/M/YY' value={row.training_end} /> : null
        }
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Criado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'updatedBy.name',
        headerName: 'Atualizado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.updatedBy?.name
        }
      },
      {
        field: 'deletedAt',
        headerName: 'Deletado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return row.deletedAt ? <DateField format='HH:mm D/M/YY' value={row.deletedAt} /> : ''
        }
      }
    ],
    []
  )

  const { dataGridProps } = useDataGrid<ITraining,
  HttpError,
  IDefaultFilters
  >({
    meta: {
      operation: 'trainings',
      fields: [
        'total',
        {
          nodes: [
            'id',
            'name',
            'description',
            'text',
            'training_init',
            'training_end',
            'type',
            'createdAt',
            {
              createdBy: ['id, name']
            },
            'updatedAt',
            {
              updatedBy: ['id, name']
            },
            'deletedAt'
          ]
        }
      ]
    },

    pagination: {
      current: 0,
      pageSize: 25
    },

    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'asc'
        }
      ]
    }
  })

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  return (
    <List headerProps={{
      title: <Typography variant="h5">Lista de Formações</Typography>
    }}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        pagination
        paginationMode='server'
        sortingMode="server"
        density='compact'
        components={{
          Toolbar: GridToolbar
        }}
        componentsProps={{
          filterPanel: {
            linkOperators: [GridLinkOperator.And],
            columnsSort: 'asc',
            filterFormProps: {
              linkOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small'
                }
              },
              deleteIconProps: {
                sx: {
                  '& .MuiSvgIcon-root': { color: '#d32f2f' }
                }
              }
            },
            sx: {
              '& .MuiDataGrid-filterForm': { p: 2 },
              '& .MuiDataGrid-filterForm:nth-child(even)': {
                backgroundColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
              },
              '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormValueInput': { width: 200 }
            }
          }
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel)
          handleSaveColumnVisibility({
            tableId,
            userId: user?.id ?? '',
            column_visibility: newModel
          })
        }}
        localeText={DatagridLanguage()}
      />
    </List>
  )
}
