import { SmartToy, NavigateNext, Warning, Error } from '@mui/icons-material'
import { Grid, Alert, Button, AlertTitle } from '@mui/material'
import { useList } from '@refinedev/core'

const CaptchaBalance = () => {
  const { data } = useList({
    resource: 'captchaBalance',
    meta: {
      operation: 'captchaBalance',
      fields: ['balance']
    },
    pagination: { mode: 'off' }
  })

  const record: any = data?.data

  return record?.balance && (
    <Grid item xs={24}>
      {
        record?.balance >= 5 &&
      (
        <Alert severity="success" icon={<SmartToy />} action={
          <Button variant='outlined' size='small' color='success' startIcon={<NavigateNext />} target='_blank' href='https://2captcha.com/pt/enterpage'>
            Navegar para 2Captcha
          </Button>
        }>
          <Grid container direction="row">
            <Grid item xs={12}>
              <AlertTitle>Saldo da Automação</AlertTitle>
            </Grid>
            <Grid item>
              Seu saldo atual no 2Captcha é de <strong>$ {Math.round(record?.balance)}</strong>, recomendamos manter sempre o saldo acima dos 5 dólares para que não haja imprevistos.
            </Grid>
          </Grid>
        </Alert>
      )
      }
      {
        record?.balance >= 1 && record?.balance < 5 &&
      (
        <Alert severity="warning" icon={<Warning />} action={
          <Button variant='outlined' size='small' color='success' startIcon={<NavigateNext />} target='_blank' href='https://2captcha.com/pt/enterpage'>
            Adicionar Fundos
          </Button>
        }>
          <Grid container direction="row">
            <Grid item xs={12}>
              <AlertTitle>Aviso de Saldo Baixo</AlertTitle>
            </Grid>
            <Grid item>
              Seu saldo atual no 2Captcha é de <strong>$ {Math.round(record?.balance)}</strong>, que está abaixo de 5 dólares. Recomendamos adicionar mais fundos para evitar qualquer interrupção futura.
            </Grid>
          </Grid>
        </Alert>
      )
      }
      {
        record?.balance <= 1 &&
      (
        <Alert severity="error" icon={<Error />} action={
          <Button variant='outlined' size='small' color='success' startIcon={<NavigateNext />} target='_blank' href='https://2captcha.com/pt/enterpage'>
            Adicionar Fundos
          </Button>
        }>
          <Grid container direction="row">
            <Grid item xs={12}>
              <AlertTitle>Aviso de Saldo Crítico</AlertTitle>
            </Grid>
            <Grid item>
              Seu saldo no 2Captcha está em <strong>$ {Math.round(record?.balance)}</strong>, é urgente que você adicione mais fundos para garantir que a automação continue a gerar relatórios.
            </Grid>
          </Grid>
        </Alert>
      )
      }
    </Grid>
  )
}

export default CaptchaBalance
