import React, { ReactNode } from 'react'
import { useForm } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Edit, RefreshButton } from '@refinedev/mui'
import { Box, TextField, Grid, Typography, Autocomplete, Stack, Button } from '@mui/material'
import { EnumUserProfile } from 'enums'
import { useParsed } from '@refinedev/core'
import { Save } from '@mui/icons-material'

export function UserEdit () {
  const { id: showId } = useParsed()
  const metaData = {
    fields: [
      'id',
      'name',
      'access',
      'email',
      'profile',
      'cpf'
    ]
  }

  const {
    refineCore: { formLoading },
    saveButtonProps,
    refineCore: { onFinish },
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      invalidates: ['all'],
      metaData
    }
  })

  const handleSubmitEdit = (values: FieldValues) => {
    const { profile, ...rest } = values
    onFinish({
      ...rest,
      profile: typeof profile === 'string' ? Object.keys(EnumUserProfile).indexOf(profile as unknown as EnumUserProfile) : profile
    })
  }

  return (
    <Edit
      headerProps={{
        title: <Typography variant="h5">Editar Usuario</Typography>,
        action: <RefreshButton meta={metaData} recordItemId={showId} />
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      footerButtons={<></>}
    >
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('name', { required: 'O Nome é obrigatório!', minLength: 5, maxLength: 50 })}
                defaultValue={' '}
                error={!!errors?.name}
                helperText={errors.name?.message as ReactNode}
                label="Nome"
                name="name"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('email', { maxLength: 250 })}
                defaultValue={' '}
                error={!!errors?.email}
                label="E-mail - BRA"
                name="email"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('access', { required: 'O Login é obrigatório!', minLength: 5, maxLength: 50 })}
                defaultValue={' '}
                error={!!errors?.access}
                helperText={errors.access?.message as ReactNode}
                label="Login"
                name="access"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('phone', { minLength: 10, maxLength: 11 })}
                defaultValue={' '}
                error={!!errors?.phone}
                helperText={errors.phone?.message as ReactNode}
                label="WhatsApp"
                name="phone"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('cpf', { required: 'O CPF é obrigatório!' })}
                defaultValue={' '}
                error={!!errors?.cpf}
                helperText={errors.cpf?.message as ReactNode}
                label="CPF"
                name="cpf"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Controller
                control={control}
                name="profile"
                rules={{ required: 'Perfil é obrigatório!' }}
                defaultValue={null as any}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    {...field}
                    options={Object.values(EnumUserProfile)}
                    onChange={(_, value) => {
                      field.onChange(Object.values(EnumUserProfile).indexOf(value as unknown as EnumUserProfile))
                    }}
                    getOptionLabel={(value) => {
                      let option = ''
                      option = (typeof value === 'number')
                        ? Object.values(EnumUserProfile)[value]
                        : EnumUserProfile[value]

                      if (!option) {
                        const indexOfS = Object.values(EnumUserProfile).indexOf(value as unknown as EnumUserProfile)
                        const key = Object.keys(EnumUserProfile)[indexOfS]
                        option = EnumUserProfile[key]
                      }
                      return option
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (typeof value === 'number') return option.toString() === Object.values(EnumUserProfile)[value]

                      return value === undefined ||
                    option.toString() === EnumUserProfile[value]
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Perfil"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.profile}
                        helperText={errors.profile?.message as ReactNode}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
            <Button
              type="submit"
              variant="contained"
              startIcon={
                <Save />
              }>
                  Editar
            </Button>
          </Stack>
        </form>
      </Box>
    </Edit>
  )
}
