import React, { ReactNode, useState } from 'react'
import { CloseOutlined, Save } from '@mui/icons-material'
import { Drawer, Typography, IconButton, Stack, Box, Grid, TextField, Autocomplete, Button, InputAdornment } from '@mui/material'
import { HttpError } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Edit } from '@refinedev/mui'
import { ModelContract, TypeContract } from 'enums'

export const EditContract: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  reset,
  getValues,
  control,
  modal: { visible, close },
  saveButtonProps
}) => {
  const handleSubmitEdit = (values: FieldValues) => {
    const { type, model, ...rest } = values
    onFinish({
      ...rest,
      type: typeof type === 'string' ? Object.keys(TypeContract).indexOf(type) : type,
      model: typeof model === 'string' ? Object.keys(ModelContract).indexOf(model) : model,
      value_total: ((Number(valueUnit) ?? Number(getValues('value_unit'))) * (Number(qty) ?? Number(getValues('qty')))) ?? getValues('value_total')
    })
    reset()
    close()
  }

  const [model, setModel] = useState<string | null>()
  const [qty, setQty] = useState<number | string>('')
  const [valueUnit, setValueUnit] = useState<number | string>('')

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 1000 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          title: <Typography variant="h5">Editar Contrato</Typography>,
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{
                width: '30px',
                height: '30px',
                mb: '5px'
              }}
            >
              <CloseOutlined />
            </IconButton>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            component="div"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitEdit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('title', {
                      required: 'Título é obrigatório!',
                      maxLength: {
                        value: 250,
                        message: 'Título deve conter no máximo 250 letras'
                      }
                    })}
                    error={!!errors?.title}
                    helperText={errors.title?.message as ReactNode}
                    label="Título"
                    name="title"
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={' '}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Controller
                    control={control}
                    name="type"
                    rules={{ required: 'Tipo é obrigatório!' }}
                    defaultValue={null as any}
                    render={({ field }) => (
                      <Autocomplete
                        disablePortal
                        {...field}
                        options={Object.values(TypeContract).filter(value => typeof value === 'string')}
                        onChange={(_, value) => {
                          field.onChange(Object.values(TypeContract).filter(value => typeof value === 'string').indexOf(value))
                        }}
                        getOptionLabel={(value) => {
                          let option = ''
                          option = (typeof value === 'number')
                            ? Object.values(TypeContract)[value]
                            : TypeContract[value]

                          if (!option) {
                            const indexOfS = Object.values(TypeContract).indexOf(value as unknown as TypeContract)
                            const key = Object.keys(TypeContract)[indexOfS]
                            option = TypeContract[key]
                          }
                          return option
                        }}
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'number') return option.toString() === Object.values(TypeContract)[value]

                          return value === undefined ||
                          option.toString() === TypeContract[value]
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.type}
                            helperText={errors.type?.message as ReactNode}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Controller
                    control={control}
                    name="model"
                    rules={{ required: 'Modelo de Contrato é obrigatório!' }}
                    defaultValue={null as any}
                    render={({ field }) => (
                      <Autocomplete
                        disablePortal
                        {...field}
                        options={Object.values(ModelContract).filter(value => typeof value === 'string')}
                        onChange={(_, value) => {
                          setModel(value)
                          field.onChange(Object.values(ModelContract).filter(value => typeof value === 'string').indexOf(value))
                        }}
                        getOptionLabel={(value) => {
                          let option = ''
                          option = (typeof value === 'number')
                            ? Object.values(ModelContract)[value]
                            : ModelContract[value]

                          if (!option) {
                            const indexOfS = Object.values(ModelContract).indexOf(value as unknown as ModelContract)
                            const key = Object.keys(ModelContract)[indexOfS]
                            option = ModelContract[key]
                          }
                          return option
                        }}
                        isOptionEqualToValue={(option, value) => {
                          if (typeof value === 'number') return option.toString() === Object.values(ModelContract)[value]

                          return value === undefined ||
                          option.toString() === ModelContract[value]
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Modelo"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.model}
                            helperText={errors.model?.message as ReactNode}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('start_date', {
                      required: 'Data de Início é obrigatória!',
                      valueAsDate: true
                    })}
                    margin="normal"
                    fullWidth
                    type="date"
                    placeholder='Selecione uma Data'
                    label='Data de Início'
                    error={!!errors?.start_date}
                    helperText={errors.start_date?.message as ReactNode}
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('end_date', {
                      valueAsDate: true
                    })}
                    defaultValue={' '}
                    margin="normal"
                    fullWidth
                    type="date"
                    placeholder='Selecione uma Data'
                    label='Data de Término'
                    error={!!errors?.end_date}
                    helperText={errors.end_date?.message as ReactNode}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('start_hour', {
                      required: model === 'Palestra' ? 'Hora de Inicio é obrigatório!' : false
                    })}
                    defaultValue={' '}
                    type="time"
                    error={!!errors?.start_hour}
                    helperText={errors.start_hour?.message as ReactNode}
                    label="Hora de Inicio"
                    placeholder='Selecione uma Hora'
                    name="start_hour"
                    margin="normal"
                    fullWidth
                    required={model === 'Palestra'}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('end_hour', {
                      required: model === 'Palestra' ? 'Hora de Finalização é obrigatório!' : false
                    })}
                    defaultValue={' '}
                    type="time"
                    placeholder='Selecione uma Hora'
                    error={!!errors?.end_hour}
                    helperText={errors.end_hour?.message as ReactNode}
                    label="Hora de Finalização"
                    name="end_hour"
                    margin="normal"
                    fullWidth
                    required={model === 'Palestra'}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('speaker_name', {
                      required: model === 'Palestra' ? 'Nome do Palestrante é obrigatório!' : false
                    })}
                    error={!!errors?.speaker_name}
                    helperText={errors.speaker_name?.message as ReactNode}
                    label="Nome do Palestrante"
                    name="speaker_name"
                    defaultValue={' '}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('speaker_cpf', {
                      required: model === 'Palestra' ? 'CPF do Palestrante é obrigatório!' : false
                    })}
                    error={!!errors?.speaker_cpf}
                    helperText={errors.speaker_cpf?.message as ReactNode}
                    label="CPF do CPFPalestrante"
                    defaultValue={' '}
                    name="speaker_cpf"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('qty', {
                      required: 'Quantidade é obrigatório!',
                      valueAsNumber: true
                    })}
                    error={!!errors?.qty}
                    helperText={errors.qty?.message as ReactNode}
                    onChange={(event) => {
                      const value = event.target.value
                      if (!Number.isNaN(Number(value)) && Number(value) > 0) {
                        setQty(Number(value))
                      }
                    }}
                    defaultValue={' '}
                    value={qty}
                    label="Quantidade"
                    name="qty"
                    margin="normal"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('value_unit', {
                      required: 'Valor Unit. é obrigatório!',
                      valueAsNumber: true
                    })}
                    defaultValue={' '}
                    error={!!errors?.value_unit}
                    helperText={errors.value_unit?.message as ReactNode}
                    onChange={(event) => {
                      const value = event.target.value
                      if (!Number.isNaN(Number(value)) && Number(value) > 0) {
                        setValueUnit(Number(value))
                      }
                    }}
                    label="Valor Unit."
                    name="value_unit"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    {...register('value_total')}
                    error={!!errors?.value_total}
                    helperText={errors.value_total?.message as ReactNode}
                    label="Valor Total"
                    value={(Number(valueUnit) ?? Number(getValues('value_unit'))) * (Number(qty) ?? Number(getValues('qty')))}
                    name="value_total"
                    margin="normal"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('conditions')}
                    defaultValue={' '}
                    error={!!errors?.conditions}
                    helperText={errors.conditions?.message as ReactNode}
                    label="Condição de Pagamento"
                    name="conditions"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    {...register('notes')}
                    defaultValue={' '}
                    error={!!errors?.notes}
                    helperText={errors.notes?.message as ReactNode}
                    label="Observações"
                    name="notes"
                    margin="normal"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    <Save />
                  }>Salvar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Edit>
    </Drawer>
  )
}
