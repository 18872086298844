import React, { useEffect, useMemo, useState } from 'react'
import { DateField, List, RefreshButton, useDataGrid } from '@refinedev/mui'
import { LoadingButton } from '@mui/lab'
import {
  Grid,
  FormControlLabel,
  Switch,
  Stack,
  Typography,
  FormHelperText,
  Tooltip,
  IconButton,
  ButtonGroup,
  Box,
  Alert,
  AlertTitle
} from '@mui/material'
import { Article, Circle, Info, PictureAsPdf } from '@mui/icons-material'
import { axiosInstance } from 'config/axios'
import { ISimec } from 'interfaces'
import { HttpError, useGetIdentity, useInvalidate, useParsed } from '@refinedev/core'
import { DataGrid, GridColumnVisibilityModel, GridColumns } from '@mui/x-data-grid'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { ReportHistorySingle } from 'components/reports'

export default function Reports ({ customer }: { customer?: ISimec}) {
  const tableId = 'simecReport'

  const { id } = useParsed()
  const invalidate = useInvalidate()
  const [loadingWord, setLoadingWord] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const [programs, setPrograms] = useState({
    fnde: false,
    sigpc: false,
    sigpc_programs: false,
    sigef: false,
    pdde: false,
    pnae: false,
    cauc: false,
    simec: false,
    cae: false,
    cacs: false,
    fundeb: false
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrograms({
      ...programs,
      [event.target.name]: event.target.checked
    })
  }

  async function generateDocx (type: number) {
    try {
      type === 1 ? setLoadingWord(true) : setLoadingPdf(true)
      const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY as string)
      const { data } = await axiosInstance.post(`docs/demand/${type}/${id}`, {
        programs
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'arraybuffer'
      })
      if (data) {
        const blob = new Blob([data], { type: `application/${type === 1 ? 'msword' : 'pdf'}` })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${customer?.name}-${new Date().toLocaleDateString()}.${type === 1 ? 'doc' : 'pdf'}`)
        document.body.appendChild(link)
        link.click()
        link?.parentNode?.removeChild(link)
      }
    } catch (error) {
      alert('Erro ao gerar o documento, caso persista comunique o suporte de T.I.')
    } finally {
      type === 1 ? setLoadingWord(false) : setLoadingPdf(false)
    }
  }

  const { dataGridProps } = useDataGrid<any,
  HttpError
  >({
    pagination: {
      mode: 'off'
    },
    syncWithLocation: false,
    resource: 'customersUpdateHistoryByCustomer',
    meta: {
      fields: [
        'id',
        'sigpc_programs_date',
        'sigpc_date',
        'pnae_date',
        'sigef_date',
        'cauc_date',
        'cacs_date',
        'pdde_date',
        'simec_date',
        'cae_date'
      ],
      variables: {
        customerId: {
          value: id,
          required: true,
          type: 'String'
        }
      }
    }
  })

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    sigpc_date: true,
    pnae_date: true,
    sigef_date: true,
    cauc_date: true,
    cacs_date: true,
    simec_date: true,
    sigpc_programs_date: true,
    cae_date: true,
    pdde_date: true
  })

  const { data: user } = useGetIdentity<{
    id: string;
  }>()

  useEffect(() => {
    const columnData = localStorage.getItem('columns')
    if (columnData) {
      const parsedData = JSON.parse(columnData)
      const tableData = parsedData.find(item => item.tableId === tableId)
      if (tableData) {
        setColumnVisibilityModel(tableData.column_visibility)
      }
    }
  }, [])

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: 'sigpc_date',
        headerName: 'SIGPC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigpc_date ? <DateField format='HH:mm D/M/YY' value={row.sigpc_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'pnae_date',
        headerName: 'PNAE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.pnae_date ? <DateField format='HH:mm D/M/YY' value={row.pnae_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'sigef_date',
        headerName: 'SIGEF',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigef_date ? <DateField format='HH:mm D/M/YY' value={row.sigef_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'cauc_date',
        headerName: 'CAUC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.cauc_date ? <DateField format='HH:mm D/M/YY' value={row.cauc_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'cacs_date',
        headerName: 'CACS',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.cacs_date ? <DateField format='HH:mm D/M/YY' value={row.cacs_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'simec_date',
        headerName: 'SIMEC',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.simec_date ? <DateField format='HH:mm D/M/YY' value={row.simec_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'sigpc_programs_date',
        headerName: 'SIGPC PROG',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.sigpc_programs_date ? <DateField format='HH:mm D/M/YY' value={row.sigpc_programs_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'cae_date',
        headerName: 'CAE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.cae_date ? <DateField format='HH:mm D/M/YY' value={row.cae_date} /> : <Circle color='error' />
        }
      },
      {
        field: 'pdde_date',
        headerName: 'PDDE',
        flex: 1,
        type: 'date',
        minWidth: 100,
        renderCell: function render ({ row }) {
          return row.pdde_date ? <DateField format='HH:mm D/M/YY' value={row.pdde_date} /> : <Circle color='error' />
        }
      }
    ],
    []
  )

  const handleInvalidate = () => {
    invalidate({
      resource: 'customersUpdateHistory',
      invalidates: ['all']
    })
  }

  return (
    <>
      <Box paddingBottom={2}>
        <ReportHistorySingle id={id?.toString() ?? ''} />
      </Box>
      <List
        breadcrumb={null}
        headerProps={{
          title: (<>
            <Typography variant="h5">Histórico de Atualização</Typography>
            <Typography variant="subtitle1">Acompanhe a atualização dos dados pela tabela clicando no botão da direita escrito <strong>Atualizar Visualização</strong>.</Typography>
          </>),
          action: (
            <Stack direction={'row'} spacing={2}>
              <RefreshButton onClick={handleInvalidate} >Atualizar Visualização</RefreshButton>
            </Stack>
          )
        }}>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          disableColumnFilter
          autoHeight
          pagination
          paginationMode='server'
          density='compact'
          localeText={DatagridLanguage()}
          hideFooter
        />
      </List>
      <Box padding={2} />
      <List
        breadcrumb={<></>}
        headerProps={{
          title: (<>
            <Typography variant="h5">Relatório</Typography>
            <Typography variant="subtitle1">O relatório será gerado com base nas informações fornecidas no formulário abaixo. Caso os dados que você deseja selecionar estejam indisponíveis ou desatualizados, por favor, atualize a base de dados através do sistema local (localhost).</Typography>
          </>),
          action: null
        }}
      >
        <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.fundeb} onChange={handleChange} name="fundeb" />}
              label="Fundeb" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>Base de dados atualizada em {new Date('2023-03-01').toLocaleString()}.</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.gov.br/fnde/pt-br/acesso-a-informacao/acoes-e-programas/financiamento/fundeb/matriculas-da-educacao-basica-1'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.fnde} onChange={handleChange} name="fnde" />}
              label="FNDE" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>Base de dados atualizada em {new Date('2023-03-01').toLocaleString()}.</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.gov.br/fnde/pt-br/acesso-a-informacao/acoes-e-programas/financiamento/fundeb/matriculas-da-educacao-basica-1'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.sigpc} onChange={handleChange} name="sigpc" disabled={!customer?.sigpc_update_date} />}
              label="SIGPC" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.sigpc_update_date ? `Base de dados atualizada em ${new Date(customer?.sigpc_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/sigpcadm/sistema.pu?operation=localizar'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.sigpc_programs} onChange={handleChange} name="sigpc_programs" disabled={!customer?.sigpc_programs_update_date} />}
              label="SIGPC PROGRAMAS" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.sigpc_programs_update_date ? `Base de dados atualizada em ${new Date(customer?.sigpc_programs_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/sigpcadm/sistema.pu?operation=localizar'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.sigef} onChange={handleChange} name="sigef" disabled={!customer?.sigef_update_date} />}
              label="SIGEF" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.sigef_update_date ? `Base de dados atualizada em ${new Date(customer?.sigef_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/sigefweb/index.php/liberacoes'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.pdde} onChange={handleChange} name="pdde" disabled={!customer?.pdde_update_date} />}
              label="PDDE" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.pdde_update_date ? `Base de dados atualizada em ${new Date(customer?.pdde_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/pddeinfo/pddeinfo/escola/consultar'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.cauc} onChange={handleChange} name="cauc" disabled={!customer?.cauc_update_date} />}
              label="CAUC" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.cauc_update_date ? `Base de dados atualizada em ${new Date(customer?.cauc_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://siconfi.tesouro.gov.br/siconfi/pages/public/sti/iframe_sti.jsf'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.pnae} onChange={handleChange} name="pnae" disabled={!customer?.pnae_update_date} />}
              label="PNAE" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.pnae_update_date ? `Base de dados atualizada em ${new Date(customer?.pnae_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/sigpcadm/sistema.pu?operation=localizar'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.simec} onChange={handleChange} name="simec" disabled={!customer?.simec_update_date} />}
              label="SIMEC" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.simec_update_date ? `Base de dados atualizada em ${new Date(customer?.simec_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/sigpcadm/sistema.pu?operation=localizar'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.cae} onChange={handleChange} name="cae" disabled={!customer?.cae_update_date} />}
              label="CAE" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.cae_update_date ? `Base de dados atualizada em ${new Date(customer?.cae_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/caeweb/publico/consultaEspelhoCae.do'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <FormControlLabel
              control={<Switch checked={programs.cacs} onChange={handleChange} name="cacs" disabled={!customer?.cacs_update_date} />}
              label="CACS" />
            <FormHelperText>
              <Tooltip title={(<>
                <Typography variant="caption" display="block" gutterBottom>{ customer?.cacs_update_date ? `Base de dados atualizada em ${new Date(customer?.cacs_update_date).toLocaleString()}.` : 'Base de dados sem nenhum registro.'}</Typography>
                <Typography variant="caption" display="block" gutterBottom>Clique para acessar o link da base de dados.</Typography>
              </>)}>
                <IconButton
                  target='_blank'
                  href='https://www.fnde.gov.br/caeweb/publico/consultaEspelhoCae.do'>
                  <Info />
                </IconButton>
              </Tooltip>
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
              <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled elevation buttons"
              >
                <LoadingButton
                  endIcon={<Article />}
                  loading={loadingWord}
                  loadingPosition="end"
                  variant="contained"
                  color="info"
                  type="button"
                  onClick={() => generateDocx(1)}
                >
                  Gerar Relatório em WORD
                </LoadingButton>
                <LoadingButton
                  endIcon={<PictureAsPdf />}
                  loading={loadingPdf}
                  loadingPosition="end"
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => generateDocx(2)}
                >
                 Gerar Relatório em PDF
                </LoadingButton>
              </ButtonGroup>
            </Stack>
          </Grid>
        </Grid>
      </List>
      <Box padding={2} />
      <Alert severity="info">
        <AlertTitle>Sistema local (localhost).</AlertTitle>
        O sistema localhost é uma instalação feita na máquina do usuário, permitindo que ele atualize exclusivamente os dados da prefeitura. Isso inclui informações como Simec, SIGPC, PNAE, FUNDEB, FNDE, CAE, CAUC, entre outros. Devido às limitações do servidor, não é possível atualizar esses dados pelo sistema.braconsultoria.com. Em vez disso, é necessário utilizar o sistema instalado na máquina do usuário, acessível através do seguinte endereço: http://localhost:8080. Caso você queira instalar o sistema, por favor, entre em contato com o suporte de T.I.
      </Alert>
    </>
  )
}
