import { Typography } from '@mui/material'
import { GridColumns, DataGrid } from '@mui/x-data-grid'
import { HttpError, useInvalidate } from '@refinedev/core'
import { DateField, List, RefreshButton, useDataGrid } from '@refinedev/mui'
import { DatagridLanguage } from 'components/datagrid/i18n-context'
import { useMemo } from 'react'

export const ReportHistory = () => {
  const invalidate = useInvalidate()
  const { dataGridProps: dataGridPropsReport } = useDataGrid<any,
  HttpError
  >({
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    resource: 'reportsGenerateHistory',
    meta: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'createdAt',
            {
              createdBy: ['id, name']
            },
            {
              customer: [
                'id',
                'name'
              ]
            }
          ]
        }
      ]
    }
  })

  const columnsReport = useMemo<GridColumns<any>>(
    () => [
      {
        field: 'customer.name',
        headerName: 'Prefeitura',
        flex: 2,
        minWidth: 200,
        renderCell: function render ({ row }) {
          return row.customer.name
        }
      },
      {
        field: 'createdAt',
        headerName: 'Relatório gerado em',
        flex: 2,
        type: 'date',
        minWidth: 200,
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy',
        headerName: 'Gerado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      }
    ],
    []
  )

  const handleInvalidate = () => {
    invalidate({
      resource: 'reportsGenerateHistory',
      invalidates: ['all']
    })
  }

  return (
    <List
      breadcrumb={null}
      headerProps={{
        title: (<>
          <Typography variant="h5">Histórico de Relatórios Gerados</Typography>
          <Typography variant="subtitle1">Visão Geral de todas as prefeituras.</Typography>
        </>),
        action: <RefreshButton onClick={handleInvalidate} />
      }}>
      <DataGrid
        {...dataGridPropsReport}
        columns={columnsReport}
        disableColumnFilter
        autoHeight
        pagination
        paginationMode='server'
        density='compact'
        localeText={DatagridLanguage()}
      />
    </List>
  )
}
