import React, { useState } from 'react'
import { ICampaign } from 'interfaces'
import { EnumStatus } from 'enums'
import { ModalMailTest } from 'components/campaigns/show-test-mail'
import { Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Grid, Typography, Stack, Button, Card, CardHeader, CardContent, TextField } from '@mui/material'
import { useUpdate, useTranslate, useShow } from '@refinedev/core'
import { Show, RefreshButton, DateField } from '@refinedev/mui'
import { useModalForm } from '@refinedev/react-hook-form'

export function CampaignShow () {
  const [loadingMailTest, setLoadingMailTest] = useState<boolean>(false)
  const { mutate: mutateUpdate } = useUpdate<ICampaign>()
  const t = useTranslate()
  const metaData = {
    fields: [
      'id',
      'title',
      'type_campaigns',
      'description',
      'text_body',
      'html_body',
      'datetime',
      'task_type',
      'region_name',
      'state_name',
      'mesoregion_name',
      'microregion_name',
      'status',
      'createdAt',
      'updatedAt',
      'deletedAt'
    ]
  }
  const { queryResult, showId } = useShow<ICampaign>({
    metaData
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const mailTestModal = useModalForm({
    refineCoreProps: {
      action: 'create',
      redirect: false,
      invalidates: ['all'],
      resource: 'campaignMail',
      metaData: {
        operation: 'campaignMail',
        fields: [],
        variables: {
          campaignId: showId
        }
      }
    }
  })

  const {
    modal: { show: showModal }
  } = mailTestModal

  const changeStatus = (status: number) => {
    setLoadingMailTest(true)
    mutateUpdate({
      resource: 'campaign',
      invalidates: ['all'],
      id: showId as string,
      values: {
        status
      },
      undoableTimeout: 3000,
      metaData: {
        fields: ['id']
      }
    }, {
      async onSuccess () {
        setLoadingMailTest(false)
      }
    })
  }

  return (
    <>
      <ModalMailTest {...mailTestModal}/>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Show
            isLoading={isLoading}
            headerProps={{
              title: <Typography variant="h5">Campanha: {record?.title}</Typography>,
              action: (
                <Stack direction="row" spacing={2}>
                  <RefreshButton meta={metaData} recordItemId={showId} />
                  <LoadingButton
                    endIcon={<Send />}
                    loading={loadingMailTest}
                    loadingPosition="end"
                    variant="contained"
                    color="info"
                    type="button"
                    onClick={() => showModal()}
                  >
                  Enviar E-mail teste
                  </LoadingButton>
                  { record?.status === 0 ? <Button color='error' variant='contained' onClick={() => changeStatus(2)} >Cancelar campanha</Button> : null}
                  { record?.status === 0 ? <Button color='success' variant='contained' onClick={() => changeStatus(1)} >Ativar campanha</Button> : null}
                </Stack>
              )
            }}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {record?.description
                ? <Grid item xs={12}>
                  <Typography variant="body1" fontWeight="bold">
                    Descrição
                  </Typography>
                  <Typography variant="body2">{record?.description}</Typography>
                </Grid>
                : null }
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Tipo de Campanha
                </Typography>
                <Typography variant="body2">{record?.type_campaigns}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Tipo de Envio
                </Typography>
                <Typography variant="body2">{record?.task_type === '2' ? `Envio agendado para: ${new Date(record?.datetime || '').toLocaleString()}` : 'Envio imediato'}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Status
                </Typography>
                <Typography variant="body2">{EnumStatus[record?.status as any]}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight="bold">
                  Criado em
                </Typography>
                <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.createdAt} /></Typography>
              </Grid>
              {record?.deletedAt
                ? <Grid item xs={3}>
                  <Typography variant="body1" fontWeight="bold">
                    Deletado em
                  </Typography>
                  <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.deletedAt} /></Typography>
                </Grid>
                : null }
            </Grid>
          </Show>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
            <CardHeader title={'Texto do E-mail'} />
            <CardContent sx={{ pt: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    value={record?.text_body}
                    margin="normal"
                    variant="outlined"
                    disabled
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
            <CardHeader title={'Filtros da Campanha'} />
            <CardContent sx={{ pt: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    defaultValue={' '}
                    value={record?.region_name}
                    label={t(
                      'customer.filter.region.label'
                    )}
                    placeholder={t(
                      'customer.filter.region.placeholder'
                    )}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    defaultValue={' '}
                    value={record?.state_name}
                    label={t(
                      'customer.filter.state.label'
                    )}
                    placeholder={t(
                      'customer.filter.state.placeholder'
                    )}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    defaultValue={' '}
                    value={record?.mesoregion_name}
                    label={t(
                      'customer.filter.mesoregion.label'
                    )}
                    placeholder={t(
                      'customer.filter.mesoregion.placeholder'
                    )}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    defaultValue={' '}
                    value={record?.microregion_name}
                    label={t(
                      'customer.filter.microregion.label'
                    )}
                    placeholder={t(
                      'customer.filter.microregion.placeholder'
                    )}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
