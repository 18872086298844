import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { DarkTheme, LightTheme } from '@refinedev/mui'
import { ThemeProvider, darken, lighten } from '@mui/material/styles'

type ColorModeContextType = {
  mode: string;
  setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
)

export const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

export const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5)

export const ColorModeContextProvider: React.FC<PropsWithChildren<any>> = ({
  children
}) => {
  const colorModeFromLocalStorage = localStorage.getItem('colorMode')
  const isSystemPreferenceDark = window?.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const systemPreference = isSystemPreferenceDark ? 'dark' : 'light'
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  )

  useEffect(() => {
    window.localStorage.setItem('colorMode', mode)
  }, [mode])

  const setColorMode = () => {
    if (mode === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode
      }}
    >
      <ThemeProvider theme={mode === 'light' ? LightTheme : DarkTheme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
