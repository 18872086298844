import { useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { IMessageCustomer } from 'interfaces'
import { HttpError, useList } from '@refinedev/core'
import { useModalForm } from '@refinedev/react-hook-form'
import { ShowAttendance } from 'components/attendance'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './style.css'
import 'moment/locale/pt-br'

const AttendanceCalendar = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [attendance, setAttendance] = useState<IMessageCustomer | null>(null)
  const localizer = momentLocalizer(moment)

  const messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    showMore: (total) => `+ (${total}) Eventos`
  }

  const { data } = useList<IMessageCustomer,
  HttpError
  >({
    pagination: {
      mode: 'off'
    },
    resource: 'messagesCustomerByUserId',
    meta: {
      fields: [
        'id',
        'title',
        'html',
        'content',
        'attendance_status',
        'return_date',
        'createdAt',
        {
          files: [
            'id',
            'file_name',
            'mimetype',
            'size',
            'file_url',
            'key',
            'createdAt',
            'updatedAt',
            'deletedAt'
          ]
        },
        {
          customer: [
            'id',
            'name'
          ]
        }
      ]
    }
  })
  const record = data?.data

  const events = (record ?? []).map(message => ({
    title: message.customer?.name ?? message.title,
    start: message.return_date ? new Date(message.return_date) : null,
    end: message.return_date ? new Date(message.return_date) : null,
    content: message.content,
    id: message.id
  }))

  const attendanceViewModal = useModalForm<IMessageCustomer>()

  const {
    modal: { show: showAttendance }
  } = attendanceViewModal

  return (
    <Paper>
      <Stack margin={2} marginTop={2} spacing={2}>
        <Typography gutterBottom variant='h5'>Calendário de Atendimento</Typography>
      </Stack>
      <ShowAttendance attendance={attendance} {...attendanceViewModal} />
      <Box margin={2} style={{ height: matches ? 300 : 500 }}>
        <Calendar
          localizer={localizer}
          events={events}
          messages={messages}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={(event) => {
            const att = record?.find((r) => r.id === event.id)
            if (att) {
              setAttendance(att)
              showAttendance(event.id)
            }
          }}
        />
      </Box>
      <Box paddingTop={2} />
    </Paper>
  )
}

export default AttendanceCalendar
