import React from 'react'
import copy from 'clipboard-copy'
import { TooltipProps, Tooltip } from '@mui/material'

interface ChildProps {
  copy: (content: any) => void;
}

interface Props {
  TooltipProps?: Partial<TooltipProps>;
  children: (props: ChildProps) => React.ReactElement<any>;
}

interface OwnState {
  showTooltip: boolean;
}

export default class CopyToClipboard extends React.Component<Props, OwnState> {
  public state: OwnState = { showTooltip: false }

  public render () {
    return (
      <Tooltip
        open={this.state.showTooltip}
        title={'Código de barras do boleto copiado!'}
        leaveDelay={1500}
        onClose={this.handleOnTooltipClose}
        {...this.props.TooltipProps || {}}
      >
        {this.props.children({ copy: this.onCopy }) as React.ReactElement<any>}
      </Tooltip>
    )
  }

  private onCopy = (content: any) => {
    copy(content)
    this.setState({ showTooltip: true })
  }

  private handleOnTooltipClose = () => {
    this.setState({ showTooltip: false })
  }
}
