import React, { useState, ReactNode, useEffect, useMemo } from 'react'
import { ISimec, ICustomerFilterVariables, ICampaign, IMesoregion, IMicroregion, IState } from 'interfaces'
import { EnumCampaigns, EnumCustomerStatus } from 'enums'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import UsystemEditor from 'usystem-ckeditor5'
import USystemUploadAdapterPlugin from 'components/ckeditor/upload-adapter'
import states from 'data/states.json'
import regions from 'data/regions.json'
import { Box, Grid, TextField, Autocomplete, Stack, Typography, Card, CardHeader, CardContent, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Stepper, Step, StepButton } from '@mui/material'
import { GridColumns, DataGrid } from '@mui/x-data-grid'
import { useTranslate, HttpError, CrudFilters, BaseRecord, getDefaultFilter, useParsed } from '@refinedev/core'
import { useDataGrid, SaveButton, List, Edit } from '@refinedev/mui'
import { useForm, useStepsForm } from '@refinedev/react-hook-form'
import { FieldValues, Controller } from 'react-hook-form'

import '../../components/ckeditor/style.css'

export function CampaignEdit () {
  const { id: showId } = useParsed()
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [radio, setRadio] = useState<string>('')
  const [state, setState] = useState<IState | null>()
  const [mesoregions, setMesoregions] = useState<IMesoregion[]>()
  const [microregions, setMicroregions] = useState<IMicroregion[]>()
  const t = useTranslate()

  const stepTitles = [
    t('campaigns.steps.descriptions'),
    t('campaigns.steps.content'),
    t('campaigns.steps.recipients'),
    t('campaigns.steps.timer')
  ]

  const {
    refineCore: { onFinish, formLoading },
    control,
    register,
    handleSubmit,
    formState: { errors },
    steps: { currentStep, gotoStep }
  } = useStepsForm<ICampaign, HttpError>({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      invalidates: ['all'],
      metaData: {
        fields: [
          'id',
          'title',
          'type_campaigns',
          'description',
          'message',
          'datetime',
          'task_type',
          'region_name',
          'state_name',
          'mesoregion_name',
          'microregion_name'
        ]
      }
    },
    stepsProps: {
      isBackValidate: false
    },
    warnWhenUnsavedChanges: true
  })

  const { dataGridProps, search, filters } = useDataGrid<
  ISimec,
  HttpError,
  ICustomerFilterVariables
  >({
    resource: 'simecCustomers',
    pagination: {
      pageSize: 10,
      current: 0
    },
    syncWithLocation: false,
    metaData: {
      fields: [
        'total',
        {
          nodes: [
            'id',
            'code',
            'cnpj',
            'name',
            'mail',
            'county_code',
            'county_name',
            'state_code',
            'state_abbreviation',
            'state_name',
            'region_abbreviation',
            'region_name',
            'mesoregion_name',
            'microregion_name',
            'status'
          ]
        }
      ],
      variables: {
        operatorOrm: {
          value: 'or',
          name: 'operatorOrm',
          required: false,
          type: 'String'
        }
      }
    },
    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, region_name: regionName, mesoregion_name: mesoregionName, microregion_name: microregionName, state_name: stateName } = params

      filters.push({
        field: 'status',
        operator: 'in',
        value: status && status.length > 0 ? status?.toString() : undefined
      })

      filters.push({
        field: 'region_name',
        operator: 'in',
        value: regionName && regionName.length > 0 ? regionName?.toString() : undefined
      })

      filters.push({
        field: 'state_name',
        operator: 'in',
        value: stateName && stateName.length > 0 ? stateName?.toString() : undefined
      })

      filters.push({
        field: 'mesoregion_name',
        operator: 'in',
        value: mesoregionName && mesoregionName.length > 0 ? mesoregionName?.toString() : undefined
      })

      filters.push({
        field: 'microregion_name',
        operator: 'in',
        value: microregionName && microregionName.length > 0 ? microregionName?.toString() : undefined
      })

      return filters
    }
  })

  const columns = useMemo<GridColumns<ISimec>>(
    () => [
      {
        field: 'county_name',
        headerName: 'Prefeitura',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'state_abbreviation',
        headerName: 'Estado',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'region_name',
        headerName: 'Região',
        flex: 1,
        minWidth: 100
      },
      {
        field: 'microregion_name',
        headerName: 'Micro Região',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'mesoregion_name',
        headerName: 'Meso Região',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: Object.values(EnumCustomerStatus).map(obj => {
          return {
            value: Object.values(EnumCustomerStatus).indexOf(obj),
            label: obj
          }
        }),
        renderCell: ({ row }) => {
          return EnumCustomerStatus[row.status]
        }
      }
    ],
    []
  )

  const { handleSubmit: handleSubmitFilter, control: controlFilter } = useForm<
  BaseRecord,
  HttpError,
  ICustomerFilterVariables
  >({
    defaultValues: {
      status: getDefaultFilter('status', filters, 'in'),
      region_name: getDefaultFilter('region_name', filters, 'in'),
      state_name: getDefaultFilter('state_name', filters, 'in'),
      mesoregion_name: getDefaultFilter('mesoregion_name', filters, 'in'),
      microregion_name: getDefaultFilter('microregion_name', filters, 'in')
    }
  })

  useEffect(() => {
    if (message.length === 0 && currentStep === 1) {
      setDisableNextButton(true)
    } else setDisableNextButton(false)
  }, [currentStep, message])

  useEffect(() => {
    const fetchData = async () => {
      const dataMeso = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state?.id}/mesorregioes`)
      const jsonMeso = await dataMeso.json()
      setMesoregions(jsonMeso)

      const dataMicro = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state?.id}/microrregioes`)
      const jsonMicro = await dataMicro.json()
      setMicroregions(jsonMicro)
    }
    fetchData()
      .catch(console.error)
  }, [state])

  const handleSubmitCreate = (values: FieldValues) => {
    const { type_campaigns: typeCampaigns, date, time, ...rest } = values

    onFinish({
      ...rest,
      datetime: radio === '2' ? new Date(`${date} ${time}`) : new Date(),
      type_campaigns: Object.values(EnumCampaigns).indexOf(typeCampaigns),
      message,
      task_type: radio,
      user_status: filters.find((f: any) => f.field === 'status')?.value ?? null,
      region_name: filters.find((f: any) => f.field === 'region_name')?.value ?? null,
      state_name: filters.find((f: any) => f.field === 'state_name')?.value ?? null,
      mesoregion_name: filters.find((f: any) => f.field === 'mesoregion_name')?.value ?? null,
      microregion_name: filters.find((f: any) => f.field === 'microregion_name')?.value ?? null
    })
  }

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column' }}
            autoComplete="off"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  {...register('title', { required: 'Título é obrigatório!' })}
                  error={!!errors?.title}
                  helperText={errors.title?.message as ReactNode ?? 'Escreva o título do E-mail, será o título enviado para todos os destinatários caso o tipo de campanha seja EMAIL.'}
                  label="Título"
                  name="title"
                  margin="normal"
                  required
                  defaultValue={' '}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Controller
                  control={control}
                  name="type_campaigns"
                  rules={{ required: 'Tipo é obrigatório!' }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={Object.values(EnumCampaigns).filter(value => typeof value === 'string')}
                      onChange={(_, value) => {
                        field.onChange(Object.values(EnumCampaigns).indexOf(value as unknown as EnumCampaigns))
                      }}
                      getOptionLabel={(value) => {
                        let option = ''
                        option = (typeof value === 'number')
                          ? Object.values(EnumCampaigns)[value]
                          : EnumCampaigns[value]

                        if (!option) {
                          const indexOfS = Object.values(EnumCampaigns).indexOf(value as unknown as EnumCampaigns)
                          const key = Object.keys(EnumCampaigns)[indexOfS]
                          option = EnumCampaigns[key]
                        }
                        return option
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (typeof value === 'number') return option.toString() === Object.values(EnumCampaigns)[value]

                        return value === undefined ||
                        option.toString() === EnumCampaigns[value]
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo"
                          margin="normal"
                          variant="outlined"
                          error={!!errors?.type_campaigns}
                          helperText={errors.type_campaigns?.message as ReactNode ?? 'Selecione o tipo da campanha, será o meio de envio utilizado.'}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  {...register('description')}
                  error={!!errors?.description}
                  helperText={errors.description?.message as ReactNode ?? 'Opcional, descrição sobre a campanha.'}
                  label="Descrição"
                  name="description"
                  defaultValue={' '}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        )
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CKEditor
                editor={ UsystemEditor }
                config={{ extraPlugins: [USystemUploadAdapterPlugin] }}
                data={message}
                onChange={ (event: any, editor: { getData: () => any }) => {
                  const data = editor.getData()
                  setMessage(data)
                }}
              />
              <Stack>
                <Typography variant="caption" display="block" gutterBottom>
                  O editor de texto acima deve ser preenchido com o conteúdo que será enviado, o título será o título do e-mail e abaixo o conteúdo do e-mail, o editor aceita HTML e imagens.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
                <CardHeader title={t('customer.filter.title')} subheader="Selecione os filtros para envio da campanha, caso não haja nenhum filtro o e-mail será enviado para todas as prefeituras." />
                <CardContent sx={{ pt: 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Controller
                        control={controlFilter}
                        name="status"
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disablePortal
                            {...field}
                            options={Object.values(EnumCustomerStatus) || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value?.map((p) => p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option === value
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.status.label'
                                )}
                                placeholder={t(
                                  'customer.filter.status.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Controller
                        control={controlFilter}
                        name="region_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            options={regions}
                            onChange={(_, value) => {
                              field.onChange(
                                value?.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.region.label'
                                )}
                                placeholder={t(
                                  'customer.filter.region.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre as prefeituras por região.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="state_name"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            groupBy={(option) => option?.regiao.nome || ''}
                            multiple
                            options={states}
                            onChange={(_, value) => {
                              if (value.length === 1) {
                                setState(value[0])
                              } else setState(null)
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.state.label'
                                )}
                                placeholder={t(
                                  'customer.filter.state.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre as prefeituras por estado, caso haja filtro de região o sistema irá considerar OU a região OU o estado selecionado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="mesoregion_name"
                        render={({ field }) => (
                          <Autocomplete
                            multiple
                            disabled={!state}
                            {...field}
                            options={mesoregions || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.mesoregion.label'
                                )}
                                placeholder={t(
                                  'customer.filter.mesoregion.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre o estado por mesoregião, válido quando selecionado apenas um estado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Controller
                        control={controlFilter}
                        name="microregion_name"
                        render={({ field }) => (
                          <Autocomplete
                            groupBy={(option) => option?.mesorregiao.nome || ''}
                            multiple
                            disabled={!state}
                            {...field}
                            options={microregions || []}
                            onChange={(_, value) => {
                              field.onChange(
                                value.map((p) => p.nome ?? p)
                              )
                            }}
                            getOptionLabel={(item) => {
                              return item?.nome
                                ? item.nome
                                : item
                            }}
                            isOptionEqualToValue={(
                              option,
                              value
                            ) => {
                              return (
                                option.nome === value ||
                                  option.nome === value.nome
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  'customer.filter.microregion.label'
                                )}
                                placeholder={t(
                                  'customer.filter.microregion.placeholder'
                                )}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                helperText={'Filtre o estado por microregião, válido quando selecionado apenas um estado.'}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="right">
                        <Button type="button" variant="contained" onClick={handleSubmitFilter(search)}>
                          {t('customer.filter.submit')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12}>
              <List
                headerProps={{
                  title: (
                    <>
                      <Typography variant='h5'>Destinatários</Typography>
                      <Typography variant='h5' color={'green'}>{dataGridProps.rowCount} prefeituras receberão a campanha.</Typography>
                      <Typography variant='subtitle1'>Tabela abaixo representa a relação de prefeituras que receberão a campanha de acordo com os filtros acima.</Typography>
                    </>
                  ),
                  action: (null)
                }}
                wrapperProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
                breadcrumb={null}
              >
                <DataGrid
                  {...dataGridProps}
                  columns={columns}
                  autoHeight
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  sx={{
                    ...dataGridProps.sx,
                    '& .MuiDataGrid-row': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </List>
            </Grid>
          </Grid>
        )
      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Selecione a programação de envio *</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radio}
                    onChange={(event, value) => setRadio(value)}
                  >
                    <FormControlLabel value="1" control={<Radio required />} label="Enviar imediatamente" />
                    <FormControlLabel value="2" control={<Radio />} label="Programar Envio" />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                {...register('date', { required: (radio === '2') })}
                margin="normal"
                fullWidth
                type="date"
                placeholder='Selecione uma Data/Hora'
                disabled={ radio !== '2' }
                required={ radio !== '2' }
                error={!!errors?.date}
                helperText={errors.date?.message as ReactNode ?? 'Data que será enviada a campanha aos destinatários, opção disponível apenas se for selecionada a opção "Programar Envio".'}
              />
              <TextField
                {...register('time', { required: (radio === '2') })}
                margin="normal"
                fullWidth
                type="time"
                placeholder='Selecione uma Data/Hora'
                disabled={ radio !== '2' }
                required={ radio !== '2' }
                error={!!errors?.time}
                helperText={errors.time?.message as ReactNode ?? 'Hora que será enviada a campanha aos destinatários, opção disponível apenas se for selecionada a opção "Programar Envio".'}
              />
            </Grid>
          </Grid>
        )
    }
  }

  return (
    <Edit
      headerProps={{
        title: <Typography variant="h5">Editar Campanha</Typography>
      }}
      headerButtons={
        <>
          {currentStep > 0 && (
            <Button
              variant='contained'
              color='info'
              onClick={() => {
                gotoStep(currentStep - 1)
              }}
            >
              {t('buttons.previousStep')}
            </Button>
          )}
          {currentStep < stepTitles.length - 1 && (
            <Button disabled={disableNextButton} variant='contained' onClick={() => gotoStep(currentStep + 1)}>
              {t('buttons.nextStep')}
            </Button>
          )}
          {currentStep === stepTitles.length - 1 && (
            <SaveButton
              onClick={handleSubmit(handleSubmitCreate)}
            />
          )}
        </>}
      footerButtons={<></>}
      isLoading={formLoading}>
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
          autoComplete="off"
        >
          <Stepper nonLinear activeStep={currentStep}>
            {stepTitles.map((label, index) => (
              <Step
                key={label}
                sx={{
                  '& .MuiStepLabel-label': {
                    fontSize: '18px',
                    lineHeight: '32px'
                  }
                }}
              >
                <StepButton disabled={disableNextButton} onClick={() => gotoStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <br />
          {renderFormByStep(currentStep)}
        </Box>
      </form>
    </Edit>
  )
}
