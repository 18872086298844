import React, { ReactNode, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Clear, Add, Save } from '@mui/icons-material'
import { Drawer, IconButton, Typography, Stack, Box, FormControl, FormLabel, OutlinedInput, FormHelperText, Paper, Grid, TextField, InputAdornment, Button } from '@mui/material'
import { HttpError, useParsed } from '@refinedev/core'
import { UseModalFormReturnType } from '@refinedev/react-hook-form'
import { FieldValues } from 'react-hook-form'
import { Edit } from '@refinedev/mui'

export const EditProgram: React.FC<
UseModalFormReturnType<any, HttpError>
> = ({
  register,
  formState: { errors },
  refineCore: { onFinish },
  handleSubmit,
  modal: { visible, close },
  saveButtonProps,
  reset
}) => {
  const { id: trainingId } = useParsed()

  const [lines, setLines] = useState<number>(6)
  const MAX_ITEMS = 6

  function addLine () {
    if (lines < MAX_ITEMS) {
      setLines(lines + 1)
    }
  }

  function removeLine () {
    setLines(lines - 1)
  }

  const handleSubmitEdit = (values: FieldValues) => {
    onFinish({
      ...values,
      trainingId
    })
    reset()
    close()
  }

  return (
    <Drawer
      sx={{ zIndex: '1301' }}
      PaperProps={{ sx: { width: { sm: '100%', md: 400 } } }}
      open={visible}
      onClose={close}
      anchor="right"
    >
      <Edit
        footerButtons={<></>}
        saveButtonProps={saveButtonProps}
        headerProps={{
          avatar: (
            <IconButton
              onClick={() => close()}
              sx={{ width: '30px', height: '30px', mb: '5px' }}
            >
              <CloseIcon />
            </IconButton>
          ),
          title: (
            <>
              <Typography variant="h5">Editar Ementa</Typography>
              <Typography variant="subtitle1" gutterBottom>
                A ementa será alterada no certificado digital.
              </Typography>
            </>
          ),
          action: null
        }}
        breadcrumb= {false}
        wrapperProps={{ sx: { overflowY: 'scroll', height: '100vh' } }}
      >
        <Stack>
          <Box
            paddingX="50px"
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingX: {
                xs: 1,
                md: 6
              }
            }}
          >
            <form onSubmit={handleSubmit(handleSubmitEdit)}>
              <Stack sx={{ paddingBottom: 2 }} gap="10px" marginTop="10px">
                <FormControl>
                  <FormLabel required={true}>
                    Título
                  </FormLabel>
                  <OutlinedInput
                    id="name"
                    {...register('name', {
                      required: 'Título é obrigatório!',
                      minLength: {
                        value: 3,
                        message: 'Título deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 75,
                        message: 'Título deve conter no máximo 75 letras'
                      }
                    })}
                    style={{ height: '40px' }}
                  />
                  {errors.name && (
                    <FormHelperText error>
                      {errors.name?.message as ReactNode}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>
                    Descrição
                  </FormLabel>
                  <OutlinedInput
                    id="description"
                    {...register('description', {
                      minLength: {
                        value: 3,
                        message: 'Título deve conter no mínimo 3 letras'
                      },
                      maxLength: {
                        value: 250,
                        message: 'Título deve conter no máximo 250 letras'
                      }
                    })}
                    multiline
                    minRows={5}
                    maxRows={5}
                  />
                  {errors.description && (
                    <FormHelperText error>
                      {errors.description?.message as ReactNode}
                    </FormHelperText>
                  )}
                </FormControl>
                <Paper component="div" elevation={4} sx={{ p: 2, gap: 2 }} >
                  <Grid container spacing={1}>
                    <Stack direction="column" alignItems='center' justifyContent='center'>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Itens da Ementa
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          É possível adicionar até {MAX_ITEMS} itens com 75 caracteres cada.
                        </Typography>
                      </Grid>
                    </Stack>
                    <Stack sx={{ paddingBottom: 2 }} gap="15px" paddingTop={2}>
                      {
                        [...Array(lines)].map((line, i) => (
                          <TextField
                            key={i}
                            {...register(`line${i + 1}`, {
                              minLength: {
                                value: 3,
                                message: 'Ementa deve conter no mínimo 3 letras'
                              },
                              maxLength: {
                                value: 100,
                                message: 'Ementa deve conter no máximo 100 letras'
                              }
                            })}
                            defaultValue={' '}
                            error={!!errors?.[`line${i + 1}`]}
                            helperText={errors?.[`line${i + 1}`]?.message as ReactNode}
                            label={`Item ${i + 1}`}
                            name={`line${i + 1}`}
                            margin='none'
                            size='small'
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton color="error" sx={{ p: '10px' }} aria-label="add">
                                    { lines === (i + 1) && i > 0 ? <Clear onClick={() => removeLine()} /> : null }
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        ))
                      }
                    </Stack>
                    <Grid item xs={12}>
                      <Stack>
                        <Button
                          variant='contained'
                          onClick={() => addLine()}
                          disabled={(lines === MAX_ITEMS)}
                          startIcon={
                            <Add />
                          }>Nova linha</Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
              <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    <Save />
                  }>Editar</Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Edit>
    </Drawer>
  )
}
