import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useCreate, useShow } from '@refinedev/core'
import { DateField, DeleteButton, EditButton, List, RefreshButton, Show } from '@refinedev/mui'
import { DataGrid, GridColumns, GridRowId } from '@mui/x-data-grid'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { IStudents, ITraining, ITrainingPrograms, ITrainingStudents } from 'interfaces'
import { EnumTypeTraining } from 'enums'
import { Add, Clear, PictureAsPdfOutlined } from '@mui/icons-material'
import { useModalForm } from '@refinedev/react-hook-form'
import { ModalProgram } from 'components/training/create-program'
import { ModalStudent } from 'components/training/create-student'
import { EditProgram } from 'components/training/edit-program'
import { HttpError } from '@refinedev/core/dist/interfaces'
import { EditStudent } from 'components/training/edit-student'
import * as html2pdf from 'html2pdf.js'
import CertificateBody from 'components/certificates/pdf'
import { generatePDF } from 'components/training/generate-pdf'
import { LoadingButton } from '@mui/lab'

export function TrainingShow () {
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [itemRows, setitemRows] = useState<IStudents[]>([])
  const { mutate: mutateCreate } = useCreate<any>()

  const metaData = {
    fields: [
      'id',
      'name',
      'text',
      'description',
      'training_init',
      'training_end',
      'type',
      'status',
      {
        students: [
          'id',
          'createdAt',
          {
            createdBy: ['id, name']
          },
          {
            student: [
              'id',
              'name'
            ]
          }
        ]
      },
      {
        programs: [
          'id',
          'name',
          'description',
          'line1',
          'line2',
          'line3',
          'line4',
          'line5',
          'line6',
          {
            createdBy: ['id, name']
          },
          'createdAt'
        ]
      },
      'createdAt',
      {
        createdBy: ['id, name']
      },
      'updatedAt',
      {
        updatedBy: ['id, name']
      },
      'deletedAt'
    ]
  }
  const { queryResult, showId } = useShow<ITraining>({
    meta: metaData
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const generatePdf = (backgroundImage: number) => {
    const generatePdfPromise = (prevPromise, trainingStudentId) => {
      return prevPromise.then(() => {
        const student = record?.students?.find((st) => st.id === trainingStudentId)?.student
        if (!student) return null

        return new Promise<void>((resolve) => {
          mutateCreate(
            {
              resource: 'trainingCertificate',
              values: {
                trainingId: showId,
                trainingStudentId
              },
              successNotification: false,
              metaData: {
                fields: ['qrcode']
              }
            },
            {
              async onSuccess ({ data }) {
                const html = (
                  <CertificateBody
                    student={student.name}
                    course={record?.text}
                    text={record?.description}
                    programs={record?.programs ?? []}
                    backgroundImage={backgroundImage}
                    qrcode={data.qrcode}
                  />
                )

                const div = document.createElement('div')
                ReactDOM.render(html, div)

                const options = {
                  margin: [0, 0, 0, 0],
                  filename: `${student.name.replace(' ', '-')}.pdf`,
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 3 },
                  jsPDF: { format: 'A4', orientation: 'landscape' }
                }

                html2pdf()
                  .from(div)
                  .set(options)
                  .save()
                  .then(() => {
                    resolve()
                  })
              }
            }
          )
        })
      })
    }

    selectionModel.reduce(generatePdfPromise, Promise.resolve())
  }

  async function handlePdf (studentId: string, studentName: string) {
    setLoadingPdf(true)
    await generatePDF((showId ?? '').toString(), studentId, studentName)
    setLoadingPdf(false)
  }

  const summaryModal = useModalForm({
    refineCoreProps: {
      action: 'create',
      redirect: false,
      invalidates: ['all'],
      resource: 'trainingProgram',
      metaData: {
        fields: ['id'],
        variables: {
          trainingId: showId
        }
      }
    }
  })

  const {
    modal: { show: showModalProgram }
  } = summaryModal

  const studentModal = useModalForm({
    refineCoreProps: {
      action: 'create',
      redirect: false,
      invalidates: ['all'],
      resource: 'trainingStudent',
      metaData: {
        fields: ['id'],
        variables: {
          trainingId: showId
        }
      }
    }
  })

  const {
    modal: { show: showModalStudent }
  } = studentModal

  const editProgram = useModalForm<ITrainingPrograms, HttpError>({
    refineCoreProps: {
      action: 'edit',
      resource: 'trainingProgram',
      metaData: {
        fields: [
          'id',
          'name',
          'description',
          'line1',
          'line2',
          'line3',
          'line4',
          'line5',
          'line6',
          'line7',
          'line9',
          'line10',
          'trainingId'
        ]
      },
      invalidates: ['all'],
      redirect: false
    }
  })

  const {
    modal: { show: showEditProgram }
  } = editProgram

  const editStudent = useModalForm<ITrainingStudents, HttpError>({
    refineCoreProps: {
      action: 'edit',
      resource: 'student',
      metaData: {
        fields: [
          'id',
          'name',
          'cpf_cnpj',
          'type_person',
          'address',
          'number',
          'complement',
          'district',
          'city',
          'state_abbreviation',
          'zip_code',
          'email',
          'phone'
        ]
      },
      invalidates: ['all'],
      redirect: false
    }
  })

  const {
    modal: { show: showEditStudent }
  } = editStudent

  const columnsPrograms = useMemo<GridColumns<ITrainingPrograms>>(
    () => [
      {
        headerName: 'Ações',
        field: 'actions',
        type: 'actions',
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton
                hideText
                onClick={() => showEditProgram(row.id)}
              />
              <DeleteButton
                hideText
                recordItemId={row.id}
                resource='trainingProgram'
                invalidates={['all']}
              />
            </Stack>
          )
        }
      },
      {
        field: 'name',
        headerName: 'Nome',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'description',
        headerName: 'Descrição',
        flex: 3,
        minWidth: 300
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'date',
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      }
    ],
    []
  )

  function sortComparator (v1, v2, cellParams1, cellParams2) {
    const name1 = cellParams1.value.trim() || ''
    const name2 = cellParams2.value.trim() || ''
    return name1.localeCompare(name2)
  }

  const columnsStudents: GridColumns<ITrainingStudents> = [
    {
      headerName: 'Ações',
      field: 'actions',
      type: 'actions',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: function render ({ row }) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton
              hideText
              onClick={() => showEditStudent(row.student.id)}
            />
            <DeleteButton
              hideText
              recordItemId={row.id}
              resource='trainingStudent'
              invalidates={['all']}
            />
          </Stack>
        )
      }
    },
    {
      field: 'student.name',
      headerName: 'Nome',
      flex: 1,
      minWidth: 200,
      sortable: true,
      sortComparator,
      valueGetter: (params) => params.row.student?.name,
      renderCell: function render ({ row }) {
        return row.student?.name
      }
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      flex: 1,
      minWidth: 150,
      type: 'date',
      renderCell: function render ({ row }) {
        return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
      }
    },
    {
      field: 'createdBy.name',
      headerName: 'Criado Por',
      flex: 1,
      minWidth: 200,
      renderCell: function render ({ row }) {
        return row.createdBy?.name
      }
    }
  ]

  return <>
    <ModalProgram {...summaryModal}/>
    <ModalStudent {...studentModal}/>
    <EditProgram {...editProgram} />
    <EditStudent {...editStudent} />
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Show
          isLoading={isLoading}
          headerProps={{
            title: <Typography variant="h5">Formação: {record?.name}</Typography>,
            action: (
              <Stack direction="row" spacing={2}>
                <RefreshButton meta={metaData} recordItemId={showId} />
                <Button
                  disabled={record?.status !== 0}
                  variant="contained"
                  color="error"
                  startIcon={
                    <Clear />
                  }
                >
                Cancelar formação
                </Button>
              </Stack>
            )
          }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" fontWeight="bold">
                Texto da primeira página
              </Typography>
              <Typography variant="body2">{ record?.text ? record?.description : 'Não há descrição.' }</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" fontWeight="bold">
                Texto da segunda página
              </Typography>
              <Typography variant="body2">{ record?.description ? record?.description : 'Não há descrição.' }</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Tipo
              </Typography>
              <Typography variant="body2">{EnumTypeTraining[record?.type]}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Data da Inicío
              </Typography>
              <Typography variant="body2">{ record?.training_init ? <DateField format='HH:mm D/M/YY' value={record?.training_init} /> : 'Não há data informada.' }</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Data da Término
              </Typography>
              <Typography variant="body2">{ record?.training_end ? <DateField format='HH:mm D/M/YY' value={record?.training_end} /> : 'Não há data informada.' }</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Status
              </Typography>
              <Typography variant="body2">{record?.deletedAt ? 'Inativo' : 'Ativo'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Criado em
              </Typography>
              <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.createdAt} /></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Criado por
              </Typography>
              <Typography variant="body2">{record?.createdBy?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Atualizado em
              </Typography>
              <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.updatedAt} /></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="body1" fontWeight="bold">
                  Atualizado por
              </Typography>
              <Typography variant="body2">{record?.updatedBy?.name}</Typography>
            </Grid>
          </Grid>
        </Show>
      </Grid>
      <Grid item xs={12}>
        <List headerProps={{
          title: <Typography variant="h5">Lista de Ementas</Typography>,
          action: (
            <Button
              disabled={record?.status !== 0 || (record?.programs?.length ?? 0) >= 8}
              variant="contained"
              color="success"
              startIcon={
                <Add />
              }
              onClick={() => showModalProgram(showId)}
            >
              Adicionar Ementa
            </Button>
          )
        }}
        breadcrumb={<></>}
        >
          <Grid item xs={12}>
            <DataGrid
              rows={record?.programs || []}
              columns={columnsPrograms}
              density='compact'
              autoHeight
              pagination
              paginationMode='server'
              sortingMode='client'
            />
          </Grid>
        </List>
      </Grid>
      <Grid item xs={12}>
        <List headerProps={{
          title: <Typography variant="h5">Lista de Alunos</Typography>,
          action: (
            <Stack direction="row" spacing={2}>
              <Button
                disabled={selectionModel.length === 0}
                variant="contained"
                color="error"
                startIcon={
                  <PictureAsPdfOutlined />
                }
                onClick={() => generatePdf(1)}
              >
                  Gerar Certificado (Nova versão)
              </Button>
              {/* <FormHelperText>
                <Tooltip title={(<>
                  <Typography variant="caption" display="block" gutterBottom>Layout Obsoleto</Typography>
                </>)}>
                  <Button
                    disabled={selectionModel.length === 0}
                    variant="contained"
                    color="error"
                    startIcon={
                      <PictureAsPdfOutlined />
                    }
                    onClick={() => generatePdf(2)}
                  >
                    Gerar Certificado (Layout 2)
                  </Button>
                </Tooltip>
              </FormHelperText> */}
              <Button
                disabled={record?.status !== 0}
                variant="contained"
                color="success"
                startIcon={
                  <Add />
                }
                onClick={() => showModalStudent(showId)}
              >
              Adicionar Aluno
              </Button>
            </Stack>
          )
        }}
        breadcrumb={<></>}
        >
          <Grid item xs={12}>
            <DataGrid
              rows={record?.students || []}
              columns={columnsStudents}
              checkboxSelection
              keepNonExistentRowsSelected
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids)
                const row = (record?.students ?? []).find((row: { id: GridRowId }) =>
                  selectedIDs.has(row.id)
                )
                if (row) {
                  setitemRows([...itemRows, row?.student])
                  setSelectionModel(ids)
                }
              }}
              selectionModel={selectionModel}
              disableSelectionOnClick
              density='compact'
              autoHeight
              pagination
              paginationMode='server'
              sortingMode='client'
            />
          </Grid>
        </List>
      </Grid>
    </Grid>
  </>
}
